import { Literal, Static } from "runtypes";
import { AgendaItemTemplateSectionValidator } from "../agenda-item-template-common";

export const AgendaItemTemplateSectionEngagementScoreValidator =
  AgendaItemTemplateSectionValidator.extend({
    type: Literal("engagementScore")
  });

export type AgendaItemTemplateSectionEngagementScore = Static<
  typeof AgendaItemTemplateSectionEngagementScoreValidator
>;
