import React from "react";
import "./AIModalSectionLocation.scss";
import {
  AccordionWithStatus,
  getAdvancedTooltipContent
} from "common-components";
import { AgendaItemSectionLocation } from "api/agenda-item/sections";
import OwnersContainer from "common-components/owners-container/OwnersContainer";
import AIModalSectionLocationMetric from "./metric/AIModalSectionLocationMetric";

const namespace = "rts-pa-agenda-item-modal-section-location";

type Props = {
  section: AgendaItemSectionLocation;
};

export const AIModalSectionLocation = (props: Props) => {
  return (
    <AccordionWithStatus
      title={props.section.title}
      status={props.section.status}
      tooltipContent={getAdvancedTooltipContent(props.section.tooltip)}
      className={namespace}
      links={props.section.links}
      accordionSyncKey="AgendaItemModal"
      accordionSyncId={props.section.id}
    >
      <OwnersContainer owners={props.section.owners} />
      <div className={`${namespace}-metrics`}>
        <AIModalSectionLocationMetric
          title="scope"
          data={props.section.scope}
        />
        <AIModalSectionLocationMetric
          title="timeline"
          data={props.section.timeline}
        />
        <AIModalSectionLocationMetric
          title="value"
          data={props.section.value}
        />
        <AIModalSectionLocationMetric title="cost" data={props.section.cost} />
      </div>
      <AIModalSectionLocationMetric title="risk" data={props.section.risk} />
    </AccordionWithStatus>
  );
};

export default AIModalSectionLocation;
