import React from "react";
import "./AIBuilderModalSectionPipelineProject.scss";
import { Select, GenericOption } from "common-components";
import { AgendaItemTemplateSectionPipelineProject } from "api/agenda-item-template/sections";
import { SectionBasicProps } from "../../AgendaItemBuilderModalSection";

const namespace = "rts-pa-agenda-item-builder-modal-section-pipeline-project";

type Props = SectionBasicProps;

const tempProjects: GenericOption<string>[] = [
  {
    label: "VESALIUS-CV",
    value: "vesalius"
  },
  {
    label: "Daily Dosing (QD)",
    value: "qd"
  },
  {
    label: "Japan Palmoplantar Pustulosis (PPP)",
    value: "ppp"
  },
  {
    label: "Pediatric Plaque Psoriasis (Peds Plaque PsO)",
    value: "pppso"
  }
];

export const AIBuilderModalSectionPipelineProject = (props: Props) => {
  return (
    <div className={namespace}>
      <Select
        label="Project"
        autoUpdater={{
          item: props.agendaItemTemplate,
          setItem: props.setAgendaItemTemplate,
          propExpression: x =>
            (
              x.groups[props.groupIndex].sections[
                props.index
              ] as AgendaItemTemplateSectionPipelineProject
            ).projectId
        }}
        required
        validator={props.fieldUtils}
        options={tempProjects}
        placeholder="Select Project"
      />
    </div>
  );
};

export default AIBuilderModalSectionPipelineProject;
