import { Array, Record, Static, String } from "runtypes";

export const PipelineProductValidator = Record({
  calendarRowLinkId: String,
  name: String
});

export type PipelineProduct = Static<typeof PipelineProductValidator>;

export const PipelineProductsValidator = Array(PipelineProductValidator);

export type PipelineProducts = Static<typeof PipelineProductsValidator>;
