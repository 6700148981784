import { Array, Number, Record, Static, String } from "runtypes";
import {
  InactiveSectionValidator,
  LinkValidator,
  OwnersValidator,
  StatusValidator
} from "../common";

const CommercialSupplyLocationSectionValidator = Record({
  status: StatusValidator,
  text: String.nullable()
});

const CommercialSupplyLocationValidator = Record({
  title: String,
  status: StatusValidator,
  owners: OwnersValidator,
  scope: CommercialSupplyLocationSectionValidator,
  timeline: CommercialSupplyLocationSectionValidator,
  value: CommercialSupplyLocationSectionValidator,
  cost: CommercialSupplyLocationSectionValidator,
  risk: CommercialSupplyLocationSectionValidator,
  links: Array(LinkValidator)
});

const CommercialSupplyCogsReductionValidator = Record({
  status: StatusValidator
});

const CommercialSupplyInventoryBelowTargetValidator = Record({
  status: StatusValidator,
  fdp: Record({
    value: Number,
    status: StatusValidator,
    statusText: String,
    reasonCodes: Array(String),
    countries: Array(String)
  }),
  links: Array(LinkValidator)
});

const CommercialSupplyComplaintsChartDataValidator = Record({
  label: String,
  actual: Number
});

const CommercialSupplyComplaintsTableDataValidator = Record({
  headers: Array(String),
  rows: Array(String)
});

const CommercialSupplyComplaintsValidator = Record({
  status: StatusValidator,
  yearlyData: Array(CommercialSupplyComplaintsChartDataValidator),
  monthlyData: Array(CommercialSupplyComplaintsChartDataValidator),
  table: Array(CommercialSupplyComplaintsTableDataValidator),
  links: Array(LinkValidator)
});

export type CommercialSupplyComplaintsChartData = Static<
  typeof CommercialSupplyComplaintsChartDataValidator
>;

export const CommercialSupplySectionValidator = Record({
  locations: Array(
    CommercialSupplyLocationValidator.Or(InactiveSectionValidator)
  ),
  cogsReduction: CommercialSupplyCogsReductionValidator.Or(
    InactiveSectionValidator
  ).nullable(),
  inventoryBelowTarget: CommercialSupplyInventoryBelowTargetValidator.Or(
    InactiveSectionValidator
  ).nullable(),
  complaints: CommercialSupplyComplaintsValidator.Or(
    InactiveSectionValidator
  ).nullable()
});

export type CommercialSupplyLocationSection = Static<
  typeof CommercialSupplyLocationValidator
>;
export type CommercialSupplyCogsReductionSection = Static<
  typeof CommercialSupplyCogsReductionValidator
>;
export type CommercialSupplyInventoryBelowTargetSection = Static<
  typeof CommercialSupplyInventoryBelowTargetValidator
>;
export type CommercialSupplyComplaintsSection = Static<
  typeof CommercialSupplyComplaintsValidator
>;

export type CommercialSupplySection = Static<
  typeof CommercialSupplySectionValidator
>;
