import React, { useCallback, useEffect } from "react";
import "./TextArea.scss";
import classNames from "classnames";
import {
  AutoUpdater,
  AutoUpdaterId,
  AutoUpdaterType,
  useAutoUpdater
} from "global/use-auto-updater";
import { FieldValUtils, ValidationError } from "global/use-validator";
import { TextArea as DSTextarea } from "@opsdti-global-component-library/amgen-design-system";
import { Label, Text } from "common-components";

const namespace = "rts-pa-text-area";

type Props<T extends AutoUpdaterType> = {
  id?: AutoUpdaterId;
  label?: string;
  errorLabel?: string;
  className?: string;
  required?: boolean;
  placeholder?: string;
  autoUpdater: AutoUpdater<T>;
  validator?: FieldValUtils;
  disabled?: boolean;
};

export const TextArea = <T extends AutoUpdaterType>(props: Props<T>) => {
  const { id, value, onChange } = useAutoUpdater<string, T>(
    props.autoUpdater,
    props.id
  );

  const validate = useCallback(
    (val: string) => {
      if (!props.validator) {
        return;
      }

      const errors: ValidationError[] = [];

      if (props.required && !val) {
        const fieldName = props.errorLabel || props.label || props.placeholder;

        errors.push({
          fieldError: `${fieldName || "This field"} is required.`,
          fullError: `${fieldName || id} is required.`
        });
      }

      props.validator.setErrors(id, errors);
    },
    [props.required, props.validator] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    if (!props.validator) {
      return;
    }

    const unregister = props.validator.registerValidation(id, () =>
      validate(value)
    );

    return () => {
      unregister();
    };
  }, [value, validate]); // eslint-disable-line react-hooks/exhaustive-deps

  const onChangeWrapper: React.ChangeEventHandler<HTMLTextAreaElement> = e => {
    const val = e.currentTarget.value;
    onChange(val);
    validate(val);
  };

  const errors = props.validator?.errors.get(id) || [];
  const className = classNames(namespace, props.className, {
    error: !!errors.length
  });

  return (
    <div className={className}>
      <Label requiredAsterisk={props.required}>{props.label}</Label>
      <DSTextarea
        autoSize={{ minRows: 2 }}
        value={value || ""}
        placeholder={props.placeholder}
        onChange={onChangeWrapper}
        data-id={props.id}
        disabled={props.disabled}
      />
      {!!errors.length && (
        <div className="errors-container">
          {errors.map((e, i) => (
            <Text type="danger" key={i} className="error-item">
              {e.fieldError}
            </Text>
          ))}
        </div>
      )}
    </div>
  );
};

export default TextArea;
