import React from "react";
import "./AgendaGridAxisVertical.scss";
import { Label } from "common-components";

export type Props = {
  label: string;
  low: string;
  high: string;
};

const AgendaGridAxisVertical = (props: Props) => {
  return (
    <div className="rts-pa-agenda-grid-axis-vertical">
      <div className="pill">
        <Label colorVariant="white">{props.low}</Label>
      </div>
      <div className="line" />
      <Label>{props.label}</Label>
      <div className="line" />
      <div className="pill">
        <Label colorVariant="white">{props.high}</Label>
      </div>
    </div>
  );
};

export default AgendaGridAxisVertical;
