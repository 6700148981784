import axios from "axios";
import {
  CalendarRowItem,
  CalendarRowItemValidator
} from "./calendar/calendar-row";
import { getKeyEventsId } from "utils/multitenancy";
import { Calendar, CalendarValidator } from "./calendar/calendar";

// TODO JCG: move to different file once tony is done with his work
export const getCalendarRowItem = async (): Promise<CalendarRowItem> => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  const result = await axios.get<CalendarRowItem>(
    `${REACT_APP_DOMAIN}/api/v1/calendar/item`
  );

  CalendarRowItemValidator.check(result.data);

  return result.data;
};

export const getCalendar = async (): Promise<Calendar> => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  const result = await axios.get<Calendar>(
    `${REACT_APP_DOMAIN}/api/v1/calendar/${getKeyEventsId()}`
  );

  CalendarValidator.check(result.data);

  return result.data;
};

export const postCalendar = async (agenda: Calendar): Promise<Calendar> => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  const result = await axios.post<Calendar>(
    `${REACT_APP_DOMAIN}/api/v1/calendar`,
    agenda
  );

  CalendarValidator.check(result.data);

  return result.data;
};

export const putCalendar = async (agenda: Calendar): Promise<Calendar> => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  const result = await axios.put<Calendar>(
    `${REACT_APP_DOMAIN}/api/v1/calendar/${getKeyEventsId()}`,
    agenda
  );

  CalendarValidator.check(result.data);

  return result.data;
};

export const deleteCalendar = async (): Promise<Calendar> => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  const result = await axios.delete<Calendar>(
    `${REACT_APP_DOMAIN}/api/v1/calendar/${getKeyEventsId()}`
  );

  CalendarValidator.check(result.data);

  return result.data;
};
