import React from "react";
import "./SummaryInfo.scss";
import { SummaryInfo as SummaryInfoType } from "api/common";
import { Label, StatusIcon, Text } from "common-components";

const namespace = "rts-pa-modal-summary-info";

type Props = {
  data: SummaryInfoType | undefined;
};

export const SummaryInfo = (props: Props) => {
  if (!props.data) {
    return <></>;
  }

  return (
    <div className={namespace}>
      <div className={`${namespace}-container`}>
        <Text>
          Status is
          <StatusIcon
            className={`${namespace}-icon`}
            status={props.data.status}
          />
          {props.data.reason && `based on ${props.data.reason}`}
        </Text>
        {props.data.scope && (
          <div>
            <div>
              <Label className={`${namespace}-label`}>Scope</Label>
            </div>
            <Text className={`${namespace}-value`}>{props.data.scope}</Text>
          </div>
        )}
        {props.data.keyInsights && (
          <div>
            <div>
              <Label className={`${namespace}-label`}>Key Insights</Label>
            </div>
            <Text className={`${namespace}-value`}>
              {props.data.keyInsights}
            </Text>
          </div>
        )}
      </div>
    </div>
  );
};

export default SummaryInfo;
