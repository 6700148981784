import React from "react";
import "./AgendaItemModalSection.scss";
import { AgendaItemSection } from "api/agenda-item/agenda-item-common";
import AIModalSectionPlaceholder from "./implementations/placeholder/AIModalSectionPlaceholder";

import {
  AgendaItemSectionSingleMetric,
  AgendaItemSectionSalesDemand,
  AgendaItemSectionPipelineProject,
  AgendaItemSectionLocation,
  AgendaItemSectionInventoryBelowTarget,
  AgendaItemSectionEngagementScore,
  AgendaItemSectionTalentAcquisition,
  AgendaItemSectionTurnover,
  AgendaItemSectionPlaceholder
} from "api/agenda-item/sections";
import AIModalSectionSingleMetric from "./implementations/single-metric/AIModalSectionSingleMetric";
import AIModalSectionSalesDemand from "./implementations/sales-demand/AIModalSectionSalesDemand";
import AIModalSectionPipelineProject from "./implementations/pipeline-project/AIModalSectionPipelineProject";
import AIModalSectionLocation from "./implementations/location/AIModalSectionLocation";
import AIModalSectionInventoryBelowTarget from "./implementations/inventory-below-target/AIModalSectionInventoryBelowTarget";
import AIModalSectionEngagementScore from "./implementations/engagement-score/AIModalSectionEngagementScore";
import AIModalSectionTalentAcquisition from "./implementations/talent-acquisition/AIModalSectionTalentAcquisition";
import AIModalSectionTurnover from "./implementations/turnover/AIModalSectionTurnover";
import { AgendaItem } from "api/agenda-item/agenda-item";

type Props = {
  agendaItem: AgendaItem;
  section: AgendaItemSection;
};

export const AgendaItemModalSectionMapper = (props: Props) => {
  switch (props.section.type) {
    case "placeholder":
      return (
        <AIModalSectionPlaceholder
          section={props.section as AgendaItemSectionPlaceholder}
        />
      );
    case "singleMetric":
      return (
        <AIModalSectionSingleMetric
          section={props.section as AgendaItemSectionSingleMetric}
        />
      );
    case "salesDemand":
      return (
        <AIModalSectionSalesDemand
          section={props.section as AgendaItemSectionSalesDemand}
        />
      );
    case "pipelineProject":
      return (
        <AIModalSectionPipelineProject
          section={props.section as AgendaItemSectionPipelineProject}
        />
      );
    case "location":
      return (
        <AIModalSectionLocation
          section={props.section as AgendaItemSectionLocation}
        />
      );
    case "inventoryBelowTarget":
      return (
        <AIModalSectionInventoryBelowTarget
          section={props.section as AgendaItemSectionInventoryBelowTarget}
        />
      );
    case "engagementScore":
      return (
        <AIModalSectionEngagementScore
          agendaItemName={props.agendaItem.name}
          section={props.section as AgendaItemSectionEngagementScore}
        />
      );
    case "talentAcquisition":
      return (
        <AIModalSectionTalentAcquisition
          agendaItemName={props.agendaItem.name}
          section={props.section as AgendaItemSectionTalentAcquisition}
        />
      );
    case "turnover":
      return (
        <AIModalSectionTurnover
          agendaItemName={props.agendaItem.name}
          section={props.section as AgendaItemSectionTurnover}
        />
      );
    default:
      return <></>;
  }
};

export default AgendaItemModalSectionMapper;
