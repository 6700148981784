import { StatusValidator } from "api/common";
import { Boolean, Record, Static, String, Array, Dictionary } from "runtypes";

const IndividualDropdownValuesValidator = Record({
  item: Array(String),
  status: Array(String),
  date: Array(String),
  itemIdMapping: Dictionary(String, String)
});
export type IndividualDropdownValues = Static<
  typeof IndividualDropdownValuesValidator
>;

export const ActivityLogDropdownValuesDataValidator = Record({
  workstream: String,
  dropdownName: Array(String),
  dropdownValues: IndividualDropdownValuesValidator
});
export type ActivityLogDropdownValuesData = Static<
  typeof ActivityLogDropdownValuesDataValidator
>;

const TimelineDataValidator = Record({
  approvalRequired: Boolean,
  workstreamUrl: String,
  approvalStatus: String,
  status: StatusValidator,
  timestamp: String,
  workstream: String,
  eventId: String,
  eventLogId: String,
  itemId: String,
  id: String,
  activityLogMessageTrimmed: String,
  activityLogMessage: String
});
export type TimelineData = Static<typeof TimelineDataValidator>;

export const ActivityLogDataValidator = Record({
  timelineData: Array(TimelineDataValidator),
  aiSummary: String
});

export type ActivityLogData = Static<typeof ActivityLogDataValidator>;
