import { AxiosInstance } from "axios";
import { Array, Record, Static, String } from "runtypes";

const KeyEventSeriesValidator = Record({
  series: Array(String)
});

export type KeyEventSeries = Static<typeof KeyEventSeriesValidator>;

export const getKeyEvents = async (
  axios: AxiosInstance
): Promise<KeyEventSeries> => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  const result = await axios.get<KeyEventSeries>(
    `${REACT_APP_DOMAIN}/api/v4/key_events`
  );

  return result.data;
};
