import React from "react";
import "./AIModalSectionPlaceholder.scss";
import {
  AccordionWithStatus,
  getAdvancedTooltipContent
} from "common-components";
import { AgendaItemSectionPlaceholder } from "api/agenda-item/sections";

const namespace = "rts-pa-agenda-item-modal-section-placeholder";

type Props = {
  section: AgendaItemSectionPlaceholder;
};

export const AIModalSectionPlaceholder = (props: Props) => {
  return (
    <AccordionWithStatus
      title={props.section.title}
      tooltipContent={getAdvancedTooltipContent(props.section.tooltip)}
      status={props.section.status}
      className={namespace}
    />
  );
};

export default AIModalSectionPlaceholder;
