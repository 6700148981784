import React from "react";
import "./AgendaGridBoxIcon.scss";
import classnames from "classnames";

export type Props = {
  focus: number;
  value: number;
};

export const AgendaGridBoxIcon = (props: Props) => {
  const selectedIndex = props.focus - 1 + (3 - props.value) * 3;

  return (
    <div className="rts-pa-agenda-grid-box-icon">
      {Array.from(Array(9)).map((_, i) => {
        const className = classnames("box", {
          selected: selectedIndex === i
        });

        return <div key={i} className={className} />;
      })}
    </div>
  );
};

export default AgendaGridBoxIcon;
