import { EntityListItem, PermissionRole } from "api";

const roleRankings: { [key in PermissionRole]: number } = {
  admin: 3,
  full_viewer: 2,
  limited_viewer: 1
};

export function hasMinimumPermissionRole(
  entityList: EntityListItem[],
  entityId: number,
  minimumRole: PermissionRole
): boolean {
  for (const entity of entityList) {
    if (entity.id === entityId) {
      return roleRankings[entity.role] >= roleRankings[minimumRole];
    }
  }
  return false;
}
