import React, { useContext, useEffect, useMemo, useState } from "react";
import "./UpsertAgendaModal.scss";
import {
  getAgenda,
  postAgenda,
  putAgenda,
  deleteAgenda as deleteAgendaApi,
  Agenda,
  AdEntity,
  EntityListItem,
  getAuthorization
} from "api";
import PaModal from "common-components/pa-modal/PaModal";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Button } from "@opsdti-global-component-library/amgen-design-system";
import { useValidator } from "global/use-validator";
import { useStatusManager } from "global/use-status-manager";
import TrashIcon from "icons/trash-icon";
import { useNavigate } from "react-router-dom";
import { useModalHelper } from "global/use-modal-helper";
import pages from "pages/pages";
import { PermissionsContext } from "global/permissions";
import { Input, DatePicker, Select, Checkbox } from "common-components";
import { useGetAllAdEntities } from "global/use-get-all-ad-entities";
import { getAgendaId } from "utils/multitenancy";
import dayjs from "dayjs";
import produce from "immer";
import { useOktaUser } from "global/use-okta-user";
import { tooltips } from "./upsertAgendaModalTooltips";

type AgendaModel = {
  id: number;
  title: string;
  publishedDate: string;
  owner: string;
  isHiddenFromSuperAdmin: boolean;
  admins: string[];
  fullViewers: string[];
  limitedViewers: string[];
};

const initialAgenda: AgendaModel = {
  id: 0,
  title: "",
  publishedDate: dayjs().toISOString(),
  owner: "",
  isHiddenFromSuperAdmin: false,
  admins: [],
  fullViewers: [],
  limitedViewers: []
};

type AddMode = {
  text: "Add New";
  isAdd: true;
  isEdit: false;
};

type EditMode = {
  text: "Edit";
  isAdd: false;
  isEdit: true;
};

type ModalMode = AddMode | EditMode;

const namespace = "rts-pa-upsert-agenda-modal";

const UpsertAgendaModal = () => {
  const perms = useContext(PermissionsContext);
  const navigate = useNavigate();
  const mhResult = useModalHelper(
    [pages.grid.addAgendaModal.path, pages.grid.editAgendaModal.path],
    [perms.grid.create, perms.grid.edit]
  );
  const currentUser = useOktaUser();
  const [agenda, setAgenda] = useState<AgendaModel>(initialAgenda);

  const mode: ModalMode = useMemo(() => {
    if (mhResult.path === pages.grid.addAgendaModal.path) {
      return {
        text: "Add New",
        isAdd: true,
        isEdit: false
      };
    } else {
      return {
        text: "Edit",
        isAdd: false,
        isEdit: true
      };
    }
  }, [mhResult]);

  const [isDeleteConfirmation, setIsDeleteConfirmation] = useState(false);
  const { containerUtils, fieldUtils } = useValidator();

  const { refetch: refreshPermsList } = useQuery(
    ["authorization"],
    getAuthorization,
    {
      enabled: false
    }
  );

  const adEntities = useGetAllAdEntities();

  const {
    data,
    status: apiAgendaStatus,
    refetch: refreshApiAgenda,
    error
  } = useQuery(["agenda", getAgendaId()], getAgenda, {
    enabled: mhResult.enabled && mode.isEdit
  });

  const { status: addStatus, mutate: addAgenda } = useMutation(postAgenda, {
    onMutate: () => {
      overrideStatus("addAgenda", "loading");
    },
    onSuccess: () => {
      refreshPermsList().then(query => {
        const newAgenda: EntityListItem | undefined =
          query.data?.pa.agendaList.find(a => a.title === agenda?.title);

        if (newAgenda) {
          navigate(pages.grid.go(newAgenda.id.toString()));
        } else {
          navigate(pages.grid.go());
        }

        setTimeout(() => {
          //keep spinner going when closing modal
          overrideStatus("addAgenda", undefined);
        }, 500);
      });
    },
    onError: () => {
      overrideStatus("addAgenda", undefined);
    }
  });
  const { status: updateStatus, mutate: updateAgenda } = useMutation(
    putAgenda,
    {
      onMutate: () => {
        overrideStatus("putAgenda", "loading");
      },
      onSuccess: () => {
        Promise.all([refreshPermsList(), refreshApiAgenda()]).then(() => {
          if (getAgendaId() !== "0") {
            // 0 when redirected by permissions to key-events or unauthorized on removing self from agenda
            navigate(pages.grid.go());
          }

          setTimeout(() => {
            //keep spinner going when closing modal -- started on update
            overrideStatus("putAgenda", undefined);
          }, 500);
        });
      },
      onError: () => {
        overrideStatus("putAgenda", undefined);
      }
    }
  );
  const { status: deleteStatus, mutate: deleteAgenda } = useMutation(
    deleteAgendaApi,
    {
      onMutate: () => {
        overrideStatus("deleteAgenda", "loading");
      },
      onSuccess: () => {
        refreshPermsList().then(() => {
          if (getAgendaId() !== "0") {
            // 0 when deleting last agenda for self, and redirected by permissions to key-events or unauthorized page
            navigate(pages.grid.go());
          }

          setTimeout(() => {
            //keep spinner going when closing modal
            overrideStatus("deleteAgenda", undefined);
            setIsDeleteConfirmation(false);
          }, 500);
        });
      },
      onError: () => {
        overrideStatus("deleteAgenda", undefined);
      }
    }
  );

  const { status, overrideStatus } = useStatusManager(
    mode.isEdit && mhResult.enabled ? apiAgendaStatus : "idle",
    addStatus,
    updateStatus,
    deleteStatus
  );

  //reset modal on close
  useEffect(() => {
    if (mhResult.enabled && mode.isEdit && data) {
      const mapToStrings = (adEntities: AdEntity[]): string[] =>
        adEntities.map(a => a.username);

      const owner =
        adEntities.users.find(u => u.username === data.owner.username)
          ?.displayName || "";

      const agenda: AgendaModel = {
        id: data.id,
        title: data.title,
        publishedDate: data.publishedDate,
        owner: owner,
        isHiddenFromSuperAdmin: data.isHiddenFromSuperAdmin,
        admins: mapToStrings(data.admins),
        fullViewers: mapToStrings(data.fullViewers),
        limitedViewers: mapToStrings(data.limitedViewers)
      };

      setAgenda(agenda);
    } else if (mhResult.enabled && mode.isAdd) {
      const newAgenda = produce(agenda, draftState => {
        const owner =
          adEntities.users.find(u => u.email === currentUser?.email)
            ?.displayName || "";

        draftState.owner = owner;
      });

      setAgenda(newAgenda);
    } else if (!mhResult.enabled) {
      setTimeout(() => {
        containerUtils.resetValidation();
        setIsDeleteConfirmation(false);
        setAgenda(initialAgenda);
      }, 500);
    }
  }, [mhResult.enabled, data, adEntities, currentUser]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSave = () => {
    containerUtils.triggerValidation().then(({ isValid }) => {
      if (!isValid) {
        return;
      }

      const mapToAdEntities = (opts: string[]): AdEntity[] => {
        let newAdEntities = opts.map(optVal => {
          let adEntity: AdEntity | undefined = adEntities.users.find(
            u => u.username === optVal
          );

          if (!adEntity) {
            adEntity = adEntities.groups.find(u => u.username === optVal);
          }

          // should never happen
          if (!adEntity) {
            return undefined as unknown as AdEntity;
          }

          return adEntity;
        });

        //remove undefined items
        newAdEntities = newAdEntities.filter(e => !!e);

        return newAdEntities;
      };

      const apiAgenda: Agenda = {
        id: agenda.id,
        title: agenda.title,
        publishedDate: dayjs
          .tz(dayjs(agenda.publishedDate).format("YYYY-MM-DD"))
          .toISOString(),
        owner: {} as AdEntity,
        isHiddenFromSuperAdmin: agenda.isHiddenFromSuperAdmin,
        admins: mapToAdEntities(agenda.admins),
        fullViewers: mapToAdEntities(agenda.fullViewers),
        limitedViewers: mapToAdEntities(agenda.limitedViewers)
      };

      if (mode.isAdd) {
        addAgenda(apiAgenda);
      } else if (mode.isEdit) {
        updateAgenda(apiAgenda);
      }
    });
  };

  const isSaving = status === "loading";

  const onClose = () => {
    if (isSaving) {
      return;
    }

    navigate(pages.grid.go());
  };

  let footerButtons: JSX.Element;
  const footerClassName = `${namespace}-footer`;
  if (isDeleteConfirmation) {
    footerButtons = (
      <div className={footerClassName}>
        <Button
          text="Cancel Delete"
          type="secondary"
          onClick={() => {
            setIsDeleteConfirmation(false);
          }}
          className={`${namespace}-delete-button`}
          disabled={status === "loading"}
          danger={true}
        />
        <Button
          text="Confirm Delete"
          type="primary"
          onClick={() => {
            deleteAgenda();
          }}
          className={`${namespace}-delete-button`}
          disabled={status === "loading"}
          danger={true}
        />
      </div>
    );
  } else if (mode.isEdit) {
    footerButtons = (
      <div className={footerClassName}>
        <Button
          text="Delete Agenda"
          type="primary"
          onClick={() => {
            setIsDeleteConfirmation(true);
          }}
          icon={<TrashIcon />}
          className={`${namespace}-delete-button`}
          disabled={isSaving}
          danger={true}
        />
        <Button
          text="Publish Updates"
          type="primary"
          onClick={onSave}
          disabled={isSaving}
        />
      </div>
    );
  } else {
    //mode.isAdd
    footerButtons = (
      <div className={footerClassName}>
        <Button
          text="Cancel"
          type="secondary"
          onClick={() => {
            navigate(pages.grid.go());
          }}
          disabled={isSaving}
        />
        <Button
          text="Save"
          type="primary"
          onClick={onSave}
          disabled={isSaving}
        />
      </div>
    );
  }

  return (
    <PaModal
      title={`${mode.text} ${agenda.title} Prioritized Agenda`}
      status={status}
      axiosErrors={error}
      isOpen={mhResult.enabled}
      onClose={onClose}
      disableBackdropDismiss={true}
      className={namespace}
      footer={footerButtons}
    >
      <div className={`${namespace}-content`}>
        <Input
          label="Title"
          placeholder="Input"
          required
          autoUpdater={{
            item: agenda,
            setItem: setAgenda,
            propExpression: x => x.title
          }}
          validator={fieldUtils}
          tooltip={tooltips.title}
        />
        <DatePicker
          label='"As Of" Date'
          placeholder="Select date"
          required
          autoUpdater={{
            item: agenda,
            setItem: setAgenda,
            propExpression: x => x.publishedDate
          }}
          tooltip={tooltips.publishedDate}
        />
        <div className={`${namespace}-one-line-container`}>
          <Input
            label="Owner"
            disabled={true}
            autoUpdater={{
              item: agenda,
              setItem: setAgenda,
              propExpression: x => x.owner
            }}
            tooltip={tooltips.owner}
          />
          <Checkbox
            label="Hide from Super Admins?"
            autoUpdater={{
              item: agenda,
              setItem: setAgenda,
              propExpression: x => x.isHiddenFromSuperAdmin
            }}
          />
        </div>
        <Select
          label="Admin Users & Groups"
          options={adEntities.options}
          autoUpdater={{
            item: agenda,
            setItem: setAgenda,
            propExpression: x => x.admins
          }}
          mode="multiple"
          tooltip={tooltips.admins}
          placeholder="Select"
        />
        <Select
          label="Full View (With Status) Users & Groups"
          options={adEntities.options}
          autoUpdater={{
            item: agenda,
            setItem: setAgenda,
            propExpression: x => x.fullViewers
          }}
          mode="multiple"
          tooltip={tooltips.fullViewers}
          placeholder="Select"
        />
        <Select
          label="Limited View (Without Status) Users & Groups"
          options={adEntities.options}
          autoUpdater={{
            item: agenda,
            setItem: setAgenda,
            propExpression: x => x.limitedViewers
          }}
          mode="multiple"
          tooltip={tooltips.limitedViewers}
          placeholder="Select"
        />
      </div>
    </PaModal>
  );
};

export default UpsertAgendaModal;
