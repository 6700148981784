import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { UserClaims } from "@okta/okta-auth-js";

let userClaims: UserClaims | undefined = undefined;

const refreshFuncs: Dispatch<SetStateAction<Record<string, unknown>>>[] = [];

export function useOktaUser() {
  const { oktaAuth } = useOktaAuth();
  const [, forceRefresh] = useState({});

  //register forceRefresh for instance
  useEffect(() => {
    if (userClaims) {
      return;
    }

    refreshFuncs.push(forceRefresh);

    if (refreshFuncs.length === 1) {
      oktaAuth.getUser().then(u => {
        if (!userClaims) {
          userClaims = u;

          //refresh instances waiting for claims result and clear all listeners
          refreshFuncs.forEach(f => f({}));
          refreshFuncs.length = 0;
        }
      });
    }
  }, [oktaAuth]);

  return userClaims;
}
