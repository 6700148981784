import React from "react";
import "./ChangeLogPageHeader.scss";
import AgendaTabNavigation from "components/agenda-tab-navigation/AgendaTabNavigation";
import { Title } from "common-components";

const namespace = "rts-pa-change-log-page-header";

export default function ChangeLogPageHeader(): JSX.Element {
  return (
    <div className={namespace}>
      <div className={`${namespace}-left-container`}>
        <Title level={1}>Prioritized Agenda Change Log</Title>
        <AgendaTabNavigation />
      </div>
      <div className={`${namespace}-right-container`}></div>
    </div>
  );
}
