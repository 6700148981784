import { useQuery } from "@tanstack/react-query";
import { EsgItem } from "api/esg";
import { getAgendaItemCached } from "api/cache";
import OwnersContainer from "common-components/owners-container/OwnersContainer";
import PaModal from "common-components/pa-modal/PaModal";
import SummaryInfo from "common-components/summary-info/SummaryInfo";
import { PermissionsContext } from "global/permissions";
import pages from "pages/pages";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./EsgModal.scss";
import EnvironmentalSustainabilityMetrics from "./environmental-sustainability-metrics/EnvironmentalSustanabilityMetrics";
import PermissionsWrapper from "common-components/permissions-wrapper/permissions-wrapper";
import SubmissionRequest from "common-components/submission-request/SubmissionRequest";
import ModalExternalLinks from "common-components/modal-external-links/ModalExternalLinks";
import { useModalHelper } from "global/use-modal-helper";

const namespace = "rts-pa-agenda-esg-modal";

const EsgModal = () => {
  const perms = useContext(PermissionsContext);
  const hasPermissions = perms.grid.esgModal;
  const navigate = useNavigate();

  const mhResult = useModalHelper(
    pages.grid.esgModal.path,
    hasPermissions,
    "esg"
  );

  const { status, data, error } = useQuery<EsgItem>(
    ["agenda-item-cached", mhResult.itemId],
    () => getAgendaItemCached("esg", mhResult.itemId),
    {
      enabled: mhResult.enabled
    }
  );

  return (
    <PaModal
      title={mhResult.title}
      status={status}
      axiosErrors={error}
      isOpen={mhResult.enabled}
      onClose={() => {
        navigate(pages.grid.go());
      }}
      className={namespace}
    >
      <PermissionsWrapper permissions={perms.submissions}>
        <SubmissionRequest agendaItem={mhResult.agendaItem} />
      </PermissionsWrapper>
      <SummaryInfo data={data?.summaryInfo} />
      <OwnersContainer owners={data?.owners} className="esg-modal" />
      <EnvironmentalSustainabilityMetrics
        data={data?.environmentalSustainability}
      />
      <ModalExternalLinks
        links={data?.links}
        className="esg-modal"
        buttonType="primary"
      />
    </PaModal>
  );
};

export default EsgModal;
