import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  EnvelopeIcon
} from "@opsdti-global-component-library/amgen-design-system";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Label, SelectSimple, Text } from "common-components";
import { AdGroupUserList } from "api/getAdGroupUserList";
import "./SubmissionRequest.scss";
import { postSubmissionRequest } from "api/submission/submission-request";
import { getAdGroupUserList } from "api/active-directory";

const namespace = "rts-pa-submission-request";

type Props = {
  agendaItem: { id: number } | undefined;
};

const SubmissionRequest = (props: Props) => {
  const [selectedUserEmail, setSelectedUserEmail] = useState<string>();
  const [selectedUserFullName, setSelectedUserFullName] = useState<string>();
  const [hasSentRequest, setHasSentRequest] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const { NODE_ENV } = window.__RUNTIME_CONFIG__;
  const adGroup = ["development", "test"].includes(NODE_ENV)
    ? "rts-master"
    : "rts-master-prod";

  const {
    status: queryStatus,
    data: queryData = { users: [] },
    error: queryError
  } = useQuery<AdGroupUserList>(
    ["ad-group-user-list", adGroup],
    () => getAdGroupUserList(adGroup),
    {
      onSuccess: () => {
        setErrorMessage(undefined);
      },
      onError: _error => {
        setErrorMessage("Unable to retrieve user list.");
      }
    }
  );

  const {
    status: requestStatus,
    mutate: requestSubmission,
    error: requestError
  } = useMutation(
    () => {
      if (!selectedUserEmail || !props.agendaItem) {
        return Promise.reject("No user selected.");
      }
      return postSubmissionRequest(props.agendaItem.id, selectedUserEmail);
    },
    {
      onSuccess: () => {
        setHasSentRequest(true);
        setErrorMessage(undefined);
      },
      onError: _error => {
        setErrorMessage("Unable to send request. Please try again.");
      }
    }
  );

  useEffect(() => {
    if (selectedUserEmail) {
      const selectedUser = queryData.users.find(
        user => user.email === selectedUserEmail
      );
      if (selectedUser) {
        setSelectedUserFullName(selectedUser.fullName);
      }
    }
  }, [selectedUserEmail, queryData.users]);

  const dropdownData = useMemo(
    () =>
      queryData.users
        .map(user => ({
          value: user.email,
          label: user.fullName
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [queryData.users]
  );

  if (props.agendaItem === undefined) {
    return <></>;
  }

  return (
    <div className={namespace}>
      <Label>REQUEST SUBMISSION</Label>
      <>
        {(queryStatus === "error" || requestStatus === "error") && (
          <Text className={`${namespace}-error`} strong type="danger">
            {`*${errorMessage || queryError || requestError}`}
          </Text>
        )}
      </>
      <div className={`${namespace}-dropdown-container`}>
        <SelectSimple<string>
          className={`${namespace}-dropdown`}
          options={dropdownData}
          onChange={setSelectedUserEmail}
          loading={queryStatus === "loading"}
          placeholder="Select Business Owner"
          disabled={hasSentRequest}
        />
        <Button
          text={
            requestStatus === "loading"
              ? "..."
              : hasSentRequest
              ? "Sent"
              : "Send"
          }
          icon={<EnvelopeIcon height={14} width={14} />}
          onClick={() => requestSubmission()}
          disabled={!selectedUserEmail || hasSentRequest}
          type="primary"
        />
      </div>
      {hasSentRequest && (
        <div className={`${namespace}-note`}>
          <Text>A submission request was sent to</Text>
          <Text strong>{` ${selectedUserFullName || ""}.`}</Text>
        </div>
      )}
    </div>
  );
};

export default SubmissionRequest;
