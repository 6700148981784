import React from "react";
import "./AgendaItemModalGroup.scss";
import { AgendaItem, AgendaItemGroup } from "api/agenda-item/agenda-item";
import AgendaItemModalSection from "../sections/AgendaItemModalSection";

const namespace = "rts-pa-agenda-item-modal-group";

type Props = {
  agendaItem: AgendaItem;
  group: AgendaItemGroup;
};

export const AgendaItemModalGroup = (props: Props) => {
  return (
    <div className={namespace}>
      <div className={`${namespace}-label`}>{props.group.label}</div>
      <div className={`${namespace}-sections`}>
        {props.group.sections.map(s => (
          <AgendaItemModalSection
            key={s.id}
            agendaItem={props.agendaItem}
            section={s}
          />
        ))}
      </div>
    </div>
  );
};

export default AgendaItemModalGroup;
