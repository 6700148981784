import React, { useContext, useState } from "react";
import "./KeyEventsPage.scss";
import PermissionsWrapper from "common-components/permissions-wrapper/permissions-wrapper";
import { PermissionsContext } from "global/permissions";
import { Container } from "@gitlab-rtsensing/component-library";
import KeyEventsPageHeader from "./header/KeyEventsPageHeader";
import KeyEventsCalendar from "components/key-events-calendar/KeyEventsCalendar";
import {
  CalendarFilters,
  RowItem
} from "components/key-events-calendar/calendarTypes";
import {
  getQuarterEnd,
  getQuarterStart
} from "components/key-events-calendar/calendarUtils";
import UpsertCalendarModal from "components/upsert-calendar-modal/UpsertCalendarModal";
import UpsertCalendarRowModal from "components/upsert-calendar-row-modal/UpsertCalendarRowModal";
import produce from "immer";
import { Alert } from "@opsdti-global-component-library/amgen-design-system";
import { CalendarContext } from "./CalendarContext";

const namespace = "rts-pa-key-events-page";

export default function KeyEventsPage(): JSX.Element {
  const isLoaded = useContext(PermissionsContext).isLoaded;
  const [calendarRowsData, setCalendarRowsData] = useState<RowItem[]>([]);
  const [filters, setFilters] = useState<CalendarFilters>({});
  const [onReorderSave, setOnReorderSave] = useState<(() => void) | undefined>(
    undefined
  );

  return (
    <CalendarContext.Provider
      value={{
        calendarRows: calendarRowsData,
        setCalendarRows: setCalendarRowsData
      }}
    >
      <>
        <div className={namespace}>
          <PermissionsWrapper permissions={isLoaded}>
            <Container className={`${namespace}-container`}>
              <KeyEventsPageHeader
                dateRange={
                  filters.startDateMin && filters.startDateMax
                    ? [filters.startDateMin, filters.startDateMax]
                    : []
                }
                onDateRangeChange={dateRange => {
                  const newFilters = produce(filters, draftState => {
                    draftState.startDateMin = getQuarterStart(dateRange[0]);
                    draftState.startDateMax = getQuarterEnd(dateRange[1]);
                  });

                  setFilters(newFilters);
                }}
                onReorderSave={onReorderSave}
              />
              <KeyEventsCalendar
                filters={filters}
                setOnReorderSave={setOnReorderSave}
              />
              <Alert
                content="Yellow emboldened milestone dates indicate that it is subject to change."
                type="warning"
              />
            </Container>
          </PermissionsWrapper>
        </div>
        <PermissionsWrapper permissions={isLoaded}>
          <UpsertCalendarRowModal />
          <UpsertCalendarModal />
        </PermissionsWrapper>
      </>
    </CalendarContext.Provider>
  );
}
