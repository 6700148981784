import React from "react";
import "./AIBuilderModalSectionPlaceholder.scss";
//import { AgendaItemTemplateSectionPlaceholder } from "api/agenda-item-template/sections";
import { SectionBasicProps } from "../../AgendaItemBuilderModalSection";

//const namespace = "rts-pa-agenda-item-builder-modal-section-placeholder";

type Props = SectionBasicProps;

export const AIBuilderModalSectionPlaceholder = (props: Props) => {
  return <></>;

  // return (
  //   <div className={namespace}>
  //   </div>
  // );
};

export default AIBuilderModalSectionPlaceholder;
