import { Literal, Record, Static, String, Union } from "runtypes";
import { AdvancedTooltipValidator, StatusValidator } from "../common";

export const AgendaItemSectionTypeValidator = Union(
  Literal("placeholder"),
  Literal("singleMetric"),
  Literal("salesDemand"),
  Literal("pipelineProject"),
  Literal("location"),
  Literal("inventoryBelowTarget"),
  Literal("engagementScore"),
  Literal("talentAcquisition"),
  Literal("turnover")
);

export type AgendaItemSectionType = Static<
  typeof AgendaItemSectionTypeValidator
>;

export const AgendaItemSectionValidator = Record({
  id: String, //uuid
  title: String,
  type: AgendaItemSectionTypeValidator,
  status: StatusValidator,
  tooltip: AdvancedTooltipValidator.nullable()
});

export type AgendaItemSection = Static<typeof AgendaItemSectionValidator>;
