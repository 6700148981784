import React from "react";
import "./PipelineProjectNextApprovalLaunchE2l.scss";
import { NextApprovalLaunchE2l } from "api";
import {
  CalendarIcon,
  LocationIcon
} from "@opsdti-global-component-library/amgen-design-system";
import dayjs from "dayjs";
import PipelineStatusWithTooltip from "common-components/pipeline-status-with-tooltip/PipelineStatusWithTooltip";
import { Label, Text } from "common-components";

const namespace = "rts-pa-pipeline-project-next-approval-launch-e2l";

type Props = {
  data: NextApprovalLaunchE2l | undefined;
};

const PipelineProjectNextApprovalLaunchE2l = (props: Props) => {
  if (!props.data) {
    return <></>;
  }

  let title = "";
  switch (props.data.archetype) {
    case "e2lPortal": // Pipeline
      title = "Next Priority E2L Portal";
      break;
    case "priorityApproval": // Commercial
      title = "Next Priority Approval";
      break;
    case "priorityLaunch": // Both Pipeline and Commercial
      title = "Next Priority Launch";
      break;
  }

  return (
    <div className={namespace}>
      <Label>{title}</Label>

      <div className={`${namespace}-content`}>
        <Text strong>{props.data.descriptor}</Text>

        <div className={`${namespace}-columns`}>
          <div className={`${namespace}-columns-left`}>
            {props.data.date && (
              <div className={`${namespace}-date-container`}>
                <CalendarIcon width={16} height={16} />
                <Text>{dayjs(props.data.date).format("MM/DD/YYYY")}</Text>
              </div>
            )}
          </div>

          <div className={`${namespace}-columns-right`}>
            {props.data.geographicArea && (
              <div className={`${namespace}-geo-container`}>
                <LocationIcon width={16} height={16} />
                <Text>{props.data.geographicArea}</Text>
              </div>
            )}
            {props.data.status && props.data.statusTooltip && (
              <div className={`${namespace}-status-container`}>
                <PipelineStatusWithTooltip
                  status={props.data.status}
                  tooltip={props.data.statusTooltip}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PipelineProjectNextApprovalLaunchE2l;
