import { Literal, Record, Static, String, Union } from "runtypes";

const AdEntityTypeValidator = Union(Literal("user"), Literal("group"));

export type AdEntityType = Static<typeof AdEntityTypeValidator>;

export const AdEntityValidator = Record({
  // id: Number, // FE doesn't care; main PK is username anyway
  displayName: String,
  username: String,
  email: String,
  type: AdEntityTypeValidator
});

export type AdEntity = Static<typeof AdEntityValidator>;
