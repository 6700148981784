import React, { useEffect } from "react";
import "./InputList.scss";
import classNames from "classnames";
import {
  AutoUpdater,
  AutoUpdaterType,
  useAutoUpdater
} from "global/use-auto-updater";
import { FieldValUtils } from "global/use-validator";
import produce from "immer";
import { v4 as uuid } from "uuid";
import {
  DragDropContext,
  Droppable,
  OnDragEndResponder
} from "react-beautiful-dnd";
import {
  PlusIcon,
  Button
} from "@opsdti-global-component-library/amgen-design-system";
import InputListItem from "./input-list-item/InputListItem";
import { Label } from "common-components";

const namespace = "rts-pa-input-list";

export type InputWithId = { id: string; value: string; order: number };

type Props<T extends AutoUpdaterType> = {
  title: string;
  placeholder?: string;
  autoUpdater: AutoUpdater<T>;
  validator: FieldValUtils;
};

export const InputList = <T extends AutoUpdaterType>(
  props: Props<T>
): JSX.Element => {
  const { value, onChange } = useAutoUpdater<InputWithId[], T>(
    props.autoUpdater
  );

  const items = value || []; //value comes in as "" on first initialization, b/c doesn't know it's an array yet
  useEffect(() => {
    //validate items are InputWithId not String, or throw a console error
    for (const link of items) {
      if (!link.id) {
        // eslint-disable-next-line no-console
        console.error(
          "InputList requires all `InputWithId` items to have an id"
        );
        break;
      }
    }
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  const onAddClick = () => {
    const newLinks = produce(items, draftState => {
      draftState.push({
        id: uuid(),
        value: "",
        order: draftState.length + 1
      });
    });

    onChange(newLinks);
  };

  const onDeleteClick = (i: number) => {
    const newLinks = produce(items, draftState => {
      draftState.splice(i, 1);
    });

    onChange(newLinks);
  };

  const onDragEnd: OnDragEndResponder = result => {
    if (!result.destination) {
      return;
    }

    const sourceIndex = result.source.index;
    const destIndex = result.destination.index;

    if (sourceIndex === destIndex) {
      return;
    }

    const newItems = produce(items, draftState => {
      const item = draftState.splice(sourceIndex, 1)[0];
      draftState.splice(destIndex, 0, item);

      let order = 1;
      for (const item of draftState) {
        item.order = order++;
      }
    });

    onChange(newItems);
  };

  return (
    <div className={namespace}>
      <Label className={`${namespace}-title`}>{props.title}</Label>
      <div className={`${namespace}-container`}>
        {/* create passable-in droppableId if ever needing to render 2 at once */}
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={namespace}>
            {(provided, snapshot) => {
              const classNameBody = classNames(
                `${namespace}-container-droppable`,
                {
                  isDraggingOver: snapshot.isDraggingOver
                }
              );

              return (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className={classNameBody}
                >
                  {items.map((item, i) => (
                    <InputListItem
                      id={item.id}
                      key={item.id}
                      autoUpdater={{
                        ...props.autoUpdater,
                        propExpression: x =>
                          (
                            props.autoUpdater.propExpression(x) as InputWithId[]
                          )[i].value
                      }}
                      autoUpdaterIndex={i}
                      onDeleteClick={onDeleteClick}
                      validator={props.validator}
                      placeholder={props.placeholder || props.title}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              );
            }}
          </Droppable>
        </DragDropContext>
      </div>
      <div className={`${namespace}-footer`}>
        <Button
          text={`Add ${props.title}`}
          type="primary"
          icon={<PlusIcon width={14} height={14} />}
          onClick={onAddClick}
        />
      </div>
    </div>
  );
};

export default InputList;
