import React from "react";
import TooltipContent from "common-components/tooltip-content/TooltipContent";
import { AdvancedTooltip } from "api";
import "./AdvancedTooltipContent.scss";
import classNames from "classnames";
import AdvancedTooltipContentMapper from "./AdvancedTooltipContentMapper";

type Props = {
  tooltip: AdvancedTooltip;
  className?: string;
};

const namespace = "rts-pa-advanced-tooltip-content";

export const AdvancedTooltipContent = (props: Props) => {
  const className = classNames(namespace, props.className);

  return (
    <TooltipContent className={className}>
      {props.tooltip.sections.map((section, i) => {
        const sectionClassName = classNames(`${namespace}-section`, {
          [`${section.rowSpacing}-row-gap`]: section.rowSpacing
        });

        return (
          <div key={i} className={sectionClassName}>
            {section.title && (
              <TooltipContent.Gray>{section.title}</TooltipContent.Gray>
            )}
            {section.rows.map((row, i) => (
              <div key={i} className={`${namespace}-section-row`}>
                {row.label && (
                  <TooltipContent.Bold>{`${row.label} `}</TooltipContent.Bold>
                )}
                {row.content.map((content, i) => {
                  return (
                    <AdvancedTooltipContentMapper
                      key={i}
                      id={i}
                      content={content}
                    />
                  );
                })}
              </div>
            ))}
          </div>
        );
      })}
    </TooltipContent>
  );
};

export default AdvancedTooltipContent;
