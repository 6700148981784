import axios from "axios";
import { Literal, Null, Record, Static, String } from "runtypes";
import { getAgendaId } from "utils/multitenancy";

export enum CheckoutStatus {
  locked = "locked",
  unlocked = "unlocked"
}

const LockedStateValidator = Record({
  status: Literal(CheckoutStatus.locked),
  username: String
});

const UnlockedStateValidator = Record({
  status: Literal(CheckoutStatus.unlocked),
  username: Null
});

const CheckoutStatusValidator = LockedStateValidator.Or(UnlockedStateValidator);

export type CheckoutStatusResult = Static<typeof CheckoutStatusValidator>;

export const checkout = async (): Promise<CheckoutStatusResult> => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  const result = await axios.get<CheckoutStatusResult>(
    `${REACT_APP_DOMAIN}/api/v2/${getAgendaId()}/conflict_management/checkout`
  );

  CheckoutStatusValidator.check(result.data);

  return result.data;
};

export const checkin = async (): Promise<CheckoutStatusResult> => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  const result = await axios.get<CheckoutStatusResult>(
    `${REACT_APP_DOMAIN}/api/v2/${getAgendaId()}/conflict_management/checkin`
  );

  CheckoutStatusValidator.check(result.data);

  return result.data;
};

export const getCheckoutStatus = async (): Promise<CheckoutStatusResult> => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  const result = await axios.get<CheckoutStatusResult>(
    `${REACT_APP_DOMAIN}/api/v2/${getAgendaId()}/conflict_management/status`
  );

  CheckoutStatusValidator.check(result.data);

  return result.data;
};
