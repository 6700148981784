import { SupplyKeyInsightsType } from "api/esg-item/esg-common";
import React from "react";
import "./SupplyKeyInsights.scss";
import { Label, Text } from "common-components";

const namespace = "rts-pa-modal-supply-key-insights";

type Props = {
  data: SupplyKeyInsightsType;
};

type SupplyKeyInsightsProps = {
  label: string;
  value: string | null;
};

const SupplyKeyInsightsSection = ({ label, value }: SupplyKeyInsightsProps) => {
  if (!value) {
    return <></>;
  }
  return (
    <div className={`${namespace}-section`}>
      <Label className={`${namespace}-label`}>{label}</Label>
      <Text>{value}</Text>
    </div>
  );
};
const SupplyKeyInsights = ({ data }: Props) => {
  return (
    <div className={namespace}>
      <div className={`${namespace}-container`}>
        <SupplyKeyInsightsSection label="Spotlight" value={data.spotlight} />
        <SupplyKeyInsightsSection
          label="Root Cause / Action"
          value={data.rootCauseAction}
        />
        <SupplyKeyInsightsSection label="Owner" value={data.owner} />
        <SupplyKeyInsightsSection
          label="Assistance Needed"
          value={data.assistanceNeeded}
        />
      </div>
    </div>
  );
};

export default SupplyKeyInsights;
