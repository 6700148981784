import getAdvancedTooltipContent from "common-components/advanced-tooltip/getAdvancedTooltipContent";
import SupplyKeyInsights from "common-components/supply-key-insights/SupplyKeyInsights";
import React from "react";
import "./EsgTotalCarbonWasteWaterReductionOffset.scss";
import { Label, Text } from "common-components";
import { TotalCarbonWasteWaterReductionOffset } from "api/esg-item/total-carbon-waste-water-reduction-offset";
import { AccordionWithStatus } from "common-components/accordion-with-status/AccordionWithStatus";
import { InactiveSection } from "api";
type Props = {
  data: TotalCarbonWasteWaterReductionOffset | InactiveSection;
};

type MetricProps = {
  label: string;
  value: number;
};

const namespace = "rts-pa-agenda-esg-total-carbon-waste-water-reduction-offset";

const toDisplayValue = (value: number): string => value.toLocaleString();

const EsgMetric = ({ label, value }: MetricProps) => {
  return (
    <div className={`${namespace}-metric`}>
      <Label>{label}</Label>
      <Text>{toDisplayValue(value)}</Text>
    </div>
  );
};
const EsgTotalCarbonWasteWaterReductionOffset = ({ data }: Props) => {
  if (!data) {
    return <></>;
  } else if (data.status === "inactive") {
    return <AccordionWithStatus title={data.title} status="gray" />;
  }
  return (
    <AccordionWithStatus
      title="Total Carbon, Waste, Water Reduction/Offset"
      status={data.status}
      className={namespace}
      tooltipContent={getAdvancedTooltipContent(data.tooltip)}
      links={data.links}
      areLinksSmall={true}
      startOpen={true}
    >
      <SupplyKeyInsights data={data.supplyKeyInsights} />
      <div className={`${namespace}-metrics`}>
        <EsgMetric label="Carbon (MT)" value={data.carbon} />
        <EsgMetric label="Waste (MT)" value={data.waste} />
        <EsgMetric label="Water (M3)" value={data.water} />
      </div>
    </AccordionWithStatus>
  );
};

export default EsgTotalCarbonWasteWaterReductionOffset;
