import { Literal, Static } from "runtypes";
import { AgendaItemTemplateSectionValidator } from "../agenda-item-template-common";

export const AgendaItemTemplateSectionTurnoverValidator =
  AgendaItemTemplateSectionValidator.extend({
    type: Literal("turnover")
  });

export type AgendaItemTemplateSectionTurnover = Static<
  typeof AgendaItemTemplateSectionTurnoverValidator
>;
