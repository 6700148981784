import React, { useState } from "react";
import classNames from "classnames";
import "./Accordion.scss";
import { useAccordionSync, AccordionSyncKey } from "./useAccordionSync";
import { Collapse } from "@opsdti-global-component-library/amgen-design-system";

export type AccordionProps = {
  title?: string | JSX.Element | JSX.Element[] | null;
  startOpen?: boolean;
  caretClickOnly?: boolean;
  preContent?: JSX.Element | JSX.Element[];
  children: JSX.Element | JSX.Element[];
  postContent?: JSX.Element | JSX.Element[];
  isOpen?: boolean; //used to create a controlled component
  onClick?: (isCollapsed: boolean) => void;
  className?: string;
  accordionSyncKey?: AccordionSyncKey;
  accordionSyncId?: string;
};

export const Accordion = (props: AccordionProps): JSX.Element => {
  const { title, preContent, children, postContent } = props;
  const [_isOpen, _setIsOpen] = useState(!!props.startOpen);
  const accordionSync = useAccordionSync(
    props.accordionSyncKey,
    props.accordionSyncId || props.className,
    props.startOpen
  );

  const className = classNames("rts-pa-accordion", props.className);

  let isOpen: boolean;
  if (accordionSync?.isOpen !== undefined) {
    isOpen = accordionSync.isOpen;
  } else if (props.isOpen !== undefined) {
    isOpen = props.isOpen;
  } else {
    isOpen = _isOpen;
  }

  const onClick = () => {
    accordionSync?.onClick();
    props.onClick && props.onClick(!isOpen);
    _setIsOpen(!isOpen);
  };

  const accordionContent = [
    {
      key: props.accordionSyncId || props.className,
      children: children,
      label: title,
      leftIcon: preContent,
      rightIcon: postContent
    }
  ];

  return (
    <Collapse
      className={className}
      data-testid="accordion"
      items={accordionContent}
      activeKey={isOpen ? props.accordionSyncId || props.className : ""}
      onChange={onClick}
      triggerArea={props.caretClickOnly ? "icon" : "header"}
    />
  );
};

export default Accordion;
