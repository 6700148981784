import React from "react";
import "./AgendaItemBuilderModalGroupsContainer.scss";
import {
  DragDropContext,
  Droppable,
  OnDragEndResponder
} from "react-beautiful-dnd";
import classnames from "classnames";
import { AgendaItemTemplate } from "api/agenda-item-template/agenda-item-template";
import AgendaItemBuilderModalGroup from "../group/AgendaItemBuilderModalGroup";
import { AutoUpdaterSetItem } from "global/use-auto-updater";
import { FieldValUtils } from "global/use-validator";
import produce from "immer";
import { v4 as uuid } from "uuid";
import {
  PlusIcon,
  Button
} from "@opsdti-global-component-library/amgen-design-system";

const namespace = "rts-pa-agenda-item-builder-modal-groups-container";

type Props = {
  agendaItemTemplate: AgendaItemTemplate;
  setAgendaItemTemplate: AutoUpdaterSetItem<AgendaItemTemplate>;
  fieldUtils: FieldValUtils;
};

const groupDroppableType = "aibm-group";

export const AgendaItemBuilderModalGroupsContainer = (props: Props) => {
  const onAddGroupClick = () => {
    const newTemplate = produce(props.agendaItemTemplate, draftState => {
      draftState.groups.push({
        id: uuid(),
        label: "",
        sections: []
      });
    });

    props.setAgendaItemTemplate(newTemplate);
  };

  const onDragEnd: OnDragEndResponder = result => {
    if (!result.destination) {
      return;
    }

    if (result.type === groupDroppableType) {
      const newTemplate = produce(props.agendaItemTemplate, draftState => {
        const group = draftState.groups.splice(result.source.index, 1)[0];

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        draftState.groups.splice(result.destination!.index, 0, group);
      });

      props.setAgendaItemTemplate(newTemplate);
    } else {
      const newTemplate = produce(props.agendaItemTemplate, draftState => {
        const group = draftState.groups.find(g => g.id === result.type);
        if (!group) {
          return;
        }

        const section = group.sections.splice(result.source.index, 1)[0];

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        group.sections.splice(result.destination!.index, 0, section);
      });

      props.setAgendaItemTemplate(newTemplate);
    }
  };

  return (
    <div className={namespace}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="aibm-groups" type={groupDroppableType}>
          {(provided, snapshot) => {
            const classNameBody = classnames(`${namespace}-droppable`, {
              isDraggingOver: snapshot.isDraggingOver
            });

            return (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className={classNameBody}
              >
                {props.agendaItemTemplate.groups.map((group, i) => (
                  <AgendaItemBuilderModalGroup
                    key={group.id}
                    group={group}
                    index={i}
                    agendaItemTemplate={props.agendaItemTemplate}
                    setAgendaItemTemplate={props.setAgendaItemTemplate}
                    fieldUtils={props.fieldUtils}
                    groupDroppableType={groupDroppableType}
                  />
                ))}
                {provided.placeholder}
              </div>
            );
          }}
        </Droppable>
      </DragDropContext>
      <Button
        text="Add Group"
        type="primary"
        onClick={onAddGroupClick}
        icon={<PlusIcon width={14} height={14} />}
      />
    </div>
  );
};

export default AgendaItemBuilderModalGroupsContainer;
