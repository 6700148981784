import { AdvancedTooltip } from "api";

const title: AdvancedTooltip = {
  sections: [
    {
      rows: [
        {
          content: [
            "Please enter an official name for your Prioritized Agenda. This will be the display title visible to all administrative users & viewers."
          ]
        }
      ]
    }
  ]
};

const publishedDate: AdvancedTooltip = {
  sections: [
    {
      rows: [
        {
          content: [
            'Please enter an official "as of" date for your Prioritized Agenda. You will be able to modify this in the future, and it will be visible to all administrative users & viewers.'
          ]
        }
      ]
    }
  ]
};

const owner: AdvancedTooltip = {
  sections: [
    {
      rows: [
        {
          content: [
            "The creator of the Prioritized Agenda. Automatically receives Admin privileges."
          ]
        }
      ]
    }
  ]
};

const admins: AdvancedTooltip = {
  sections: [
    {
      rows: [
        {
          content: [
            "Enter users that should have administrative rights to make direct changes to this Prioritized Agenda. These users will be able to edit, modify and delete contents. Please enter individual Amgen email addresses or an active Amgen AD group address.​"
          ]
        }
      ]
    }
  ]
};

const fullViewers: AdvancedTooltip = {
  sections: [
    {
      rows: [
        {
          content: [
            "Enter users that should be able to view items and any available stoplight statuses on this Prioritized Agenda. Please enter individual Amgen email addresses or an active Amgen AD group address."
          ]
        }
      ]
    }
  ]
};

const limitedViewers: AdvancedTooltip = {
  sections: [
    {
      rows: [
        {
          content: [
            "Enter users that should be able to view items on the Prioritized Agenda, but NOT view stoplight statuses for any items or metrics. Please enter individual Amgen email addresses or an active Amgen AD group address."
          ]
        }
      ]
    }
  ]
};

export const tooltips = {
  title: title,
  publishedDate: publishedDate,
  owner: owner,
  admins: admins,
  fullViewers: fullViewers,
  limitedViewers: limitedViewers
};
