import { QueryCache, QueryClient } from "@tanstack/react-query";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: { refetchOnMount: "always", staleTime: Infinity }
  },
  queryCache: new QueryCache({
    onError(error, query) {
      console.error(error); // eslint-disable-line no-console
    }
  })
});
