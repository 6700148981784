import { QueryStatus } from "@tanstack/react-query";
import React from "react";
import { Loader } from "@gitlab-rtsensing/component-library";
import "./PaLoader.scss";
import { AxiosError } from "axios";

type Props = {
  children: JSX.Element | JSX.Element[];
  status: QueryStatus;
  axiosErrors: unknown | unknown[];
  componentName: string;
};

const namespace = "rts-pa-loader";

const PaLoader = (props: Props) => {
  if (props.axiosErrors) {
    const errors: AxiosError[] = Array.isArray(props.axiosErrors)
      ? props.axiosErrors
      : [props.axiosErrors];

    for (const error of errors) {
      const statusCode = error?.response?.status;

      let message = "";
      switch (statusCode) {
        case 401:
          message = "You need to sign in.";
          break;
        case 403:
          message = `You are not authorized to view this ${props.componentName}.`;
          break;
      }

      return (
        <div className={namespace}>
          <div className="error-message">{message}</div>
        </div>
      );
    }
  }

  return <Loader status={props.status}>{props.children}</Loader>;
};

export default PaLoader;
