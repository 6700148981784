import React from "react";
import {
  Card,
  Spin
} from "@opsdti-global-component-library/amgen-design-system";
import "./HomeTile.scss";

type HomeTileProps = {
  title: string;
  footer: React.ReactNode;
  imageUrl?: string;
  isLoading: boolean;
};

export const HomeTile = (props: HomeTileProps) => {
  return (
    <Card
      className="rts-pa-home-tile"
      title={props.title}
      footer={<div className="pa-home-tile-footer">{props.footer}</div>}
    >
      <div className="pa-home-tile-image-container">
        {props.isLoading ? (
          <Spin size="default" />
        ) : (
          <img
            className="pa-home-tile-image"
            src={props.imageUrl}
            alt={props.title}
          />
        )}
      </div>
    </Card>
  );
};
