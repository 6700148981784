import React from "react";
import "./Label.scss";
import {
  Label as LabelCompLib,
  LabelProps,
  Text,
  theme
} from "@opsdti-global-component-library/amgen-design-system";
import { AdvancedTooltip } from "api";
import Tooltip from "common-components/tooltip/Tooltip";
import { getAdvancedTooltipContent } from "common-components";

const { token } = theme;

type LibColorVariant = Exclude<LabelProps["colorVariant"], undefined>;
type ColorVariant =
  | LibColorVariant
  | "tertiary"
  | "selected"
  | "white"
  | "dark";

type Props = Omit<LabelProps, "colorVariant"> & {
  children?: React.ReactNode;
  keepSpaceWhenEmpty?: boolean;
  colorVariant?: ColorVariant;
  requiredAsterisk?: boolean;
  tooltip?: AdvancedTooltip;
};

const namespace = "rts-pa-label";

export const Label = ({
  children,
  keepSpaceWhenEmpty,
  colorVariant,
  requiredAsterisk,
  tooltip,
  style,
  ...rest
}: Props) => {
  if (!children) {
    if (keepSpaceWhenEmpty) {
      return <LabelCompLib {...rest}>&nbsp;</LabelCompLib>;
    }
    return null;
  }

  const colorMap = {
    default: undefined,
    secondary: undefined,
    tertiary: token?.colorTextDescription,
    selected: token?.colorPrimary,
    white: token?.colorTextLightSolid,
    dark: token?.colorTextHeading
  };

  const color = colorMap[colorVariant || "default"];
  const finalColorVariant = !color
    ? "default"
    : (colorVariant as LibColorVariant);

  return (
    <LabelCompLib
      colorVariant={finalColorVariant}
      style={style ? style : { color: color }}
      {...rest}
    >
      <div className={namespace}>
        {children}
        {requiredAsterisk && <Text type="danger"> *</Text>}
        {tooltip && <Tooltip>{getAdvancedTooltipContent(tooltip)}</Tooltip>}
      </div>
    </LabelCompLib>
  );
};

export default Label;
