import React from "react";

//TODO JCG: change icon from an X to actual trash can once it becomes available

type Props = {
  height?: number;
  width?: number;
  onClick?: () => void;
  className?: string;
  color?: string;
};

export const TrashIcon = (props: Props) => {
  const {
    width = 15,
    height = 14,
    onClick,
    className,
    color = "currentColor"
  } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        d="M12.1667 3.49996H9.83333V2.91663C9.83333 2.4525 9.64896 2.00738 9.32077 1.67919C8.99258 1.351 8.54746 1.16663 8.08333 1.16663H6.91667C6.45254 1.16663 6.00742 1.351 5.67923 1.67919C5.35104 2.00738 5.16667 2.4525 5.16667 2.91663V3.49996H2.83333C2.67862 3.49996 2.53025 3.56142 2.42085 3.67081C2.31146 3.78021 2.25 3.92858 2.25 4.08329C2.25 4.238 2.31146 4.38638 2.42085 4.49577C2.53025 4.60517 2.67862 4.66663 2.83333 4.66663H3.41667V11.0833C3.41667 11.5474 3.60104 11.9925 3.92923 12.3207C4.25742 12.6489 4.70254 12.8333 5.16667 12.8333H9.83333C10.2975 12.8333 10.7426 12.6489 11.0708 12.3207C11.399 11.9925 11.5833 11.5474 11.5833 11.0833V4.66663H12.1667C12.3214 4.66663 12.4697 4.60517 12.5791 4.49577C12.6885 4.38638 12.75 4.238 12.75 4.08329C12.75 3.92858 12.6885 3.78021 12.5791 3.67081C12.4697 3.56142 12.3214 3.49996 12.1667 3.49996ZM6.33333 2.91663C6.33333 2.76192 6.39479 2.61354 6.50419 2.50415C6.61358 2.39475 6.76196 2.33329 6.91667 2.33329H8.08333C8.23804 2.33329 8.38642 2.39475 8.49581 2.50415C8.60521 2.61354 8.66667 2.76192 8.66667 2.91663V3.49996H6.33333V2.91663ZM10.4167 11.0833C10.4167 11.238 10.3552 11.3864 10.2458 11.4958C10.1364 11.6052 9.98804 11.6666 9.83333 11.6666H5.16667C5.01196 11.6666 4.86358 11.6052 4.75419 11.4958C4.64479 11.3864 4.58333 11.238 4.58333 11.0833V4.66663H10.4167V11.0833Z"
        fill={color}
      />
    </svg>
  );
};

export default TrashIcon;
