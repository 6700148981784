import { useEffect } from "react";
import { useNavLinks } from "./use-nav-links";
import {
  NavLink,
  NavLinkSections,
  NavManager,
  NavSection
} from "@amgen/rtsensing-nav";
import { Permissions } from "global/permissions";
import { getAgendaId } from "utils/multitenancy";

//one navManager instance per site
let navManager: NavManager | undefined = undefined;
if (NavManager) {
  navManager = new NavManager("Prioritized Agenda");
}

function pruneNavSections(
  navSections: NavSection[] | undefined
): NavSection[] | undefined {
  if (!navSections) {
    return undefined;
  }

  const newNavSections: NavSection[] = [];

  for (const navSection of navSections) {
    const newNavSectionLinks = navSection.links.filter(ns => !ns.disabled);

    if (newNavSectionLinks.length) {
      newNavSections.push({
        sectionLabel: navSection.sectionLabel,
        links: newNavSectionLinks
      });
    }
  }

  if (newNavSections.length) {
    return newNavSections;
  }

  return undefined;
}

export function useNavManager(permissions: Permissions) {
  const { status, links } = useNavLinks(permissions);

  useEffect(() => {
    if (!navManager || status !== "success") {
      return;
    }

    let relatedLinks: NavLink[] = [];
    // PA related links
    if (getAgendaId() !== "0") {
      relatedLinks = [
        links.external.pa.functionalPa,
        links.external.pa.ceoStaff,
        links.external.pa.operatingTeam
      ];
    }

    const navLinkSections: NavLinkSections = {
      menuSections: [
        {
          label: "Quick Links",
          sectionType: "internal",
          sections: [
            {
              sectionLabel: "Prioritized Agenda",
              links: links.agendas
            },
            {
              sectionLabel: "Key Events",
              links: links.calendars
            }
          ]
        },
        {
          label: "Admin Options",
          sectionType: "internal",
          sections: [
            {
              sectionLabel: "Prioritized Agenda",
              links: links.admin.pa
            },
            {
              sectionLabel: "Key Events",
              links: links.admin.ke
            }
          ]
        },
        {
          label: "Related Links",
          sectionType: "external",
          sections: [
            {
              sectionLabel: "",
              links: relatedLinks
            }
          ]
        }
      ]
    };

    if (!navLinkSections.menuSections) {
      // satisfies typescript
      return;
    }

    // remove disabled/hidden links and corresponding menuSections
    for (let i = navLinkSections.menuSections.length - 1; 0 <= i; i--) {
      const newNavSections = pruneNavSections(
        navLinkSections.menuSections[i].sections
      );
      if (!newNavSections) {
        navLinkSections.menuSections.splice(i, 1);
      } else {
        navLinkSections.menuSections[i].sections = newNavSections;
      }
    }

    navManager.setNavLinkSections(navLinkSections);
  }, [permissions, status, links]);
}
