import { Literal, Static, Union } from "runtypes";
import { AgendaItemTemplateSectionValidator } from "../agenda-item-template-common";

export const AgendaItemTemplateSectionSalesDemandSubtypeValidator = Union(
  Literal("netSales"),
  Literal("nbrxDemand"),
  Literal("trxDemand"),
  Literal("trxDotDemand")
);

export type AgendaItemTemplateSectionSalesDemandSubtype = Static<
  typeof AgendaItemTemplateSectionSalesDemandSubtypeValidator
>;

export const AgendaItemTemplateSectionSalesDemandScopeValidator = Union(
  Literal("us")
);

export type AgendaItemTemplateSectionSalesDemandScope = Static<
  typeof AgendaItemTemplateSectionSalesDemandScopeValidator
>;

export const AgendaItemTemplateSectionSalesDemandValidator =
  AgendaItemTemplateSectionValidator.extend({
    type: Literal("salesDemand"),
    subtype: AgendaItemTemplateSectionSalesDemandSubtypeValidator,
    scope: AgendaItemTemplateSectionSalesDemandScopeValidator
  });

export type AgendaItemTemplateSectionSalesDemand = Static<
  typeof AgendaItemTemplateSectionSalesDemandValidator
>;
