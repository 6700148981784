import { Literal, Static, String } from "runtypes";
import { AgendaItemSectionValidator } from "../agenda-item-common";

export const AgendaItemSectionSingleMetricValidator =
  AgendaItemSectionValidator.extend({
    type: Literal("singleMetric"),
    value: String
  });

export type AgendaItemSectionSingleMetric = Static<
  typeof AgendaItemSectionSingleMetricValidator
>;
