import React, { useEffect, useState } from "react";
import "./LinksInput.scss";
import {
  AutoUpdater,
  AutoUpdaterType,
  useAutoUpdater
} from "global/use-auto-updater";
import { FieldValUtils } from "global/use-validator";
import LinksInputItem from "./item/LinksInputItem";
import {
  PlusIcon,
  Button
} from "@opsdti-global-component-library/amgen-design-system";
import produce from "immer";
import {
  DragDropContext,
  Droppable,
  OnDragEndResponder
} from "react-beautiful-dnd";
import classnames from "classnames";
import { v4 as uuid } from "uuid";
import { CheckboxSimple, Label, Text } from "common-components/";
import { ExternalLink } from "api/common";

const namespace = "rts-pa-links-input";

export type ExternalLinkWithId = ExternalLink & { id: string; order: number };

export type Props<T extends AutoUpdaterType> = {
  title: string;
  className?: string;
  autoUpdater: AutoUpdater<T>;
  validator: FieldValUtils;
  confirmation?: boolean;
};

export const LinksInput = <T extends AutoUpdaterType>(
  props: Props<T>
): JSX.Element => {
  const { value: links, onChange } = useAutoUpdater<ExternalLinkWithId[], T>(
    props.autoUpdater
  );
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [isConfirmed, setIsConfirmed] = useState<boolean>(true);

  useEffect(() => {
    //validate ExternalLinks have Id, or throw a console error
    for (const link of links) {
      if (!link.id) {
        // eslint-disable-next-line no-console
        console.error(
          "LinksInput requires all `ExternalLink` items to have an id"
        );
        break;
      }
    }

    if (isInitialized) {
      setIsConfirmed(false);
    } else if (!isInitialized && links.length) {
      setIsInitialized(true);
    }
  }, [links]); // eslint-disable-line react-hooks/exhaustive-deps

  const onAddClick = () => {
    const newLinks = produce(links, draftState => {
      draftState.push({
        id: uuid(),
        href: "",
        label: "",
        isSensingExternal: true,
        order: draftState.length + 1
      });
    });

    onChange(newLinks);
    setIsInitialized(true);
  };

  const onDeleteClick = (i: number) => {
    const newLinks = produce(links, draftState => {
      draftState.splice(i, 1);
    });

    onChange(newLinks);
  };

  const onDragEnd: OnDragEndResponder = result => {
    if (!result.destination) {
      return;
    }

    const sourceIndex = result.source.index;
    const destIndex = result.destination.index;

    if (sourceIndex === destIndex) {
      return;
    }

    const newLinks = produce(links, draftState => {
      const link = draftState.splice(sourceIndex, 1)[0];
      draftState.splice(destIndex, 0, link);

      let order = 1;
      for (const link of draftState) {
        link.order = order++;
      }
    });

    onChange(newLinks);
  };

  return (
    <div className={namespace}>
      <div className={`${namespace}-title`}>
        <Label>{props.title}</Label>
      </div>
      <div className={`${namespace}-description`}>
        <Text>
          To update the current links, copy and enter your new links in the URL
          fields below. Please ensure your links are accessible Amgen-wide.
          Please reach out to <a href="mailto:hwebber@amgen.com">Hana Webber</a>{" "}
          if you need support.
        </Text>
      </div>
      <div className={`${namespace}-container`}>
        {/* create passable-in droppableId if ever needing to render 2 at once */}
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={namespace}>
            {(provided, snapshot) => {
              const classNameBody = classnames(
                `${namespace}-container-droppable`,
                {
                  isDraggingOver: snapshot.isDraggingOver
                }
              );

              return (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className={classNameBody}
                >
                  {links.map((link, i) => (
                    <LinksInputItem
                      key={link.id}
                      id={link.id}
                      title={`Link ${i + 1}`}
                      autoUpdater={{
                        item: props.autoUpdater.item,
                        propExpressionLabel: x =>
                          (
                            props.autoUpdater.propExpression(
                              x
                            ) as ExternalLink[]
                          )[i].label,
                        propExpressionHref: x =>
                          (
                            props.autoUpdater.propExpression(
                              x
                            ) as ExternalLink[]
                          )[i].href,
                        propExpressionIsExternal: x =>
                          (
                            props.autoUpdater.propExpression(
                              x
                            ) as ExternalLink[]
                          )[i].isSensingExternal,
                        setItem: props.autoUpdater.setItem
                      }}
                      autoUpdaterIndex={i}
                      onDeleteClick={onDeleteClick}
                      validator={props.validator}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              );
            }}
          </Droppable>
        </DragDropContext>
      </div>
      <div className={`${namespace}-footer`}>
        <Button
          text="Add Link"
          type="primary"
          icon={<PlusIcon width={14} height={14} />}
          onClick={onAddClick}
        />
        {props.confirmation && (
          <CheckboxSimple
            label="I have confirmed that users will be able to access the above link(s)"
            errorLabel="Confirmation"
            checked={isConfirmed}
            onChange={setIsConfirmed}
            validator={props.validator}
            required={true}
          />
        )}
      </div>
    </div>
  );
};

export default LinksInput;
