/* eslint-disable no-case-declarations */
//import axios from "axios";
import { AgendaItemTemplate } from "./agenda-item-template/agenda-item-template";
import {
  AgendaItem,
  AgendaItemGroup,
  AgendaItemValidator
} from "./agenda-item/agenda-item";
import { SummaryInfo } from "./common";
import {
  AgendaItemSectionEngagementScore,
  AgendaItemSectionInventoryBelowTarget,
  AgendaItemSectionLocation,
  AgendaItemSectionPipelineProject,
  AgendaItemSectionPlaceholder,
  AgendaItemSectionSalesDemand,
  AgendaItemSectionSingleMetric,
  AgendaItemSectionTalentAcquisition,
  AgendaItemSectionTurnover
} from "./agenda-item/sections";
import * as TestData from "../__fixtures__/agenda-item-template/template-repatha.json";
import * as SamplePlaceholder from "../__fixtures__/agenda-item-template/sections/section-placeholder.json";
import * as SampleSummaryInfo from "../__fixtures__/agenda-item-template/sections/section-summary-info.json";
import * as SampleOwners from "../__fixtures__/agenda-item-template/sections/section-owners.json";
import * as SampleSingleMetric from "../__fixtures__/agenda-item-template/sections/section-single-metric-ytd-vs-fcst.json";
import * as SampleSalesDemand from "../__fixtures__/agenda-item-template/sections/section-sales-demand.json";
import * as SamplePipelineProject from "../__fixtures__/agenda-item-template/sections/section-pipeline-project.json";
import * as SampleLocation from "../__fixtures__/agenda-item-template/sections/section-location.json";
import * as SampleInventoryBelowTarget from "../__fixtures__/agenda-item-template/sections/section-inventory-below-target.json";
import * as SampleEngagementScore from "../__fixtures__/agenda-item-template/sections/section-engagement-score.json";
import * as SampleTalentAcquisition from "../__fixtures__/agenda-item-template/sections/section-talent-acquisition.json";
import * as SampleTurnover from "../__fixtures__/agenda-item-template/sections/section-turnover.json";

export const TEMP_getAgendaItem = (id: number): AgendaItem => {
  const agendaItem = TestData as unknown as AgendaItem;

  AgendaItemValidator.check(agendaItem);

  return agendaItem;
};

export const getAgendaItemPreview = (
  template: AgendaItemTemplate
): AgendaItem => {
  const agendaItemData: AgendaItem = {
    id: template.id,
    name: template.name,
    summaryInfo: { ...SampleSummaryInfo } as SummaryInfo,
    owners: { ...SampleOwners },
    groups: [],
    links: []
  };

  agendaItemData.name = template.name;

  for (const templateGroup of template.groups) {
    const groupData: AgendaItemGroup = {
      id: templateGroup.id,
      label: templateGroup.label,
      sections: []
    };

    agendaItemData.groups.push(groupData);

    for (const templateSection of templateGroup.sections) {
      switch (templateSection.type) {
        case "placeholder":
          const sectionPlaceholder = {
            ...SamplePlaceholder
          } as AgendaItemSectionPlaceholder;

          sectionPlaceholder.id = templateSection.id;
          sectionPlaceholder.title = templateSection.title;
          groupData.sections.push(sectionPlaceholder);
          break;
        case "singleMetric":
          const sectionSingleMetric = {
            ...SampleSingleMetric
          } as AgendaItemSectionSingleMetric;

          sectionSingleMetric.id = templateSection.id;
          sectionSingleMetric.title = templateSection.title;
          groupData.sections.push(sectionSingleMetric);
          break;
        case "salesDemand":
          const sectionSalesDemand = {
            ...SampleSalesDemand
          } as AgendaItemSectionSalesDemand;

          sectionSalesDemand.id = templateSection.id;
          sectionSalesDemand.title = templateSection.title;
          groupData.sections.push(sectionSalesDemand);
          break;
        case "pipelineProject":
          const sectionPipelineProject = {
            ...SamplePipelineProject
          } as AgendaItemSectionPipelineProject;

          sectionPipelineProject.id = templateSection.id;
          sectionPipelineProject.title = templateSection.title;
          groupData.sections.push(sectionPipelineProject);
          break;
        case "location":
          const sectionLocation = {
            ...SampleLocation
          } as AgendaItemSectionLocation;

          sectionLocation.id = templateSection.id;
          sectionLocation.title = templateSection.title;
          groupData.sections.push(sectionLocation);
          break;
        case "inventoryBelowTarget":
          const sectionInventoryBelowTarget = {
            ...SampleInventoryBelowTarget
          } as AgendaItemSectionInventoryBelowTarget;

          sectionInventoryBelowTarget.id = templateSection.id;
          sectionInventoryBelowTarget.title = templateSection.title;
          groupData.sections.push(sectionInventoryBelowTarget);
          break;
        case "engagementScore":
          const sectionEngagementScore = {
            ...SampleEngagementScore
          } as AgendaItemSectionEngagementScore;

          sectionEngagementScore.id = templateSection.id;
          sectionEngagementScore.title = templateSection.title;
          groupData.sections.push(sectionEngagementScore);
          break;
        case "talentAcquisition":
          const sectionTalentAcquisition = {
            ...SampleTalentAcquisition
          } as AgendaItemSectionTalentAcquisition;

          sectionTalentAcquisition.id = templateSection.id;
          sectionTalentAcquisition.title = templateSection.title;
          groupData.sections.push(sectionTalentAcquisition);
          break;
        case "turnover":
          const sectionTurnover = {
            ...SampleTurnover
          } as AgendaItemSectionTurnover;

          sectionTurnover.id = templateSection.id;
          sectionTurnover.title = templateSection.title;
          groupData.sections.push(sectionTurnover);
          break;
      }
    }
  }

  const green: string[] = [];
  const yellow: string[] = [];
  const red: string[] = [];

  agendaItemData.groups.forEach(group => {
    const label = group.label.replace(" Metrics", "");
    let hasYellow = false;

    for (const section of group.sections) {
      if (section.status === "red") {
        red.push(label);
        return;
      } else if (section.status === "yellow") {
        hasYellow = true;
      }
    }

    if (hasYellow) {
      yellow.push(label);
      return;
    }

    green.push(label);
  });

  let groupNames: string[];

  if (red.length) {
    agendaItemData.summaryInfo.status = "red";
    groupNames = red;
  } else if (yellow.length) {
    agendaItemData.summaryInfo.status = "yellow";
    groupNames = yellow;
  } else {
    agendaItemData.summaryInfo.status = "green";
    groupNames = green;
  }

  agendaItemData.summaryInfo.reason = `${groupNames
    .join(", ")
    .replace(/,(?!.*[,].*)/, " and")} metrics.`;

  AgendaItemValidator.check(agendaItemData);

  return agendaItemData;
};
