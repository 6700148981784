import { useMemo } from "react";
import { QueryStatus, useQuery } from "@tanstack/react-query";
import { NavLink } from "@amgen/rtsensing-nav";
import { Permissions } from "global/permissions";
import produce from "immer";
import pages from "pages/pages";
import { getGlobalConfig } from "api/globalConfig";

export type PaNavLinks = {
  agendas: NavLink[];
  calendars: NavLink[];
  admin: {
    pa: NavLink[];
    ke: NavLink[];
  };
  external: {
    pa: {
      ceoStaff: NavLink;
      functionalPa: NavLink;
      operatingTeam: NavLink;
    };
  };
};

const initialPaNavLinks: PaNavLinks = {
  agendas: [],
  calendars: [],
  admin: {
    pa: [],
    ke: []
  },
  external: {
    pa: {
      ceoStaff: {
        label: "",
        url: "",
        disabled: true
      },
      functionalPa: {
        label: "",
        url: "",
        disabled: true
      },
      operatingTeam: {
        label: "",
        url: "",
        disabled: true
      }
    }
  }
};

export function useNavLinks(permissions: Permissions) {
  const { status, data } = useQuery(["global-config"], getGlobalConfig);

  return useMemo<{
    status: QueryStatus;
    links: PaNavLinks;
  }>(() => {
    if (status !== "success" || !data || !permissions?.isLoaded) {
      return { status: "loading", links: initialPaNavLinks };
    }

    const links = produce(initialPaNavLinks, draftState => {
      draftState.agendas = permissions.grid.agendaList.map<NavLink>(a => ({
        label: `${a.title} Prioritized Agenda`,
        disabled: false,
        url: pages.grid.go(a.id.toString())
      }));

      draftState.calendars = permissions.keyEvents.calendarList.map<NavLink>(
        c => ({
          label: `${c.title} Forward 12 Quarters`,
          disabled: false,
          url: pages.keyEvents.go(c.id.toString())
        })
      );

      draftState.admin.pa = [
        {
          label: "Global Config",
          url: pages.grid.globalConfigModal.go(),
          disabled: !permissions.leftNavLinks.globalConfig
        },
        {
          label: "Update Homepage Image",
          url: pages.grid.updateHomepageImageModal.go(),
          disabled: !permissions.leftNavLinks.updateHomepageImage
        }
      ];

      draftState.admin.ke = [];

      draftState.external.pa = {
        ceoStaff: {
          label: "CEO Staff Prioritized Agenda",
          url: data.ceoStaffUrl,
          disabled: !permissions.leftNavLinks.ceoStaff
        },
        functionalPa: {
          label: "Functional Prioritized Agendas",
          url: data.functionalPaUrl,
          disabled: !permissions.leftNavLinks.functionalPas
        },
        operatingTeam: {
          label: "2024 Operating Team GMIP & Deliverables",
          url: data.operatingTeamUrl,
          disabled: !permissions.leftNavLinks.operatingTeam
        }
      };
    });

    return { status: "success", links: links };
  }, [status, data, permissions]); // eslint-disable-line react-hooks/exhaustive-deps
}
