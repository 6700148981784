import { Array, Boolean, Number, Record, Static, String } from "runtypes";
import { CalendarMilestoneValidator } from "./calendar-common";

export const CalendarRowItemValidator = Record({
  id: Number,
  calendarRowVersionId: Number,
  calendarRowLinkId: String.nullable(),
  order: Number,
  name: String,
  isVisibleToOfficers: Boolean,
  linkToSource: String.nullable(),
  automatedMilestones: Array(CalendarMilestoneValidator),
  manualMilestones: Array(CalendarMilestoneValidator)
});

export type CalendarRowItem = Static<typeof CalendarRowItemValidator>;

export const CalendarRowItemsValidator = Array(CalendarRowItemValidator);

export type CalendarRowItems = Static<typeof CalendarRowItemsValidator>;
