import React from "react";
import "./ComModalPeopleMetrics.scss";
import { CommercialPeopleSection } from "api";
import ModalSection from "common-components/modal-section/ModalSection";
import ComModalPeopleEngagementScore from "./engagement-score/ComModalPeopleEngagementScore";
import ComModalPeopleTalentAcquisition from "./talent-acquisition/ComModalPeopleTalentAcquisition";
import ComModalPeopleTurnover from "./turnover/ComModalPeopleTurnover";

type Props = {
  agendaItemTitle: string | undefined;
  data: CommercialPeopleSection | undefined | null;
};

const ComModalPeopleMetrics = (props: Props) => {
  if (!props.data) {
    return <></>;
  }

  return (
    <ModalSection
      title="People Metrics"
      className="rts-pa-commercial-modal-people-metrics"
    >
      <ComModalPeopleEngagementScore
        agendaItemTitle={props.agendaItemTitle || ""}
        data={props.data.engagementScore}
      />
      <ComModalPeopleTalentAcquisition
        agendaItemTitle={props.agendaItemTitle || ""}
        data={props.data.talentAcquisition}
      />
      <ComModalPeopleTurnover
        agendaItemTitle={props.agendaItemTitle || ""}
        data={props.data.turnover}
      />
    </ModalSection>
  );
};

export default ComModalPeopleMetrics;
