import axios from "axios";
import { Number, Record, Static, String } from "runtypes";

const GlobalConfigValidator = Record({
  id: Number,
  ceoStaffUrl: String,
  functionalPaUrl: String,
  operatingTeamUrl: String
});

export type GlobalConfig = Static<typeof GlobalConfigValidator>;

export const getGlobalConfig = async (): Promise<GlobalConfig> => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  const result = await axios.get<GlobalConfig>(
    `${REACT_APP_DOMAIN}/api/v2/global_config`
  );

  GlobalConfigValidator.check(result.data);

  return result.data;
};

export const putGlobalConfig = async (
  globalConfig: GlobalConfig
): Promise<GlobalConfig> => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  const result = await axios.put<GlobalConfig>(
    `${REACT_APP_DOMAIN}/api/v2/global_config`,
    globalConfig
  );

  GlobalConfigValidator.check(result.data);

  return result.data;
};
