import axios from "axios";
import { Record, Static, String } from "runtypes";
import { getAgendaId } from "utils/multitenancy";

const SubmissionRequestValidator = Record({
  submissionId: String,
  href: String,
  submissionTitle: String
});

export type SubmissionRequest = Static<typeof SubmissionRequestValidator>;

export const postSubmissionRequest = async (
  agendaItemId: number,
  email: string
) => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  const result = await axios.post(
    `${REACT_APP_DOMAIN}/api/v2/${getAgendaId()}/submission/request`,
    {
      agendaItemId: agendaItemId,
      userEmail: email
    }
  );

  SubmissionRequestValidator.check(result.data);

  return result.data;
};
