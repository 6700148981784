import React from "react";
import "./Table.scss";
import {
  ColumnsType,
  Table as TableCompLib,
  TableProps
} from "@opsdti-global-component-library/amgen-design-system";
import { MutationStatus } from "@tanstack/react-query";
import { Label } from "common-components";
export type { TableColumnsType } from "@opsdti-global-component-library/amgen-design-system/node_modules/antd";

const namespace = "rts-pa-table";

type Props<T> = Omit<TableProps<T>, "loading"> & {
  status?: MutationStatus;
};

export const Table = <T extends Record<string, unknown>>(props: Props<T>) => {
  for (const column of props.columns as (ColumnsType<T> & {
    title?: React.ReactNode;
  })[]) {
    column.title = <Label colorVariant="secondary">{column.title}</Label>;
  }
  return (
    <div className={namespace}>
      <TableCompLib
        headerType="secondary"
        pagination={{ position: ["bottomLeft"] }}
        columns={props.columns}
        {...props}
        loading={props.status === "loading"}
      />
    </div>
  );
};

export default Table;
