import { Literal, Static, Union } from "runtypes";
import { AgendaItemTemplateSectionValidator } from "../agenda-item-template-common";

export const AgendaItemTemplateSectionSingleMetricSubtypeValidator = Union(
  Literal("ytdVarVsFcst")
);

export type AgendaItemTemplateSectionSingleMetricSubtype = Static<
  typeof AgendaItemTemplateSectionSingleMetricSubtypeValidator
>;

export const AgendaItemTemplateSectionSingleMetricScopeValidator = Union(
  Literal("global")
);

export type AgendaItemTemplateSectionSingleMetricScope = Static<
  typeof AgendaItemTemplateSectionSingleMetricScopeValidator
>;

export const AgendaItemTemplateSectionSingleMetricValidator =
  AgendaItemTemplateSectionValidator.extend({
    type: Literal("singleMetric"),
    subtype: AgendaItemTemplateSectionSingleMetricSubtypeValidator,
    scope: AgendaItemTemplateSectionSingleMetricScopeValidator
  });

export type AgendaItemTemplateSectionSingleMetric = Static<
  typeof AgendaItemTemplateSectionSingleMetricValidator
>;
