import React from "react";
import "./TalentDinbModalLaborProductivityMetrics.scss";
import ModalSection from "common-components/modal-section/ModalSection";
import TalentDinbModalLaborProductivityRevenuePerLnb from "./revenue-per-lnb/TalentDinbModalLaborProductivityRevenuePerLnb";
import { TalentDinbLaborProductivitySection } from "api/talent-dinb/talent-dinb-labor-productivity";

const namespace = "rts-pa-talent-dinb-modal-labor-productivity-metrics";

type Props = {
  data: TalentDinbLaborProductivitySection | undefined | null;
};

const TalentDinbModalLaborProductivityMetrics = (props: Props) => {
  if (!props.data) {
    return <></>;
  }
  return (
    <ModalSection title="Labor Productivity Metrics" className={namespace}>
      <div className={`${namespace}-container`}>
        <TalentDinbModalLaborProductivityRevenuePerLnb
          data={props.data.revenuePerLnb}
        />
      </div>
    </ModalSection>
  );
};

export default TalentDinbModalLaborProductivityMetrics;
