import React from 'react';

export type PermissionsCollection = boolean | (boolean | boolean[])[];
type RootValidationMode = 'OR' | 'AND';

type props = {
  permissions: PermissionsCollection; //permissions treated as "OR" unless wrapped in an array, then all permissions in array are "AND"; can invert using rootValidationMode
  rootValidationMode?: RootValidationMode; //defaults to "OR"
  children: JSX.Element | JSX.Element[];
};

export const hasPermissions = (
  permissions: PermissionsCollection,
  rootValidationMode?: RootValidationMode,
) => {
  if (permissions === true) {
    return true;
  } else if (permissions === false) {
    return false;
  }

  let hasPerms: boolean;

  if (rootValidationMode === 'AND') {
    hasPerms = true;
  } else {
    hasPerms = false;
  }

  for (const permission of permissions) {
    if (rootValidationMode === 'AND') {
      if (Array.isArray(permission)) {
        //array items are OR, but if whole array is false, then don't show
        if (permission.every(perm => perm === false)) {
          hasPerms = false;
          break;
        }
      } else if (permission === false) {
        hasPerms = false;
        break;
      }
    } else {
      if (Array.isArray(permission)) {
        //array items are AND, but if whole array is true, then show
        if (permission.every(perm => perm === true)) {
          hasPerms = true;
          break;
        }
      } else if (permission === true) {
        hasPerms = true;
        break;
      }
    }
  }

  return hasPerms;
};

export const PermissionsWrapper = (props: props) => {
  const hasPerms = hasPermissions(props.permissions, props.rootValidationMode);

  if (hasPerms) {
    return <>{props.children}</>;
  } else {
    return <></>;
  }
};

export default PermissionsWrapper;
