import React from "react";
import "./AIBuilderModalSectionTalentAcquisition.scss";
//import { AgendaItemTemplateSectionPlaceholder } from "api/agenda-item-template/sections";
import { SectionBasicProps } from "../../AgendaItemBuilderModalSection";

//const namespace = "rts-pa-agenda-item-builder-modal-section-talent-acquisition";

type Props = SectionBasicProps;

export const AIBuilderModalSectionTalentAcquisition = (props: Props) => {
  return <></>;

  // return (
  //   <div className={namespace}>
  //   </div>
  // );
};

export default AIBuilderModalSectionTalentAcquisition;
