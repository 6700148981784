import {
  CircleIcon,
  ThreeQuarterCircleIcon,
  TriangleIcon,
  SquareIcon,
  DiamondIcon,
  PentagonIcon,
  OctagonIcon
} from "icons/key-events-icons";
import React, { useMemo } from "react";
import "./MilestoneTypeIcon.scss";
import { Text } from "@opsdti-global-component-library/amgen-design-system";
import { KeyEventsIconProps } from "icons/key-events-icons/common-props";
import { getMilestoneCalendarKeys } from "api";
import { useQuery } from "@tanstack/react-query";

const namespace = "rts-pa-milestone-type-icon";

type Props = {
  id: number;
  showLabel?: boolean;
  className?: string;
  size?: "large" | "small";
};

export const MilestoneTypeIcon = (props: Props) => {
  const { data } = useQuery(["calendar-keys"], getMilestoneCalendarKeys);

  const milestoneCalendarKey = useMemo(() => {
    return data?.keys.find(mck => mck.id === props.id);
  }, [props.id, data]);

  if (!milestoneCalendarKey) {
    return null;
  }

  let Icon: (props: KeyEventsIconProps) => React.JSX.Element;

  switch (milestoneCalendarKey.icon) {
    case "circle":
      Icon = CircleIcon;
      break;
    case "three_quarter_circle":
      Icon = ThreeQuarterCircleIcon;
      break;
    case "triangle":
      Icon = TriangleIcon;
      break;
    case "square":
      Icon = SquareIcon;
      break;
    case "diamond":
      Icon = DiamondIcon;
      break;
    case "pentagon":
      Icon = PentagonIcon;
      break;
    case "octagon":
      Icon = OctagonIcon;
      break;
  }

  return (
    <span className={namespace}>
      <Icon
        width={props.size === "large" ? 14 : 8}
        height={props.size === "large" ? 14 : 8}
        className={props.className}
        fill={milestoneCalendarKey.color}
      />
      {props.showLabel && (
        <Text className="rts-pa-key-events-page-icon-label">
          {milestoneCalendarKey.label}
        </Text>
      )}
    </span>
  );
};

export default MilestoneTypeIcon;
