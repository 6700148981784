import { useState } from "react";
import produce from "immer";
import { MutationStatus } from "@tanstack/query-core";

type statusOverrides = Map<string, MutationStatus>;

export function useStatusManager(...statuses: MutationStatus[]) {
  const [statusOverrides, setStatusOverrides] = useState(
    new Map<string, MutationStatus>()
  );

  const overrideStatus = (
    source: string,
    status: MutationStatus | undefined
  ) => {
    let newStatusOverride: statusOverrides;

    if (status) {
      newStatusOverride = produce(statusOverrides, draftState => {
        draftState.set(source, status);
      });
    } else {
      newStatusOverride = produce(statusOverrides, draftState => {
        draftState.delete(source);
      });
    }

    setStatusOverrides(newStatusOverride);
  };

  let worstOverrideStatus: MutationStatus = "success";
  statusOverrides.forEach(val => {
    if (worstOverrideStatus === "error") {
      return;
    } else if (worstOverrideStatus === "loading") {
      if (val === "error") {
        worstOverrideStatus = "error";
      }
    } else if (worstOverrideStatus === "success") {
      worstOverrideStatus = val;
    }
  });

  let status: MutationStatus;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (worstOverrideStatus === "error" || statuses.includes("error")) {
    status = "error";
  } else if (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    worstOverrideStatus === "loading" ||
    statuses.includes("loading")
  ) {
    status = "loading";
  } else {
    status = "success";
  }

  return { status, overrideStatus };
}
