import { BiosimilarsPipelineGroupValidator } from "./biosimilars-item/biosimilars-pipeline-group";
import {
  ArchetypeValidator,
  LinkValidator,
  OwnersValidator,
  SummaryInfoValidator
} from "./common";
import { Array, Number, Record, Static, String } from "runtypes";

export const BiosimilarsItemValidator = Record({
  id: Number,
  agendaItemVersionId: Number,
  archetype: ArchetypeValidator,
  triggers: Array(String),
  summaryInfo: SummaryInfoValidator,
  owners: OwnersValidator,
  pipeline: BiosimilarsPipelineGroupValidator,
  links: Array(LinkValidator)
});

export type BiosimilarsItem = Static<typeof BiosimilarsItemValidator>;
