import React from "react";
import "./AIBuilderModalSectionTurnover.scss";
//import { AgendaItemTemplateSectionPlaceholder } from "api/agenda-item-template/sections";
import { SectionBasicProps } from "../../AgendaItemBuilderModalSection";

//const namespace = "rts-pa-agenda-item-builder-modal-section-turnover";

type Props = SectionBasicProps;

export const AIBuilderModalSectionTurnover = (props: Props) => {
  return <></>;

  // return (
  //   <div className={namespace}>
  //   </div>
  // );
};

export default AIBuilderModalSectionTurnover;
