import axios from "axios";
import { Agenda, AgendaValidator } from "./agenda/";
import { getAgendaId } from "utils/multitenancy";

export const getAgenda = async (): Promise<Agenda> => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  const result = await axios.get<Agenda>(
    `${REACT_APP_DOMAIN}/api/v1/agenda/${getAgendaId()}`
  );

  AgendaValidator.check(result.data);

  return result.data;
};

export const postAgenda = async (agenda: Agenda): Promise<Agenda> => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  const result = await axios.post<Agenda>(
    `${REACT_APP_DOMAIN}/api/v1/agenda`,
    agenda
  );

  AgendaValidator.check(result.data);

  return result.data;
};

export const putAgenda = async (agenda: Agenda): Promise<Agenda> => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  const result = await axios.put<Agenda>(
    `${REACT_APP_DOMAIN}/api/v1/agenda/${getAgendaId()}`,
    agenda
  );

  AgendaValidator.check(result.data);

  return result.data;
};

export const deleteAgenda = async (): Promise<Agenda> => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  const result = await axios.delete<Agenda>(
    `${REACT_APP_DOMAIN}/api/v1/agenda/${getAgendaId()}`
  );

  AgendaValidator.check(result.data);

  return result.data;
};
