import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";

import PaModal from "common-components/pa-modal/PaModal";
import { PermissionsContext } from "global/permissions";
import { useModalHelper } from "global/use-modal-helper";
import pages from "pages/pages";
import { Input } from "common-components";
import { useValidator } from "global/use-validator";
import {
  getGlobalConfig,
  GlobalConfig,
  putGlobalConfig
} from "api/globalConfig";
import { Button } from "@opsdti-global-component-library/amgen-design-system";
import { useStatusManager } from "global/use-status-manager";

import "./GlobalConfigModal.scss";

const namespace = "rts-pa-global-config-modal";

const initialItem: GlobalConfig = {
  id: 0,
  ceoStaffUrl: "",
  functionalPaUrl: "",
  operatingTeamUrl: ""
};

export const GlobalConfigModal = () => {
  const hasPermissions =
    useContext(PermissionsContext).leftNavLinks.globalConfig;
  const mhResult = useModalHelper(
    pages.grid.globalConfigModal.path,
    hasPermissions
  );

  const navigate = useNavigate();
  const location = useLocation();

  const [globalConfig, setGlobalConfig] = useState<GlobalConfig>(initialItem);

  const { containerUtils, fieldUtils } = useValidator();

  const {
    data,
    status: getStatus,
    error,
    refetch: refreshGlobalConfig
  } = useQuery<GlobalConfig>(["global-config"], getGlobalConfig, {
    enabled: mhResult.enabled
  });

  useEffect(() => {
    if (mhResult.enabled && data) {
      containerUtils.resetValidation();
      setGlobalConfig(data);
    }
  }, [mhResult.enabled, data]); // eslint-disable-line react-hooks/exhaustive-deps

  const { status: updateStatus, mutate: updateGlobalConfig } = useMutation(
    putGlobalConfig,
    {
      onMutate: () => {
        overrideStatus("updateGlobalConfig", "loading");
      },
      onSuccess: () => {
        refreshGlobalConfig().then(() => {
          onClose();
          setTimeout(() => {
            //keep spinner going when closing modal
            overrideStatus("updateGlobalConfig", undefined);
          }, 500);
        });
      },
      onError: () => {
        overrideStatus("updateGlobalConfig", undefined);
      }
    }
  );

  const { status, overrideStatus } = useStatusManager(getStatus, updateStatus);

  const onSave = () => {
    containerUtils.triggerValidation().then(({ isValid }) => {
      if (!isValid) {
        return;
      }

      updateGlobalConfig(globalConfig);
    });
  };

  const onClose = () => {
    const prevUrl = location.state?.prevUrl;
    if (prevUrl) {
      navigate(prevUrl);
    } else {
      navigate(pages.grid.go());
    }
  };

  return (
    <PaModal
      title="Global Config"
      isOpen={mhResult.enabled}
      onClose={onClose}
      status={status}
      axiosErrors={error}
      className={namespace}
      footer={
        <Button
          text="Save"
          type="primary"
          onClick={onSave}
          disabled={status !== "success"}
        />
      }
    >
      <div className={`${namespace}-content`}>
        <Input
          id="ceo-staff-url"
          label="CEO Staff Url"
          placeholder="CEO Staff Url"
          required
          autoUpdater={{
            item: globalConfig,
            setItem: setGlobalConfig,
            propExpression: x => x.ceoStaffUrl
          }}
          validator={fieldUtils}
        />
        <Input
          id="functional-pa-url"
          label="Functional PA Url"
          placeholder="Functional PA Url"
          required
          autoUpdater={{
            item: globalConfig,
            setItem: setGlobalConfig,
            propExpression: x => x.functionalPaUrl
          }}
          validator={fieldUtils}
        />
        <Input
          id="operating-team-url"
          label="Operating Team Url"
          placeholder="Operating Team Url"
          required
          autoUpdater={{
            item: globalConfig,
            setItem: setGlobalConfig,
            propExpression: x => x.operatingTeamUrl
          }}
          validator={fieldUtils}
        />
      </div>
    </PaModal>
  );
};
