import React from "react";
import "./ChangeLogTableFilters.scss";
import produce from "immer";
import dayjs from "dayjs";
import {
  Label,
  SelectSimple,
  Option,
  DateRangePickerSimple
} from "common-components";

const namespace = "rts-pa-change-log-table-filters";

const filterItems: Option[] = [
  {
    label: "Repatha",
    value: "1"
  },
  {
    label: "Otezla",
    value: "2"
  }
];

const filterFieldNames: Option[] = [
  {
    label: "CEO Staff Sponsor",
    value: "ceo_staff_sponsor"
  },
  {
    label: "Item Added",
    value: "added"
  },
  {
    label: "Item Name",
    value: "name"
  },
  {
    label: "Item Order",
    value: "order"
  },
  {
    label: "Item Removed",
    value: "removed"
  },
  {
    label: "Operating Team Owner",
    value: "operating_team_owner"
  },
  {
    label: "Position (Focus)",
    value: "focus"
  },
  {
    label: "Position (Value)",
    value: "value"
  }
];

export type Filters = {
  dateRange: dayjs.Dayjs[];
  agendaItemIds: string[];
  fieldNames: string[];
};

type Props = {
  filters: Filters;
  onChange: (filters: Filters) => void;
};

export const ChangeLogTableFilters = (props: Props) => {
  const onItemNamesChange = (value: string[]) => {
    const newFilters = produce(props.filters, draftState => {
      draftState.agendaItemIds = value;
    });

    props.onChange(newFilters);
  };

  const onFieldNamesChange = (value: string[]) => {
    const newFilters = produce(props.filters, draftState => {
      draftState.fieldNames = value;
    });

    props.onChange(newFilters);
  };

  const onDatePickerChange = (dateRange: dayjs.Dayjs[]) => {
    const newFilters = produce(props.filters, draftState => {
      draftState.dateRange = dateRange[0] && dateRange[1] ? dateRange : [];
    });

    props.onChange(newFilters);
  };

  return (
    <div className={namespace}>
      <Label className={`${namespace}-title`}>FILTER BY:</Label>
      <DateRangePickerSimple
        allowEmpty={[true, true]}
        className={`${namespace}-date-picker`}
        label="Date Range"
        format="MMM, DD YYYY"
        placeholder={["Start date", "End date"]}
        onChange={onDatePickerChange}
      />
      <SelectSimple
        label="Item Name"
        options={filterItems}
        onChange={onItemNamesChange}
        mode="multiple"
        placeholder="Select agenda items"
      />
      <SelectSimple
        label="Changes"
        options={filterFieldNames}
        onChange={onFieldNamesChange}
        mode="multiple"
        placeholder="Select fields changed"
      />
    </div>
  );
};

export default ChangeLogTableFilters;
