import React from "react";
import "./AIModalSectionInventoryBelowTargetLineItem.scss";
import classNames from "classnames";

const namespace =
  "rts-pa-agenda-item-modal-section-inventory-below-target-line-item";

type Props = {
  label: string;
  text: React.ReactNode;
  bolder?: boolean;
};
export const AIModalSectionInventoryBelowTargetLineItem = (props: Props) => {
  const labelClassName = classNames(`${namespace}-label`, {
    bolder: props.bolder
  });

  return (
    <div className={`${namespace}`}>
      <span className={labelClassName}>{props.label}</span>
      <span className={`${namespace}-text`}>{props.text}</span>
    </div>
  );
};

export default AIModalSectionInventoryBelowTargetLineItem;
