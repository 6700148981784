import React from "react";
import "./AIBuilderModalSectionLocation.scss";
import { Select, GenericOption } from "common-components";
import { AgendaItemTemplateSectionLocation } from "api/agenda-item-template/sections";
import { SectionBasicProps } from "../../AgendaItemBuilderModalSection";

const namespace = "rts-pa-agenda-item-builder-modal-section-location";

type Props = SectionBasicProps;

const tempLocations: GenericOption<string>[] = [
  {
    label: "C01565: AOH - B1 Packaging Facility",
    value: "C01565"
  },
  {
    label: "C01566: ANC FleX Batch DS Manufacturing Facility",
    value: "C01566"
  }
];

export const AIBuilderModalSectionLocation = (props: Props) => {
  return (
    <div className={namespace}>
      <Select
        label="Location"
        autoUpdater={{
          item: props.agendaItemTemplate,
          setItem: props.setAgendaItemTemplate,
          propExpression: x =>
            (
              x.groups[props.groupIndex].sections[
                props.index
              ] as AgendaItemTemplateSectionLocation
            ).locationId
        }}
        required
        validator={props.fieldUtils}
        options={tempLocations}
        placeholder="Select Location"
      />
    </div>
  );
};

export default AIBuilderModalSectionLocation;
