import { AgendaItemsGroup } from "api";
import update from "immutability-helper";

export const updateGroupOrder = (
  groups: AgendaItemsGroup[],
  groupIndex: number
) => {
  let newGroups = update(groups, {});

  for (let i = 0; i < groups[groupIndex].items.length; i++) {
    newGroups = update(newGroups, {
      [groupIndex]: {
        items: {
          [i]: {
            order: {
              $set: i + 1
            }
          }
        }
      }
    });
  }

  return newGroups;
};
