import React, { useEffect } from "react";
import "./AgendaItemBuilderModalBasicInfo.scss";
import { AgendaItemTemplate } from "api";
import { Archetype, StatusType } from "api/common";
import {
  GenericOption,
  Input,
  Select,
  StatusSelector
} from "common-components";
import { AutoUpdaterSetItem } from "global/use-auto-updater";
import { FieldValUtils } from "global/use-validator";
import produce from "immer";

const namespace = "rts-pa-agenda-item-builder-modal-basic-info";

type Props = {
  agendaItemTemplate: AgendaItemTemplate;
  setAgendaItemTemplate: AutoUpdaterSetItem<AgendaItemTemplate>;
  fieldUtils: FieldValUtils;
};

const archetypeOptions: GenericOption<Archetype>[] = [
  { label: "Commercial", value: "commercial" },
  { label: "Standalone", value: "standalone" }
];

const statusTypeOptions: GenericOption<StatusType>[] = [
  { label: "Automatic", value: "automatic" },
  { label: "Manual", value: "manual" }
];

const tempProductOptions: GenericOption<string>[] = [
  { label: "Otezla | AMG ###", value: "otezla" },
  { label: "Repatha | AMG 145", value: "repatha" }
];

export const AgendaItemBuilderModalBasicInfo = (props: Props) => {
  useEffect(() => {
    if (props.agendaItemTemplate.statusType === "automatic") {
      const newTemplate = produce(props.agendaItemTemplate, draftState => {
        draftState.status = null;
      });
      props.setAgendaItemTemplate(newTemplate);
    } else if (props.agendaItemTemplate.statusType === "manual") {
      const newTemplate = produce(props.agendaItemTemplate, draftState => {
        draftState.status = "gray";
      });
      props.setAgendaItemTemplate(newTemplate);
    }
  }, [props.agendaItemTemplate.statusType]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={namespace}>
      <Input
        label="Display Name"
        autoUpdater={{
          item: props.agendaItemTemplate,
          setItem: props.setAgendaItemTemplate,
          propExpression: x => x.name
        }}
        required
        validator={props.fieldUtils}
      />
      <Select
        label="Archetype"
        autoUpdater={{
          item: props.agendaItemTemplate,
          setItem: props.setAgendaItemTemplate,
          propExpression: x => x.archetype
        }}
        required
        validator={props.fieldUtils}
        options={archetypeOptions}
        placeholder="Select Archetype"
      />
      {props.agendaItemTemplate.archetype === "commercial" && (
        <Select
          label="Product"
          autoUpdater={{
            item: props.agendaItemTemplate,
            setItem: props.setAgendaItemTemplate,
            propExpression: x => x.linkId
          }}
          required
          validator={props.fieldUtils}
          options={tempProductOptions}
          placeholder="Select Product"
        />
      )}
      <Select
        label="Status Mode"
        autoUpdater={{
          item: props.agendaItemTemplate,
          setItem: props.setAgendaItemTemplate,
          propExpression: x => x.statusType
        }}
        required
        validator={props.fieldUtils}
        options={statusTypeOptions}
        placeholder="Select Mode"
      />
      {props.agendaItemTemplate.statusType === "manual" && (
        <StatusSelector
          required
          autoUpdater={{
            item: props.agendaItemTemplate,
            setItem: props.setAgendaItemTemplate,
            propExpression: x => x.status
          }}
        />
      )}
    </div>
  );
};

export default AgendaItemBuilderModalBasicInfo;
