import React from "react";
import "./PipelineEnrollmentStatusWithTooltip.scss";
import { AdvancedTooltip, PipelineEnrollmentStatus } from "api/common";
import StatusIcon from "common-components/status-icon/StatusIcon";
import Tooltip from "common-components/tooltip/Tooltip";
import { getAdvancedTooltipContent, Text } from "common-components";
const namespace = "rts-pa-pipeline-enrollment-status-with-tooltip";

type Props = {
  status: PipelineEnrollmentStatus;
  tooltip: AdvancedTooltip | null;
};

const PipelineEnrollmentStatusWithTooltip = (props: Props) => {
  const { tooltip, status } = props;
  let statusText = "";

  switch (status) {
    case "green":
    case "yellow":
    case "red":
    case "gray":
      statusText = "Enrollment";
      break;
    case "enrollment complete":
      statusText = "Enrollment Complete";
      break;
    case "study fully enrolled":
      statusText = "Fully Enrolled";
      break;
    case "pending subject enrollment":
      statusText = "Enrollment Not Started";
      break;
  }

  if (!tooltip) {
    return (
      <div className={namespace}>
        <Text>{statusText}</Text>
      </div>
    );
  }

  let statusIcon: JSX.Element | null = null;

  switch (status) {
    case "green":
    case "yellow":
    case "red":
    case "gray":
      statusIcon = <StatusIcon status={status} />;
      break;
    case "enrollment complete":
      statusIcon = <StatusIcon status="green" />;
      break;
    case "study fully enrolled":
    case "pending subject enrollment":
      statusIcon = <Text>{statusText}</Text>;
      statusText = "";
      break;
  }

  return (
    <div className={namespace}>
      <Text>{statusText}</Text>
      <Tooltip tooltipIcon={statusIcon}>
        {getAdvancedTooltipContent(props.tooltip)}
      </Tooltip>
    </div>
  );
};

export default PipelineEnrollmentStatusWithTooltip;
