import { useQuery } from "@tanstack/react-query";
import {
  getCheckoutStatus,
  checkout as checkoutApi,
  checkin as checkinApi
} from "api/conflict-management";
import { useState } from "react";

export const useConflictManagement = () => {
  const [isCheckOutInLoadingV2, setIsCheckOutInLoadingV2] = useState(false);
  const { refetch: refreshCheckoutStatusV2 } = useQuery(
    ["checkout-status"],
    getCheckoutStatus,
    {
      enabled: false
    }
  );

  const { refetch: checkoutV2 } = useQuery(["checkout"], checkoutApi, {
    enabled: false,
    retry: false
  });

  const { refetch: checkinV2 } = useQuery(["checkin"], checkinApi, {
    enabled: false,
    retry: false
  });

  return {
    isCheckOutInLoading: isCheckOutInLoadingV2,
    setIsCheckOutInLoading: setIsCheckOutInLoadingV2,
    refreshCheckoutStatus: refreshCheckoutStatusV2,
    checkout: checkoutV2,
    checkin: checkinV2
  };
};
