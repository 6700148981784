import axios from "axios";
import {
  ActivityLogData,
  ActivityLogDataValidator,
  ActivityLogDropdownValuesData,
  ActivityLogDropdownValuesDataValidator
} from "./notifications/activity-log";
import { Record, String, Array, Static } from "runtypes";
import { getAgendaId } from "utils/multitenancy";

const FilterListItemsValidator = Record({
  filterKey: String,
  filterValue: String
});

export type FilterListItems = Static<typeof FilterListItemsValidator>;

const ActivityLogDataPayloadValidator = Record({
  workstream: String,
  filterList: Array(FilterListItemsValidator)
});

export type ActivityLogDataPayload = Static<
  typeof ActivityLogDataPayloadValidator
>;

export const ActivityLogDropdownValuesPayloadValidator = Record({
  workstream: String
});

export type ActivityLogDropdownValuesPayload = Static<
  typeof ActivityLogDropdownValuesPayloadValidator
>;

export const getActivityLogData = async (
  payload: ActivityLogDataPayload
): Promise<ActivityLogData> => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  const result = await axios.post<ActivityLogData>(
    `${REACT_APP_DOMAIN}/api/v2/${getAgendaId()}/activity_log`,
    payload
  );

  ActivityLogDataValidator.check(result.data);

  return result.data;
};

export const getActivityLogDropdownValuesData = async (
  payload: ActivityLogDropdownValuesPayload
): Promise<ActivityLogDropdownValuesData> => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  const result = await axios.post<ActivityLogDropdownValuesData>(
    `${REACT_APP_DOMAIN}/api/v2/${getAgendaId()}/activity_log/dropdown_values`,
    payload
  );

  ActivityLogDropdownValuesDataValidator.check(result.data);

  return result.data;
};
