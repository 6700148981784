import axios from "axios";
import {
  Authorization,
  AuthorizationValidator
} from "./authorization/permissions";

export const getAuthorization = async (): Promise<Authorization> => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  const result = await axios.get<Authorization>(
    `${REACT_APP_DOMAIN}/api/v1/authorization`
  );

  const resultData = result.data;

  AuthorizationValidator.check(resultData);

  return resultData;
};
