import { Button } from "@opsdti-global-component-library/amgen-design-system";
import React, { useState } from "react";
import "./ExportModalOptions.scss";
import { ExportIcon } from "icons/export-icon";
import { Text, RadioOption } from "common-components";

export type ExportMode = "export without status" | "export status";

type ExportModalOption = {
  label: string;
  value: ExportMode;
  description: string;
};

type Props = {
  onExportClick: (option: ExportMode) => void;
};

const options: ExportModalOption[] = [
  {
    label: "Export with status",
    value: "export status",
    description:
      "Status indicators will be shown for each item in the current agenda PDF export."
  },
  {
    label: "Export without status",
    value: "export without status",
    description:
      "No status indicators will be shown in the current agenda PDF export."
  }
];

const namespace = "rts-pa-export-modal-options";

export const ExportModalOptions = (props: Props) => {
  const [exportMode, setExportMode] = useState<ExportMode>("export status");

  return (
    <div className={namespace}>
      <div className={`${namespace}-container`}>
        {options.map((o, i) => (
          <div key={i} className={`${namespace}-option`}>
            <RadioOption
              key={i}
              label={o.label}
              value={o.value}
              onClick={(option: ExportMode) => setExportMode(option)}
              isSelected={o.value === exportMode}
            />
            <Text className={`${namespace}-description`}>{o.description}</Text>
          </div>
        ))}
      </div>
      <div className={`${namespace}-footer`}>
        <Button
          text="Export"
          type="primary"
          className={`${namespace}-export-button`}
          icon={<ExportIcon />}
          onClick={() => props.onExportClick(exportMode)}
        />
      </div>
    </div>
  );
};
