import StatusIcon from "common-components/status-icon/StatusIcon";
import React from "react";
import "./AIModalSectionLocationMetric.scss";
import { Status } from "api/common";

const namespace = "rts-pa-agenda-item-modal-section-location-metric";

type Props = {
  title: string;
  data: {
    status: Status;
    text: string | null;
  };
};

const AIModalSectionLocationMetric = (props: Props) => {
  if (!props.data) {
    return <></>;
  }

  return (
    <div className={namespace}>
      <span className={`${namespace}-label`}>{props.title}</span>
      <div className={`${namespace}-value`}>
        <div>
          <StatusIcon status={props.data.status} />
        </div>
        <span>{props.data.text ?? "N/A"}</span>
      </div>
    </div>
  );
};

export default AIModalSectionLocationMetric;
