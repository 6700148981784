import React, {
  FC,
  PropsWithChildren,
  createContext,
  useState,
  useEffect
} from "react";
import { useQuery } from "@tanstack/react-query";
import { Authorization, EntityListItem, getAuthorization } from "api";
import { useNavManager } from "global/use-nav-manager";
import pages from "pages/pages";
import { useNavigate } from "react-router-dom";
import { getAgendaId, getKeyEventsId } from "utils/multitenancy";
import { hasMinimumPermissionRole } from "utils/permissions";

export type Permissions = {
  isLoaded: boolean;
  homeTile: {
    any: boolean;
    pdfs: boolean;
    pageLink: boolean;
  };
  grid: {
    any: boolean;
    edit: boolean;
    status: boolean;
    view: boolean;
    create: boolean;
    standaloneModal: boolean;
    commercialModal: boolean;
    pipelineModal: boolean;
    talentDinbModal: boolean;
    horizonModal: boolean;
    esgModal: boolean;
    biosimilarsModal: boolean;
    export: boolean;
    activityLog: boolean;
    agendaList: EntityListItem[];
  };
  leftNavLinks: {
    any: boolean;
    agendaConfig: boolean;
    globalConfig: boolean;
    updateHomepageImage: boolean;
    ceoStaff: boolean;
    functionalPas: boolean;
    operatingTeam: boolean;
  };
  submissions: boolean;
  changeLog: boolean;
  keyEvents: {
    any: boolean;
    view: boolean;
    edit: boolean;
    create: boolean;
    calendarList: EntityListItem[];
  };
};

const initialPermissions: Permissions = {
  isLoaded: false,
  homeTile: {
    any: false,
    pdfs: false,
    pageLink: false
  },
  grid: {
    any: false,
    edit: false,
    status: false,
    view: false,
    create: false,
    standaloneModal: false,
    commercialModal: false,
    pipelineModal: false,
    talentDinbModal: false,
    horizonModal: false,
    esgModal: false,
    biosimilarsModal: false,
    export: false,
    activityLog: false,
    agendaList: []
  },
  leftNavLinks: {
    any: false,
    agendaConfig: false,
    globalConfig: false,
    updateHomepageImage: false,
    ceoStaff: false,
    functionalPas: false,
    operatingTeam: false
  },
  submissions: false,
  changeLog: false,
  keyEvents: {
    any: false,
    view: false,
    edit: false,
    create: false,
    calendarList: []
  }
};

export const PermissionsContext = createContext(initialPermissions);

export const PermissionsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [permissions, setPermissions] = useState(initialPermissions);
  useNavManager(permissions);
  const navigate = useNavigate();

  const { data: permsResponse, refetch } = useQuery<Authorization>(
    ["authorization"],
    getAuthorization
  );

  const agendaId = parseInt(getAgendaId()) || 0;
  const keyEventsId = parseInt(getKeyEventsId()) || 0;

  useEffect(() => {
    if (!permsResponse) {
      return;
    }

    let perms: Permissions = initialPermissions;

    const { pa, ke, domain, ws } = permsResponse;

    const agendaPermissions = {
      admin: hasMinimumPermissionRole(pa.agendaList, agendaId, "admin"),
      fullViewer: hasMinimumPermissionRole(
        pa.agendaList,
        agendaId,
        "full_viewer"
      ),
      limitedViewer: hasMinimumPermissionRole(
        pa.agendaList,
        agendaId,
        "limited_viewer"
      )
    };

    const calendarPermissions = {
      admin: hasMinimumPermissionRole(ke.calendarList, keyEventsId, "admin"),
      fullViewer: hasMinimumPermissionRole(
        ke.calendarList,
        keyEventsId,
        "full_viewer"
      ),
      limitedViewer: hasMinimumPermissionRole(
        ke.calendarList,
        keyEventsId,
        "limited_viewer"
      )
    };

    perms = {
      ...initialPermissions,
      isLoaded: true,
      homeTile: {
        any: false,
        pdfs: domain.homePdf,
        pageLink: ws.pa
      },
      grid: {
        any: false,
        edit: agendaPermissions.admin,
        status: agendaPermissions.fullViewer,
        view: agendaPermissions.limitedViewer,
        create: pa.createAgenda,
        standaloneModal: agendaPermissions.fullViewer,
        commercialModal: agendaPermissions.fullViewer,
        pipelineModal: agendaPermissions.fullViewer,
        talentDinbModal: agendaPermissions.fullViewer,
        horizonModal: agendaPermissions.fullViewer,
        esgModal: agendaPermissions.fullViewer,
        biosimilarsModal: agendaPermissions.fullViewer,
        export: agendaPermissions.limitedViewer,
        activityLog: pa.activityLog,
        agendaList: pa.agendaList
      },
      leftNavLinks: {
        any: false,
        agendaConfig: agendaPermissions.admin,
        globalConfig: pa.superAdmin,
        updateHomepageImage: pa.superAdmin,
        ceoStaff: domain.ceoStaffNav,
        functionalPas: domain.funcNav,
        operatingTeam: agendaPermissions.fullViewer
      },
      submissions: agendaPermissions.admin && getAgendaId() === "1",
      changeLog: false, //agendaPermissions.admin,
      keyEvents: {
        any: false,
        view: calendarPermissions.limitedViewer,
        edit: calendarPermissions.admin,
        create: ke.createCalendar,
        calendarList: ke.calendarList
      }
    };

    // iterates through perms and children perms setting `.any` prop based on sibling property `true` values
    const setAny = (obj: { [key: string]: object | boolean }): void => {
      let key: string;
      let hasAnyProp = false;
      let hasAny = false;

      const nonBoolProps: string[] = [];

      for (key in obj) {
        if (key === "any") {
          hasAnyProp = true;
          continue;
        }

        if (typeof obj[key] === "object") {
          nonBoolProps.push(key);
        } else if (obj[key] === true) {
          hasAny = true;
        }
      }

      if (hasAnyProp) {
        obj["any"] = hasAny; // eslint-disable-line no-param-reassign
      }

      for (const childObjKey of nonBoolProps) {
        const newObj = obj[childObjKey] as { [key: string]: object | boolean };
        setAny(newObj);
      }
    };

    setAny(perms);

    // reroute if needed -- usually on initial page load, other reroute in TenantSelector happens on list item removal
    const tryRoutingToGrid = () => {
      if (perms.grid.view) {
        setPermissions(perms);
        return true;
      }

      const agendaId = pa.agendaList[0]?.id.toString();

      if (agendaId) {
        navigate(pages.grid.go(agendaId));
        setTimeout(() => {
          refetch();
        }, 100);
        return true;
      }

      return false;
    };

    const tryRoutingToKeyEvents = () => {
      if (perms.keyEvents.view) {
        setPermissions(perms);
        return true;
      }

      const calendarId = ke.calendarList[0]?.id.toString();

      if (calendarId) {
        navigate(pages.keyEvents.go(calendarId));
        setTimeout(() => {
          refetch();
        }, 100);

        return true;
      }

      return false;
    };

    if (
      (agendaId && !tryRoutingToGrid() && !tryRoutingToKeyEvents()) ||
      (keyEventsId && !tryRoutingToKeyEvents() && !tryRoutingToGrid())
    ) {
      navigate(pages.unauthorized.go());
    } else if (!agendaId && !keyEventsId) {
      //load "no context" perms, used in tiles
      setPermissions(perms);
    }
  }, [permsResponse, agendaId, keyEventsId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <PermissionsContext.Provider value={permissions}>
      {children}
    </PermissionsContext.Provider>
  );
};
