// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[2]!../../../../node_modules/resolve-url-loader/index.js??ruleSet[1].rules[1].oneOf[7].use[3]!../../../../node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[4]!../../../../node_modules/simplebar-react/dist/simplebar.min.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "[data-simplebar].rts-pa-fix-vertical-scrollbar-overlap{max-width:calc(100% + 10px);width:calc(100% + 10px);padding-right:10px;padding-bottom:1px}[data-simplebar].rts-pa-fix-horizontal-scrollbar-overlap{max-height:calc(100% + 10px);height:calc(100% + 10px);padding-bottom:10px}.simplebar-content-wrapper:focus{outline:none}[class*=loader-module]{display:flex;align-items:center;justify-content:center}.rts-pa-upsert-calendar-row-modal-milestone-milestone-accordion{background-color:#f5f5f5;padding:20px;border-radius:0;border-bottom:1px solid #e6e6e6}", "",{"version":3,"sources":["webpack://./src/styles/simplebar.scss","webpack://./src/styles/loader.scss","webpack://./src/components/upsert-calendar-row-modal/milestone/UpsertCalendarRowModalMilestone.scss"],"names":[],"mappings":"AAEA,uDACE,2BAAA,CACA,uBAAA,CACA,kBAAA,CACA,kBAAA,CAGF,yDACE,4BAAA,CACA,wBAAA,CACA,mBAAA,CAGF,iCACE,YAAA,CChBF,uBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CAAA,gECCE,wBAAA,CACA,YAAA,CACA,eAAA,CACA,+BAAA","sourcesContent":["@import \"simplebar-react/dist/simplebar.min.css\";\n\n[data-simplebar].rts-pa-fix-vertical-scrollbar-overlap {\n  max-width: calc(100% + 10px);\n  width: calc(100% + 10px);\n  padding-right: 10px;\n  padding-bottom: 1px; //fixes container \"eating\" content; e.g. last accordion's button's border in rep modal\n}\n\n[data-simplebar].rts-pa-fix-horizontal-scrollbar-overlap {\n  max-height: calc(100% + 10px);\n  height: calc(100% + 10px);\n  padding-bottom: 10px;\n}\n\n.simplebar-content-wrapper:focus {\n  outline: none;\n}\n","[class*=\"loader-module\"] {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n","@import \"styles\";\n\n.rts-pa-upsert-calendar-row-modal-milestone {\n  &-milestone-accordion {\n    background-color: #f5f5f5;\n    padding: 20px;\n    border-radius: 0;\n    border-bottom: 1px solid #e6e6e6;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
