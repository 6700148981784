import {
  AdvancedTooltipValidator,
  LinkValidator,
  StatusValidator
} from "api/common";
import { Array, Boolean, Number, Record, Static, String } from "runtypes";
import { SupplyKeyInsightsValidator } from "./esg-common";

export const TotalCarbonWasteWaterReductionOffsetValidator = Record({
  tooltip: AdvancedTooltipValidator.nullable(),
  status: StatusValidator,
  supplyKeyInsights: SupplyKeyInsightsValidator,
  title: String,
  carbon: Number,
  waste: Number,
  water: Number,
  links: Array(LinkValidator),
  statusDriver: Boolean
});

export type TotalCarbonWasteWaterReductionOffset = Static<
  typeof TotalCarbonWasteWaterReductionOffsetValidator
>;
