import React from "react";
import "./StatusSelector.scss";
import classNames from "classnames";
import {
  AutoUpdater,
  AutoUpdaterType,
  useAutoUpdater
} from "global/use-auto-updater";
import { Status } from "api/common";
import StatusSelectorButton from "./status-selector-button/StatusSelectorButton";
import { Label } from "common-components";

type Props<T extends AutoUpdaterType> = {
  value?: Status; //not needed with autoUpdater
  onChange?: (val: Status) => void; //not needed with autoUpdater
  className?: string;
  required?: boolean;
  autoUpdater: AutoUpdater<T>;
};

export const StatusSelector = <T extends AutoUpdaterType>(props: Props<T>) => {
  const namespace = "rts-pa-status-selector";
  const className = classNames(namespace, props.className);
  const { value, onChange } = useAutoUpdater<Status, T>(props.autoUpdater);

  return (
    <div className={className}>
      <Label requiredAsterisk={props.required}>Status</Label>

      <div className={`${namespace}-status-container`}>
        <StatusSelectorButton
          status="green"
          currentVal={value}
          onChangeAutoUpdater={onChange}
          onChangeProp={props.onChange}
        />
        <StatusSelectorButton
          status="red"
          currentVal={value}
          onChangeAutoUpdater={onChange}
          onChangeProp={props.onChange}
        />
        <StatusSelectorButton
          status="yellow"
          currentVal={value}
          onChangeAutoUpdater={onChange}
          onChangeProp={props.onChange}
        />
        <StatusSelectorButton
          status="gray"
          currentVal={value}
          onChangeAutoUpdater={onChange}
          onChangeProp={props.onChange}
        />
      </div>
    </div>
  );
};

export default StatusSelector;
