import React, { useContext } from "react";
import { Container } from "@gitlab-rtsensing/component-library";
import PermissionsWrapper from "common-components/permissions-wrapper/permissions-wrapper";
import { PermissionsContext } from "global/permissions";
import "./ChangeLogPage.scss";
import ChangeLogPageHeader from "./header/ChangeLogPageHeader";
import ChangeLogTable from "components/change-log-table/ChangeLogTable";

const namespace = "rts-pa-change-log-page";

export default function AgendaGridPage(): JSX.Element {
  const isLoaded = useContext(PermissionsContext).isLoaded;
  const hasPerms = useContext(PermissionsContext).changeLog;

  return (
    <>
      <div className={namespace}>
        <PermissionsWrapper permissions={isLoaded}>
          <Container>
            <PermissionsWrapper permissions={hasPerms}>
              <ChangeLogPageHeader />
              <ChangeLogTable />
            </PermissionsWrapper>
          </Container>
        </PermissionsWrapper>
      </div>
    </>
  );
}
