import React from "react";
import "./ComModalPeopleTalentAcquisition.scss";
import {
  CommercialPeopleInactiveSection,
  CommercialPeopleTalentAcquisitionSection
} from "api";
import { AccordionWithStatus } from "common-components/accordion-with-status/AccordionWithStatus";
import getAdvancedTooltipContent from "common-components/advanced-tooltip/getAdvancedTooltipContent";
import { Label, Text } from "common-components";

const namespace = "rts-pa-commercial-modal-people-talent-acquisition";

type Props = {
  agendaItemTitle: string;
  data:
    | CommercialPeopleTalentAcquisitionSection
    | CommercialPeopleInactiveSection
    | undefined
    | null;
};

const ComModalPeopleTalentAcquisition = (props: Props) => {
  const title = "Talent Acquisition";

  if (!props.data) {
    return <></>;
  } else if (props.data.status === "inactive") {
    return (
      <AccordionWithStatus
        title={title}
        status="gray"
        tooltipContent={getAdvancedTooltipContent(props.data.tooltip)}
      />
    );
  }

  return (
    <AccordionWithStatus
      status={props.data.status}
      title={title}
      className={namespace}
      links={props.data.externalLinks}
      accordionSyncKey="CommercialModal"
    >
      <div className={`${namespace}-metrics`}>
        <div />
        <Label>{props.agendaItemTitle}</Label>
        <Label>amgen</Label>
        <Text>Open Requisitions</Text>
        <Text keepSpaceWhenEmpty>{props.data.openRequisitions}</Text>
        <Text keepSpaceWhenEmpty>{props.data.amgenOpenRequisitions}</Text>
        <Text>Average Open Days</Text>
        <Text keepSpaceWhenEmpty strong>
          {props.data.avgOpenDays}
        </Text>
        <Text keepSpaceWhenEmpty>{props.data.amgenAvgOpenDays}</Text>
      </div>
    </AccordionWithStatus>
  );
};

export default ComModalPeopleTalentAcquisition;
