import React from "react";
import { AdvancedTooltip } from "api";
import AdvancedTooltipContent from "./AdvancedTooltipContent";

// function, not component, because returning null as a component still returns a JSX.Element, not actually null
export const getAdvancedTooltipContent = (
  tooltip: AdvancedTooltip | null | undefined
) => {
  if (!tooltip) {
    return null;
  }

  return <AdvancedTooltipContent tooltip={tooltip} />;
};

export default getAdvancedTooltipContent;
