import React from "react";
import "./ComModalSupplyCogsReduction.scss";
import { CommercialSupplyCogsReductionSection } from "api";
import { AccordionWithStatus } from "common-components/accordion-with-status/AccordionWithStatus";
import { InactiveSection } from "api/common";

type Props = {
  data:
    | CommercialSupplyCogsReductionSection
    | InactiveSection
    | undefined
    | null;
};

const ComModalSupplyCogsReduction = (props: Props) => {
  const title = "COGS Reduction";

  if (!props.data) {
    return <></>;
  } else if (props.data.status === "inactive") {
    return <AccordionWithStatus title={title} status="gray" />;
  }

  return (
    <AccordionWithStatus
      status={props.data.status}
      title={title}
      className="rts-pa-commercial-modal-supply-cogs-reduction"
      accordionSyncKey="CommercialModal"
    />
  );
};

export default ComModalSupplyCogsReduction;
