import React, { useContext } from "react";
import { matchRoutes, useLocation, useNavigate } from "react-router-dom";
import pages from "pages/pages";
import { PermissionsContext } from "global/permissions";
import TabNavigation, {
  TabItem
} from "common-components/tab-navigation/TabNavigation";
import "./AgendaTabNavigation.scss";
import { useQuery } from "@tanstack/react-query";
import { getAgendaId } from "utils/multitenancy";
import { getSubmissionsCount, SubmissionCount } from "api/getSubmissionCount";

const namespace = "rts-pa-agenda-tab-navigation";

enum TabKeys {
  agenda = "agenda",
  submissions = "submissions",
  changeLog = "changeLog"
}

const AgendaTabNavigation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const gridPerms = useContext(PermissionsContext);

  const { data } = useQuery<SubmissionCount>(
    ["submission-count"],
    getSubmissionsCount,
    {
      enabled: gridPerms.submissions && getAgendaId() === "1"
    }
  );

  const items: TabItem[] = [
    {
      key: TabKeys.agenda,
      label: "Agenda",
      onClick: () => navigate(pages.grid.go())
    }
  ];

  if (gridPerms.submissions) {
    items.push({
      key: TabKeys.submissions,
      label: "Submissions",
      badge: data?.count || 0,
      onClick: () => navigate(pages.grid.submissions.go())
    });
  }

  if (gridPerms.changeLog) {
    items.push({
      key: TabKeys.changeLog,
      label: "Change Log",
      onClick: () => navigate(pages.grid.changeLog.go())
    });
  }

  const isPageMatch = (url: string) => matchRoutes([{ path: url }], location);

  let activeKey: TabKeys;
  if (isPageMatch(pages.grid.submissions.path)) {
    activeKey = TabKeys.submissions;
  } else if (isPageMatch(pages.grid.changeLog.path)) {
    activeKey = TabKeys.changeLog;
  } else {
    activeKey = TabKeys.agenda;
  }

  return (
    <TabNavigation items={items} activeKey={activeKey} className={namespace} />
  );
};

export default AgendaTabNavigation;
