import { Array, Record, Static } from "runtypes";
import {
  ActiveDirectoryGroupValidator,
  ActiveDirectoryUserValidator
} from "./active-directory-common";

export const ActiveDirectoryAllEntitiesValidator = Record({
  users: Array(ActiveDirectoryUserValidator),
  groups: Array(ActiveDirectoryGroupValidator)
});

export type ActiveDirectoryAllEntities = Static<
  typeof ActiveDirectoryAllEntitiesValidator
>;
