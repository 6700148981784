import axios from "axios";

import { getKeyEventsId } from "utils/multitenancy";
import {
  CalendarRowItem,
  CalendarRowItemValidator
} from "./calendar/calendar-row";
import {
  CalendarMilestones,
  CalendarMilestonesValidator
} from "./calendar/calendar-common";

export const getAutomatedMilestones = async (
  calendarRowLinkId: string
): Promise<CalendarMilestones> => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  const result = await axios.get<CalendarMilestones>(
    `${REACT_APP_DOMAIN}/api/v1/${getKeyEventsId()}/automated_milestones/${calendarRowLinkId}`
  );

  CalendarMilestonesValidator.check(result.data);

  return result.data;
};

export const getCalendarRow = async (id: number): Promise<CalendarRowItem> => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  const result = await axios.get<CalendarRowItem>(
    `${REACT_APP_DOMAIN}/api/v1/${getKeyEventsId()}/calendar_row/${id}`
  );

  CalendarRowItemValidator.check(result.data);

  return result.data;
};

export const postCalendarRow = async (
  rowItem: CalendarRowItem
): Promise<CalendarRowItem> => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  const result = await axios.post<CalendarRowItem>(
    `${REACT_APP_DOMAIN}/api/v1/${getKeyEventsId()}/calendar_row`,
    rowItem
  );

  CalendarRowItemValidator.check(result.data);

  return result.data;
};

export const putCalendarRow = async (
  rowItem: CalendarRowItem
): Promise<CalendarRowItem> => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  const result = await axios.put<CalendarRowItem>(
    `${REACT_APP_DOMAIN}/api/v1/${getKeyEventsId()}/calendar_row/${rowItem.id}`,
    rowItem
  );

  CalendarRowItemValidator.check(result.data);

  return result.data;
};

export const deleteCalendarRow = async (rowItemId: number) => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  const result = await axios.delete(
    `${REACT_APP_DOMAIN}/api/v1/${getKeyEventsId()}/calendar_row/${rowItemId}`
  );

  return result;
};
