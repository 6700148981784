import React from "react";
import "./Unauthorized.scss";
import { Button, Text, Title } from "common-components";
import LockIcon from "icons/lock";

const namespace = "rts-pa-agenda-unauthorized-page";

export default function UnauthorizedPage(): JSX.Element {
  return (
    <div className={namespace}>
      <div className={`${namespace}-content`}>
        <LockIcon color={"#75A8DD"} />
        <Title level={3}>Not Authorized</Title>
        <Text>You are not authorized to view this page</Text>
        <Button
          text="Sensing Home"
          type="secondary"
          onClick={() => {
            window.location.href = `${window.location.origin}/home`;
          }}
        />
      </div>
    </div>
  );
}
