import React from "react";
import { Droppable } from "react-beautiful-dnd";
import "./AgendaGridBox.scss";
import { AgendaItem } from "api/common";
import AgendaGridLineItem from "../agenda-grid-line-item/AgendaGridLineItem";
import classnames from "classnames";
import AgendaGridBoxIcon from "../agenda-grid-box-icon/AgendaGridBoxIcon";
import {
  PlusIcon,
  Button
} from "@opsdti-global-component-library/amgen-design-system";
import { useGridModeHelper } from "global/use-grid-mode-helper";
import { useNavigate } from "react-router-dom";
import pages from "pages/pages";
import { Text, Title } from "common-components";
import { getAgendaId } from "utils/multitenancy";
//import AgendaGridLineItemPlaceholder from "../agenda-grid-line-item-placeholder/AgendaGridLineItemPlaceholder";

const namespace = "rts-pa-agenda-grid-box";

type Props = {
  focus: number;
  value: number;
  items: AgendaItem[];
};

const getTitlePieceLevelValue = (level: number) => {
  switch (level) {
    case 1:
      return "Lower";
    case 2:
      return "Medium";
    case 3:
      return "Higher";
    default:
      return "";
  }
};

const getTitlePieceLevelFocus = (level: number) => {
  switch (level) {
    case 1:
      return "Regular";
    case 2:
      return "Higher";
    case 3:
      return "Immediate";
    default:
      return "";
  }
};

const getTitle = (focus: number, value: number) => {
  const focusLevel = getTitlePieceLevelFocus(focus);
  const valueLevel = getTitlePieceLevelValue(value);

  return `${valueLevel} Value + ${focusLevel} Focus`;
};

const AgendaGridBox = (props: Props) => {
  const { focus, value } = props;
  const gridMode = useGridModeHelper();
  const navigate = useNavigate();

  const isHighlighted =
    (focus >= 2 && value === 3) || (focus === 3 && value >= 2);

  const classNameBox = classnames(namespace, {
    special: isHighlighted
  });

  const boxId = `${focus}${value}`;

  return (
    <div className={classNameBox} data-grid-id={boxId}>
      <div className="mobile-header">
        <Title level={3}>{getTitle(focus, value)}</Title>
        <AgendaGridBoxIcon focus={focus} value={value} />
      </div>

      {isHighlighted && (
        <Text type="blue" strong className="priority-header">
          {getAgendaId() === "1" ? "Priority for CEO Staff" : "Priority"}
        </Text>
      )}

      <Droppable droppableId={boxId}>
        {(provided, snapshot) => {
          const classNameBody = classnames(`${namespace}-droppable`, {
            isDraggingOver: snapshot.isDraggingOver
          });

          return (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={classNameBody}
            >
              {props.items.map((agendaItem, i) => (
                <AgendaGridLineItem
                  key={agendaItem.id}
                  agendaItem={agendaItem}
                  index={i}
                />
              ))}
              {provided.placeholder}
              {/* <AgendaGridLineItemPlaceholder droppableId={boxId} /> */}
            </div>
          );
        }}
      </Droppable>
      {!gridMode.isViewMode && (
        <Button
          text="Add Item"
          icon={<PlusIcon width={14} height={14} />}
          type="secondary"
          className={`${namespace}-add-button`}
          disabled={!gridMode.isEditMode}
          onClick={() => {
            navigate(pages.grid.edit.addItemModal.go(focus, value));
          }}
        />
      )}
    </div>
  );
};

export default AgendaGridBox;
