import React from "react";
import "./TalentDinbModalDinbMetrics.scss";
import ModalSection from "common-components/modal-section/ModalSection";
import { TalentDinbDiversityInclusionBelongingSection } from "api/talent-dinb/talent-dinb-diversity-inclusion-belonging";
import TalentDinbModalDinbWomenInLeadership from "./women-in-leadership/TalentDinbModalDinbWomenInLeadership";

type Props = {
  data: TalentDinbDiversityInclusionBelongingSection | undefined | null;
};

const TalentDinbModalDinbMetrics = (props: Props) => {
  const namespace = "rts-pa-talent-dinb-modal-dinb-metrics";
  if (!props.data) {
    return <></>;
  }

  return (
    <ModalSection
      title="Diversity Inclusion & Belonging (DI&B) Metrics"
      className={namespace}
    >
      <div className="rts-pa-talent-dinb-modal-dinb-metrics-container">
        <TalentDinbModalDinbWomenInLeadership
          data={props.data.womenInLeadership}
        />
      </div>
    </ModalSection>
  );
};

export default TalentDinbModalDinbMetrics;
