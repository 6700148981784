import React from "react";
import "./UpsertCalendarRowModalMilestone.scss";
import UpsertCalendarRowModalMilestoneFields from "../milestone-fields/UpsertCalendarRowModalMilestoneFields";
import { Checkbox } from "common-components";
import Accordion from "common-components/accordion/Accordion";
import {
  AutoUpdater,
  AutoUpdaterType,
  useAutoUpdater
} from "global/use-auto-updater";
import { CalendarMilestone } from "api/calendar/calendar-common";

type Props<T extends AutoUpdaterType> = {
  autoUpdater: AutoUpdater<T>;
  onDeleteClick?: () => void;
};

const namespace = "rts-pa-upsert-calendar-row-modal-milestone";

const UpsertCalendarRowMilestone = <T extends AutoUpdaterType>(
  props: Props<T>
) => {
  const { value: milestone } = useAutoUpdater<CalendarMilestone, T>(
    props.autoUpdater
  );

  return (
    <Accordion
      title={milestone.name}
      caretClickOnly={true}
      startOpen={milestone.type === "manual"}
      preContent={
        <Checkbox
          autoUpdater={{
            item: props.autoUpdater.item,
            setItem: props.autoUpdater.setItem,
            propExpression: ri =>
              (props.autoUpdater.propExpression(ri) as CalendarMilestone)
                .isVisible
          }}
        />
      }
      className={`${namespace}-milestone-accordion`}
    >
      <UpsertCalendarRowModalMilestoneFields
        autoUpdater={{
          item: props.autoUpdater.item,
          setItem: props.autoUpdater.setItem,
          propExpression: ri =>
            props.autoUpdater.propExpression(ri) as CalendarMilestone
        }}
        onDeleteClick={props.onDeleteClick}
      />
    </Accordion>
  );
};

export default UpsertCalendarRowMilestone;
