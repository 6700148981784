import React, { useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import "./TalentDinbModal.scss";
import PaModal from "common-components/pa-modal/PaModal";
import { useNavigate } from "react-router-dom";
import pages from "pages/pages";
import { PermissionsContext } from "global/permissions";
import PermissionsWrapper from "common-components/permissions-wrapper/permissions-wrapper";
import { useModalHelper } from "global/use-modal-helper";
import { TalentDinb } from "api/getTalentDinb";
import { getAgendaItemCached } from "api/cache";
import ModalExternalLinks from "common-components/modal-external-links/ModalExternalLinks";
import OwnersContainer from "common-components/owners-container/OwnersContainer";
import SummaryInfo from "common-components/summary-info/SummaryInfo";
import SubmissionRequest from "common-components/submission-request/SubmissionRequest";
import TalentDinbModalTalentMetrics from "./talent/TalentDinbModalTalentMetrics";
import TalentDinbModalDinbMetrics from "./dinb/TalentDinbModalDinbMetrics";
import TalentDinbModalLaborProductivityMetrics from "./labor-productivity/TalentDinbModalLaborProductivityMetrics";

const TalentDinbModal = () => {
  const namespace = "talent-dinb-modal";
  const perms = useContext(PermissionsContext);
  const hasPermissions = perms.grid.talentDinbModal;
  const navigate = useNavigate();
  const mhResult = useModalHelper(
    pages.grid.talentDinbModal.path,
    hasPermissions,
    "talent_dinb"
  );

  const { status, data, error } = useQuery<TalentDinb>(
    ["agenda-item-cached", mhResult.itemId],
    () => getAgendaItemCached("talent_dinb", mhResult.itemId),
    {
      enabled: mhResult.enabled
    }
  );

  return (
    <PaModal
      title={mhResult.title}
      status={status}
      axiosErrors={error}
      isOpen={mhResult.enabled}
      onClose={() => {
        navigate(pages.grid.go());
      }}
      className="rts-pa-talent-dinb-modal"
    >
      <PermissionsWrapper permissions={perms.submissions}>
        <SubmissionRequest agendaItem={mhResult.agendaItem} />
      </PermissionsWrapper>
      <SummaryInfo data={data?.summaryInfo} />
      <OwnersContainer owners={data?.owners} className={namespace} />
      <TalentDinbModalTalentMetrics data={data?.talent} />
      <TalentDinbModalDinbMetrics data={data?.diversityInclusionBelonging} />
      <TalentDinbModalLaborProductivityMetrics data={data?.laborProductivity} />
      <ModalExternalLinks
        links={data?.links}
        className={namespace}
        buttonType="primary"
      />
    </PaModal>
  );
};

export default TalentDinbModal;
