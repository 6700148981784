import React from "react";
import "./Button.scss";
import {
  Button as DSButton,
  ButtonProps
} from "@opsdti-global-component-library/amgen-design-system";
import classNames from "classnames";

const namespace = "rts-pa-button";

type Props = ButtonProps;

export const Button = (props: Props) => {
  const className = classNames(namespace, props.className);

  return <DSButton {...props} className={className} />;
};

export default Button;
