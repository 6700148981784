import React, { useMemo } from "react";
import "./PrintFooter.scss";
import dayjs from "dayjs";
import { ExportMode } from "components/export-modal/export-modal-options/ExportModalOptions";

type Props = {
  mode: ExportMode | undefined;
};

export const PrintFooter = (props: Props) => {
  const timeStr = useMemo(() => {
    if (props.mode) {
      return dayjs().tz().format("M/D/YYYY, HH:mm:ss A");
    }

    return undefined;
  }, [props.mode]);

  return (
    <div className="rts-pa-print-footer">
      <p className="rts-pa-print-confidentiality-container">
        Confidential Information General and Administrative
      </p>
      <p className="rts-pa-timestamp-container">{`Exported ${timeStr}`}</p>
    </div>
  );
};

export default PrintFooter;
