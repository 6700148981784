import React from "react";
import "./KeyEventsCalendarEmptyContainer.scss";
import { Empty } from "@opsdti-global-component-library/amgen-design-system";
import { Loader } from "@gitlab-rtsensing/component-library";
import { useQuery } from "@tanstack/react-query";
import { getCalendarBoard } from "api/calendar-board";
import { useStatusManager } from "global/use-status-manager";
import { getMilestoneCalendarKeys } from "api";
import useLimitedView from "global/use-limited-view";
import pages from "pages/pages";
import { CalendarFilters } from "../calendarTypes";

export const namespace = "rts-pa-key-events-calendar-empty-container";

type Props = {
  filters: CalendarFilters;
};

export default function KeyEventsCalendarEmptyContainer(
  props: Props
): JSX.Element {
  const { isLimitedView } = useLimitedView(pages.keyEvents);

  const { status: boardStatus } = useQuery(
    [
      "calendar-board",
      isLimitedView,
      props.filters.startDateMin,
      props.filters.startDateMax
    ],
    () =>
      getCalendarBoard(
        isLimitedView,
        props.filters.startDateMin,
        props.filters.startDateMax
      )
  );

  const { status: calendarKeysStatus } = useQuery(
    ["calendar-keys"],
    getMilestoneCalendarKeys
  );

  const { status } = useStatusManager(boardStatus, calendarKeysStatus);

  return (
    <div className={namespace}>
      {status === "loading" ? <Loader /> : <Empty description="No Data" />}
    </div>
  );
}
