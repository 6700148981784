import { useMemo } from "react";
import {
  matchRoutes,
  useLocation,
  useNavigate,
  useSearchParams
} from "react-router-dom";

type Page = {
  path: string;
  go: (someId?: string, isLimitedView?: boolean) => string;
};

type LimitedViewResult = {
  isLimitedView: boolean;
  toggleLimitedView: () => void;
};

export default function useLimitedView(page: Page): LimitedViewResult {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const result = useMemo<LimitedViewResult>(() => {
    const isPageMatch = matchRoutes([{ path: page.path }], location);

    if (!isPageMatch) {
      return {
        isLimitedView: false,
        toggleLimitedView: () => {
          navigate(page.go(undefined, false));
        }
      };
    }

    if (searchParams.get("limited_view") === "true") {
      return {
        isLimitedView: true,
        toggleLimitedView: () => {
          navigate(page.go(undefined, false));
        }
      };
    }

    return {
      isLimitedView: false,
      toggleLimitedView: () => {
        navigate(page.go(undefined, true));
      }
    };
  }, [location, searchParams, navigate, page]);

  return result;
}
