import React from "react";
import "./Tooltip.scss";
import { InfoIcon } from "@opsdti-global-component-library/amgen-design-system";
import { Popover } from "antd";
import classNames from "classnames";

const namespace = "rts-pa-tooltip";

type Props = {
  tooltipIcon?: JSX.Element;
  children: JSX.Element | null | undefined;
  trigger?: "click" | "hover";
};

export const Tooltip = (props: Props) => {
  const className = classNames(namespace, {
    [`${namespace}-click`]: props.trigger === "click"
  });

  if (!props.children) {
    return <></>;
  }

  return (
    <Popover
      content={props.children}
      trigger={props.trigger}
      className={className}
    >
      {props.tooltipIcon || (
        <InfoIcon color="secondary" width={16} height={16} />
      )}
    </Popover>
  );
};

export default Tooltip;
