import React from "react";
import "./AgendaItemBuilderModalSection.scss";
import { Draggable } from "react-beautiful-dnd";
import classnames from "classnames";
import TrashIcon from "icons/trash-icon";
import DragIcon from "icons/drag-icon";
import { AgendaItemTemplate } from "api/agenda-item-template/agenda-item-template";
import { AgendaItemTemplateSection } from "api/agenda-item-template/agenda-item-template-common";
import { AutoUpdaterSetItem } from "global/use-auto-updater";
import { FieldValUtils } from "global/use-validator";
import AgendaItemBuilderModalSectionBase from "./AgendaItemBuilderModalSectionBase";
import AgendaItemBuilderModalSectionMapper from "./AgendaItemBuilderModalSectionMapper";
import produce from "immer";

const namespace = "rts-pa-agenda-item-builder-modal-section";

export type SectionBasicProps = {
  section: AgendaItemTemplateSection;
  index: number;
  groupIndex: number;
  agendaItemTemplate: AgendaItemTemplate;
  setAgendaItemTemplate: AutoUpdaterSetItem<AgendaItemTemplate>;
  fieldUtils: FieldValUtils;
  updateTitle: (title: string) => void;
};

type Props = Omit<SectionBasicProps, "updateTitle"> & {
  sectionDroppableType: string;
};

export const AgendaItemBuilderModalSection = (props: Props) => {
  const onDeleteClick = () => {
    const newTemplate = produce(props.agendaItemTemplate, draftState => {
      draftState.groups[props.groupIndex].sections.splice(props.index, 1);
    });

    props.setAgendaItemTemplate(newTemplate);
  };

  const updateTitle = (title: string) => {
    const newTemplate = produce(props.agendaItemTemplate, draftState => {
      draftState.groups[props.groupIndex].sections[props.index].title = title;
    });

    props.setAgendaItemTemplate(newTemplate);
  };

  return (
    <Draggable
      draggableId={props.section.id.toString()}
      index={props.index}
      type={props.sectionDroppableType}
    >
      {(provided, snapshot) => {
        const rootClassName = classnames(namespace, {
          isDragging: snapshot.isDragging
        });

        return (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            style={provided.draggableProps.style}
            className={rootClassName}
          >
            <div className={`${namespace}-content`}>
              <AgendaItemBuilderModalSectionBase
                {...props}
                updateTitle={updateTitle}
              />
              <AgendaItemBuilderModalSectionMapper
                {...props}
                updateTitle={updateTitle}
              />
            </div>
            <div className={`${namespace}-icons`}>
              <div
                className={`${namespace}-icons-drag`}
                {...provided.dragHandleProps}
              >
                <DragIcon height={10} />
              </div>
              <div
                className={`${namespace}-icons-trash`}
                onClick={onDeleteClick}
              >
                <TrashIcon />
              </div>
            </div>
          </div>
        );
      }}
    </Draggable>
  );
};

export default AgendaItemBuilderModalSection;
