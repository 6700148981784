import React, { useMemo } from "react";
import "./AgendaItemBuilderModalGroup.scss";
import { Draggable, Droppable } from "react-beautiful-dnd";
import classnames from "classnames";
import {
  AgendaItemTemplate,
  AgendaItemTemplateGroup
} from "api/agenda-item-template/agenda-item-template";
import Accordion from "common-components/accordion/Accordion";
import { AutoUpdaterSetItem } from "global/use-auto-updater";
import { FieldValUtils } from "global/use-validator";
import TrashIcon from "icons/trash-icon";
import DragIcon from "icons/drag-icon";
import { AgendaItemBuilderModalSection } from "../sections/AgendaItemBuilderModalSection";
import {
  PlusIcon,
  Button
} from "@opsdti-global-component-library/amgen-design-system";
import produce from "immer";
import { v4 as uuid } from "uuid";
import { AgendaItemSectionPlaceholder } from "api/agenda-item/sections";
import { AgendaItemSectionType } from "api/agenda-item/agenda-item-common";
import { Select, GenericOption } from "common-components";

const namespace = "rts-pa-agenda-item-builder-modal-group";

type Props = {
  group: AgendaItemTemplateGroup;
  index: number;
  agendaItemTemplate: AgendaItemTemplate;
  setAgendaItemTemplate: AutoUpdaterSetItem<AgendaItemTemplate>;
  fieldUtils: FieldValUtils;
  groupDroppableType: string;
};

enum GroupTitles {
  "Global Net Sales",
  "US Brand Metrics",
  "Pipeline Metrics",
  "Supply Metrics",
  "People Metrics"
}

export const AgendaItemBuilderModalGroup = (props: Props) => {
  const groupTitles = useMemo<GenericOption<string>[]>(() => {
    const arr: GenericOption<string>[] = [];

    for (const title in GroupTitles) {
      if (Number.isNaN(Number(title))) {
        arr.push({
          label: title,
          value: title
        });
      }
    }

    return arr;
  }, []);

  const onAddSectionClick = () => {
    const newTemplate = produce(props.agendaItemTemplate, draftState => {
      draftState.groups[props.index].sections.push({
        id: uuid(),
        title: "",
        type: "" as AgendaItemSectionType
      } as AgendaItemSectionPlaceholder);
    });

    props.setAgendaItemTemplate(newTemplate);
  };

  const onDeleteClick = () => {
    const newTemplate = produce(props.agendaItemTemplate, draftState => {
      draftState.groups.splice(props.index, 1);
    });

    props.setAgendaItemTemplate(newTemplate);
  };

  return (
    <Draggable
      draggableId={props.group.id.toString()}
      index={props.index}
      type={props.groupDroppableType}
    >
      {(provided, snapshot) => {
        const rootClassName = classnames(namespace, {
          isDragging: snapshot.isDragging
        });

        return (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            style={provided.draggableProps.style}
            className={rootClassName}
          >
            <div className={`${namespace}-content`}>
              <Accordion
                title={
                  <Select
                    autoUpdater={{
                      item: props.agendaItemTemplate,
                      setItem: props.setAgendaItemTemplate,
                      propExpression: x => x.groups[props.index].label
                    }}
                    options={groupTitles}
                  />
                }
                className={namespace}
                startOpen
                caretClickOnly
              >
                <Droppable droppableId={props.group.id} type={props.group.id}>
                  {(provided, snapshot) => {
                    const classNameBody = classnames(
                      `${namespace}-content-sections`,
                      {
                        isDraggingOver: snapshot.isDraggingOver
                      }
                    );

                    return (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className={classNameBody}
                      >
                        {props.group.sections.map((section, i) => (
                          <AgendaItemBuilderModalSection
                            key={section.id}
                            section={section}
                            index={i}
                            groupIndex={props.index}
                            agendaItemTemplate={props.agendaItemTemplate}
                            setAgendaItemTemplate={props.setAgendaItemTemplate}
                            fieldUtils={props.fieldUtils}
                            sectionDroppableType={props.group.id}
                          />
                        ))}
                        {provided.placeholder}
                      </div>
                    );
                  }}
                </Droppable>
                <Button
                  text="Add Section"
                  type="primary"
                  onClick={onAddSectionClick}
                  icon={<PlusIcon width={14} height={14} />}
                />
              </Accordion>
            </div>
            <div className={`${namespace}-icons`}>
              <div
                className={`${namespace}-icons-drag`}
                {...provided.dragHandleProps}
              >
                <DragIcon height={10} />
              </div>
              <div
                className={`${namespace}-icons-trash`}
                onClick={onDeleteClick}
              >
                <TrashIcon />
              </div>
            </div>
          </div>
        );
      }}
    </Draggable>
  );
};

export default AgendaItemBuilderModalGroup;
