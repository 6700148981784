import React from "react";
import "./AIModalSectionSingleMetric.scss";
import { AgendaItemSectionSingleMetric } from "api/agenda-item/sections";
import { AdvancedTooltip } from "common-components/advanced-tooltip/AdvancedTooltip";
import StatusIcon from "common-components/status-icon/StatusIcon";

const namespace = "rts-pa-agenda-item-modal-section-single-metric";

type Props = {
  section: AgendaItemSectionSingleMetric;
};

export const AIModalSectionSingleMetric = (props: Props) => {
  return (
    <div className={namespace}>
      <div className={`${namespace}-left-container`}>
        <StatusIcon status={props.section.status} />
        <div className={`${namespace}-title`}>{props.section.title}</div>
        <AdvancedTooltip tooltip={props.section.tooltip} />
      </div>
      <div className={`${namespace}-right-container`}>
        <div className={`${namespace}-value ${props.section.status}`}>
          {props.section.value}
        </div>
      </div>
    </div>
  );
};

export default AIModalSectionSingleMetric;
