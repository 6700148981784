import React, { useRef } from "react";
import { SearchProps } from "antd/es/input";
import "./SearchInput.scss";
import { Search } from "@opsdti-global-component-library/amgen-design-system";
import { Label } from "common-components";

type Props = {
  label: string;
  delayMs?: number;
  onChange: (value: string) => void;
} & Omit<SearchProps, "onChange">;

const namespace = "rts-pa-search-input";

const SearchInput = ({ label, delayMs, onChange, ...rest }: Props) => {
  const delayTimeout = useRef<NodeJS.Timeout | undefined>(undefined);

  return (
    <div className={namespace}>
      <Label>{label}</Label>
      <Search
        onChange={e => {
          const val = e.target.value;

          if (!delayMs) {
            onChange(val);
          } else {
            clearTimeout(delayTimeout.current);

            delayTimeout.current = setTimeout(() => {
              onChange(val);
              delayTimeout.current = undefined;
            }, delayMs);
          }
        }}
        placeholder={rest.placeholder}
      />
    </div>
  );
};

export default SearchInput;
