import { Number, Record, Static, String, Array } from "runtypes";
import { CommercialPipelineSectionValidator } from "./commercial-item/commercial-pipeline";
import { CommercialSupplySectionValidator } from "./commercial-item/commercial-supply";
import { CommercialPeopleSectionValidator } from "./commercial-item/commercial-people";
import { CommercialBrandSectionValidator } from "./commercial-item/commercial-brand";
import {
  ArchetypeValidator,
  LinkValidator,
  OwnersValidator,
  SummaryInfoValidator
} from "./common";

export const CommercialItemValidator = Record({
  title: String,
  archetype: ArchetypeValidator,
  triggers: Array(String),
  agendaItemId: Number,
  summaryInfo: SummaryInfoValidator,
  owners: OwnersValidator,
  brand: CommercialBrandSectionValidator.nullable(),
  pipeline: CommercialPipelineSectionValidator.nullable(),
  supply: CommercialSupplySectionValidator.nullable(),
  people: CommercialPeopleSectionValidator.nullable(),
  links: Array(LinkValidator)
});

export type CommercialItem = Static<typeof CommercialItemValidator>;
