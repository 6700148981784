import { Literal, Static } from "runtypes";
import { AgendaItemSectionValidator } from "../agenda-item-common";

export const AgendaItemSectionPlaceholderValidator =
  AgendaItemSectionValidator.extend({
    type: Literal("placeholder")
  });

export type AgendaItemSectionPlaceholder = Static<
  typeof AgendaItemSectionPlaceholderValidator
>;
