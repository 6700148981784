import React from "react";
import "./PipelineStatusWithTooltip.scss";
import { AdvancedTooltip, PipelineActivityStatus } from "api/common";
import {
  AdvancedTooltipContent,
  Tooltip,
  StatusIcon,
  Text
} from "common-components";

const namespace = "rts-pa-pipeline-status-with-tooltip";

type Props = {
  status: PipelineActivityStatus;
  tooltip: AdvancedTooltip;
};

const PipelineStatusWithTooltip = (props: Props) => {
  let statusText = "";

  switch (props.status.toLowerCase()) {
    case "accelerated":
      statusText = "Accelerated";
      break;
    case "green":
    case "on track":
      statusText = "On Track";
      break;
    case "yellow":
    case "subject to change":
      statusText = "Subject to Change";
      break;
    case "red":
    case "delayed":
      statusText = "Delayed";
      break;
  }

  return (
    <div className={namespace}>
      <Tooltip tooltipIcon={<StatusIcon status={props.status} />}>
        <AdvancedTooltipContent tooltip={props.tooltip} />
      </Tooltip>
      <Text>{statusText}</Text>
    </div>
  );
};

export default PipelineStatusWithTooltip;
