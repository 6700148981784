import { RowItem } from "components/key-events-calendar/calendarTypes";
import { createContext, useContext } from "react";

type CalendarContextType = {
  calendarRows: RowItem[];
  setCalendarRows: React.Dispatch<React.SetStateAction<RowItem[]>>;
};

export const CalendarContext = createContext<CalendarContextType | undefined>(
  undefined
);

export const useCalendarContext = () => {
  const context = useContext(CalendarContext);

  if (!context) {
    throw new Error(
      "useCalendarContext must be used within a CalendarContext.Provider"
    );
  }

  return context;
};
