import React from "react";
import "./PipelineProject.scss";
import {
  NextApprovalLaunchE2l,
  NextPriorityMilestone,
  PipelineStudyEnrollment
} from "api";
import PipelineProjectNextApprovalLaunchE2l from "./nextApprovalLaunchE2l/PipelineProjectNextApprovalLaunchE2l";
import PipelineProjectNextPriorityMilestone from "./nextPriorityMilestone/PipelineProjectNextPriorityMilestone";
import PipelineProjectStudyEnrollments from "./studyEnrollments/PipelineProjectStudyEnrollments";
import { Owners } from "api/common";
import OwnersContainer from "common-components/owners-container/OwnersContainer";

const namespace = "rts-pa-pipeline-project";

type Props = {
  owners?: Owners | null;
  hideOwnersOnEmpty?: boolean;
  nextApprovalLaunchE2l: NextApprovalLaunchE2l | undefined;
  nextPriorityMilestone: NextPriorityMilestone | undefined;
  studyEnrollments: PipelineStudyEnrollment[] | undefined;
};

const PipelineProject = (props: Props) => {
  return (
    <div className={namespace}>
      <OwnersContainer
        owners={props.owners}
        hideOnEmpty={props.hideOwnersOnEmpty}
      />
      <PipelineProjectNextApprovalLaunchE2l
        data={props.nextApprovalLaunchE2l}
      />
      <PipelineProjectNextPriorityMilestone
        data={props.nextPriorityMilestone}
      />
      <PipelineProjectStudyEnrollments data={props.studyEnrollments} />
    </div>
  );
};

export default PipelineProject;
