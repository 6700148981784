import { Array, Record, Static } from "runtypes";
import { ActiveDirectoryUserValidator } from "./active-directory-common";

export const ActiveDirectoryGroupUsersValidator = Record({
  users: Array(ActiveDirectoryUserValidator)
});

export type ActiveDirectoryGroupUsers = Static<
  typeof ActiveDirectoryGroupUsersValidator
>;
