import { StatusValidator, LinkValidator, InactiveSectionValidator } from "api/common";
import { Array, Boolean, Number, Record, Static, String } from "runtypes";

const TalentDinbDiversityInclusionBelongingWomenInLeadershipValidator = Record({
  status: StatusValidator,
  title: String,
  executive: Number,
  management: Number,
  links: Array(LinkValidator),
  statusDriver: Boolean
});

export type TalentDinbDiversityInclusionBelongingWomenInLeadership = Static<
  typeof TalentDinbDiversityInclusionBelongingWomenInLeadershipValidator
>;

export const TalentDinbDiversityInclusionBelongingSectionValidator = Record({
  womenInLeadership:
    TalentDinbDiversityInclusionBelongingWomenInLeadershipValidator.Or(InactiveSectionValidator)
});

export type TalentDinbDiversityInclusionBelongingSection = Static<
  typeof TalentDinbDiversityInclusionBelongingSectionValidator
>;
