import React from "react";
import "./AIModalSectionEngagementScoreMetric.scss";
import classNames from "classnames";

const namespace = "rts-pa-agenda-item-modal-section-engagement-score-metric";

type EngagementScoreProps = {
  label: string;
  date?: string;
  value: number | string;
  status?: string;
  styleType: "primary" | "secondary";
};

export const AIModalSectionEngagementScoreMetric = (
  props: EngagementScoreProps
) => {
  const valueClasses = classNames(
    `${namespace}-value`,
    props.status?.toLowerCase(),
    {
      bold: props.styleType === "primary"
    }
  );

  const labelClasses = classNames(`${namespace}-label`, {
    black: props.styleType === "primary"
  });

  return (
    <div className={`${namespace}`}>
      <div className={labelClasses}>
        {props.label} {props.date ? props.date : null}
      </div>
      <div className={valueClasses}>{props.value}</div>
    </div>
  );
};

export default AIModalSectionEngagementScoreMetric;
