import React, { useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import "./CommercialModal.scss";
import PaModal from "common-components/pa-modal/PaModal";
import SummaryInfo from "common-components/summary-info/SummaryInfo";
import { useNavigate } from "react-router-dom";
import pages from "pages/pages";
import { PermissionsContext } from "global/permissions";
import PermissionsWrapper from "common-components/permissions-wrapper/permissions-wrapper";
import ComModalSupplyMetrics from "components/commercial-modal/supply/ComModalSupplyMetrics";
import ComModalPipelineMetrics from "components/commercial-modal/pipeline/ComModalPipelineMetrics";
import ComModalPeopleMetrics from "components/commercial-modal/people/ComModalPeopleMetrics";
import { useModalHelper } from "global/use-modal-helper";
import { CommercialItem } from "api/getCommercialItem";
import { getAgendaItemCached } from "api/cache";
import ModalExternalLinks from "common-components/modal-external-links/ModalExternalLinks";
import SubmissionRequest from "common-components/submission-request/SubmissionRequest";
import OwnersContainer from "common-components/owners-container/OwnersContainer";
import ComModalBrandMetrics from "components/commercial-modal/brand/ComModalBrandMetrics";

const namespace = "rts-pa-commercial-modal";

const CommercialModal = () => {
  const perms = useContext(PermissionsContext);
  const hasPermissions = perms.grid.commercialModal;
  const navigate = useNavigate();
  const mhResult = useModalHelper(
    pages.grid.commercialModal.path,
    hasPermissions,
    "commercial"
  );
  const { status, data, error } = useQuery<CommercialItem>(
    ["agenda-item-cached", mhResult.itemId],
    () => getAgendaItemCached("commercial", mhResult.itemId),
    {
      enabled: mhResult.enabled
    }
  );

  return (
    <PaModal
      title={mhResult.title}
      status={status}
      axiosErrors={error}
      isOpen={mhResult.enabled}
      onClose={() => {
        navigate(pages.grid.go());
      }}
      className={namespace}
    >
      <PermissionsWrapper permissions={perms.submissions}>
        <SubmissionRequest agendaItem={mhResult.agendaItem} />
      </PermissionsWrapper>
      <SummaryInfo data={data?.summaryInfo} />
      <OwnersContainer owners={data?.owners} className="commercial-modal" />
      <ComModalBrandMetrics data={data?.brand} />
      <ComModalPipelineMetrics data={data?.pipeline} />
      <ComModalSupplyMetrics data={data?.supply} />
      <ComModalPeopleMetrics
        agendaItemTitle={data?.title}
        data={data?.people}
      />
      <ModalExternalLinks
        links={data?.links}
        className="commercial-modal"
        buttonType="primary"
      />
    </PaModal>
  );
};

export default CommercialModal;
