import React, { useMemo } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import pages from "./pages";

const AppRoutes = () => {
  const pageRoutes = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const getPageRoutes = (page: any): JSX.Element[] => {
      const { path, go, component, ...childrenPages } = page;

      // adds route with component, if one exists
      let routes =
        (component && [<Route key={path} path={path} element={component} />]) ||
        [];

      // adds redirect to route if missing agendaId
      if (path.includes("/:agendaId")) {
        const pathAgendaless = path.replace("/:agendaId", "");
        routes = routes.concat(
          <Route
            key={pathAgendaless}
            path={pathAgendaless}
            element={
              <Navigate
                to={window.location.pathname.replace(
                  "/prioritized-agenda/grid",
                  "/prioritized-agenda/grid/1"
                )}
                replace
              />
            }
          />
        );
      }

      for (const [, value] of Object.entries(childrenPages)) {
        routes = routes.concat(getPageRoutes(value));
      }

      return routes;
    };

    return getPageRoutes(pages);
  }, []);

  return (
    <Routes>
      {pageRoutes}
      <Route path="*" element={<Navigate to={pages.grid.go("1")} replace />} />
    </Routes>
  );
};

export default AppRoutes;
