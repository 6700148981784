import React from "react";
import "./KeyEventsCalendarRowBorder.scss";

export const namespace = "rts-pa-key-events-calendar-row-border";

type Props = {
  year: number;
};

export default function KeyEventsCalendarRowBorder(props: Props): JSX.Element {
  return (
    <div
      className={namespace}
      style={{
        gridColumn: `y${props.year}m12 / span 1`
      }}
    />
  );
}
