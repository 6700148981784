import {
  Button,
  Input,
  Label,
  PaModal,
  Select,
  Text,
  ToggleButton
} from "common-components";
import React, { useContext, useEffect, useMemo, useState } from "react";
import "./UpsertCalendarRowModal.scss";
// TODO JCG: implement Tabs component in PA and use wrapper component (as opposed to TabNavigation)
import {
  Alert,
  Popconfirm,
  Tabs,
  useMessage
} from "@opsdti-global-component-library/amgen-design-system";
import pages from "pages/pages";
import { PermissionsContext } from "global/permissions";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useStatusManager } from "global/use-status-manager";
import { useNavigate } from "react-router-dom";
import { useValidator } from "global/use-validator";
import produce from "immer";
import TrashIcon from "icons/trash-icon";
import { useModalHelper } from "global/use-modal-helper";
import UpsertCalendarRowModalMilestoneGroup from "./milestone-group/UpsertCalendarRowModalMilestoneGroup";
import {
  CalendarRowItem,
  getAutomatedMilestones,
  getCalendarRow,
  postCalendarRow,
  putCalendarRow,
  deleteCalendarRow as deleteCalendarRowApiCall,
  getPipelineProducts,
  getCalendarBoard
} from "api";
import { queryClient } from "global/query-config";

const namespace = "rts-pa-upsert-calendar-row-modal";

const initialRow: CalendarRowItem = {
  id: 0,
  calendarRowVersionId: 0,
  calendarRowLinkId: null,
  order: 0,
  name: "",
  isVisibleToOfficers: false,
  linkToSource: null,
  automatedMilestones: [],
  manualMilestones: []
};

type AddMode = {
  text: "Add";
  isAdd: true;
  isEdit: false;
};

type EditMode = {
  text: "Edit";
  isAdd: false;
  isEdit: true;
};

type ModalMode = AddMode | EditMode;

export const UpsertCalendarRowModal = () => {
  const navigate = useNavigate();
  const hasPerms = useContext(PermissionsContext).keyEvents.edit;

  const [calendarRow, setCalendarRow] = useState<CalendarRowItem>(initialRow);
  const [isDeleteConfirmation, setIsDeleteConfirmation] = useState(false);
  const [isAutomatedMilestonesEmpty, setIsAutomatedMilestonesEmpty] =
    useState(true);
  const { containerUtils, fieldUtils } = useValidator();
  const { showMessage, contextHolder } = useMessage();

  const onMilestoneImportSuccess = () => {
    showMessage({
      content: "Import Successful",
      type: "success",
      duration: 5
    });
  };

  const mhResult = useModalHelper(
    [pages.keyEvents.addItemModal.path, pages.keyEvents.editItemModal.path],
    hasPerms
  );

  const mode: ModalMode = useMemo(() => {
    if (mhResult.path === pages.keyEvents.addItemModal.path) {
      return {
        text: "Add",
        isAdd: true,
        isEdit: false
      };
    } else {
      return {
        text: "Edit",
        isAdd: false,
        isEdit: true
      };
    }
  }, [mhResult]);

  /* populates an array of product items */
  const { data: pipelineProductsApi, status: productItemsStatus } = useQuery(
    ["pipeline-products"],
    getPipelineProducts,
    { enabled: mhResult.enabled }
  );

  const pipelineProducts = useMemo(() => {
    if (!pipelineProductsApi) {
      return [];
    }
    const itemsArr: { label: string; value: string }[] = [];

    pipelineProductsApi?.forEach(rowItem => {
      itemsArr.push({ label: rowItem.name, value: rowItem.calendarRowLinkId });
    });

    return itemsArr;
  }, [pipelineProductsApi]);

  const { data: automatedMilestonesApi, status: automatedMilestonesStatus } =
    useQuery(
      ["automated-milestones", calendarRow.calendarRowLinkId],
      () => getAutomatedMilestones(calendarRow.calendarRowLinkId as string),
      {
        enabled:
          mhResult.enabled && !!calendarRow.calendarRowLinkId && mode.isAdd
      }
    );

  useEffect(() => {
    if (
      automatedMilestonesStatus === "success" &&
      automatedMilestonesApi.length > 0
    ) {
      onMilestoneImportSuccess();
    }
  }, [automatedMilestonesStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  const { data: calendarBoard } = useQuery(["calendar-board", false], () =>
    getCalendarBoard(false)
  );

  const refreshCalendarBoard = () => {
    return queryClient.refetchQueries({ queryKey: ["calendar-board"] });
  };

  const {
    data: apiCalendarRow,
    status: apiCalendarRowStatus,
    remove: refreshApiCalendarRow,
    error
  } = useQuery(
    ["row-item", mhResult.itemId],
    () => getCalendarRow(mhResult.itemId),
    {
      enabled: mhResult.enabled && mode.isEdit
    }
  );

  const { status: addStatus, mutate: addCalendarRow } = useMutation(
    postCalendarRow,
    {
      onMutate: () => {
        overrideStatus("addCalendarRow", "loading");
      },
      onSuccess: () => {
        refreshCalendarBoard().then(() => {
          navigate(pages.keyEvents.go());
          setTimeout(() => {
            //keep spinner going when closing modal
            overrideStatus("addCalendarRow", undefined);
          }, 500);
        });
      },
      onError: () => {
        overrideStatus("addCalendarRow", undefined);
      }
    }
  );

  const { status: updateStatus, mutate: updateCalendarRow } = useMutation(
    putCalendarRow,
    {
      onMutate: () => {
        overrideStatus("updateCalendarRow", "loading");
      },
      onSuccess: () => {
        refreshCalendarBoard().then(() => {
          navigate(pages.keyEvents.go());

          refreshApiCalendarRow();

          setTimeout(() => {
            //keep spinner going when closing modal
            overrideStatus("updateCalendarRow", undefined);
          }, 500);
        });
      },
      onError: () => {
        overrideStatus("updateCalendarRow", undefined);
      }
    }
  );

  const { status: deleteStatus, mutate: deleteCalendarRow } = useMutation(
    deleteCalendarRowApiCall,
    {
      onMutate: () => {
        overrideStatus("deleteCalendarRow", "loading");
      },
      onSuccess: () => {
        refreshCalendarBoard().then(() => {
          navigate(pages.keyEvents.go());
          setTimeout(() => {
            //keep spinner going when closing modal
            overrideStatus("deleteCalendarRow", undefined);
            setIsDeleteConfirmation(false);
          }, 500);
        });
      },
      onError: () => {
        overrideStatus("deleteCalendarRow", undefined);
      }
    }
  );

  const { status, overrideStatus } = useStatusManager(
    mode.isEdit && mhResult.enabled ? apiCalendarRowStatus : "idle",
    productItemsStatus,
    addStatus,
    updateStatus,
    deleteStatus
  );

  //initialize modal on open/close
  useEffect(() => {
    if (!mhResult.enabled) {
      setTimeout(() => {
        setCalendarRow(initialRow);
        containerUtils.resetValidation();
      }, 500);
      return;
    }
  }, [mhResult.enabled]); // eslint-disable-line react-hooks/exhaustive-deps

  // initialize calendarRow when retrieved from DB (edit mode)
  useEffect(() => {
    if (!apiCalendarRow) {
      return;
    }

    setCalendarRow(apiCalendarRow);
  }, [apiCalendarRow]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSave = () => {
    containerUtils.triggerValidation().then(result => {
      if (!result.isValid) {
        return;
      }

      if (mode.isAdd) {
        // get next row order number
        let order = 1;
        if (calendarBoard?.rows.length) {
          order = calendarBoard.rows[calendarBoard.rows.length - 1].order + 1;
        }

        const addedRow = produce(calendarRow, draftState => {
          draftState.order = order;
        });

        addCalendarRow(addedRow);
      } else if (mode.isEdit) {
        updateCalendarRow(calendarRow);
      }
    });
  };

  const disableButtons = status === "loading";

  const onClose = () => {
    if (
      addStatus === "loading" ||
      updateStatus === "loading" ||
      deleteStatus === "loading"
    ) {
      return;
    }

    setTimeout(() => {
      setIsDeleteConfirmation(false);
    }, 500);

    navigate(pages.keyEvents.go());
  };

  // auto-populates name on item select
  useEffect(() => {
    if (!mode.isAdd) {
      return;
    }

    const newRowItem = produce(calendarRow, draftState => {
      if (!draftState.name) {
        const pipelineProduct = pipelineProducts.find(
          pp => pp.value === draftState.calendarRowLinkId
        );

        if (pipelineProduct) {
          draftState.name = pipelineProduct.label;
        }
      }
    });

    setCalendarRow(newRowItem);
  }, [calendarRow.calendarRowLinkId]); // eslint-disable-line react-hooks/exhaustive-deps

  // loads automated milestones on item select
  useEffect(() => {
    if (!mode.isAdd) {
      return;
    }

    const newRowItem = produce(calendarRow, draftState => {
      draftState.automatedMilestones = automatedMilestonesApi || [];
    });

    setCalendarRow(newRowItem);
  }, [automatedMilestonesApi]); // eslint-disable-line react-hooks/exhaustive-deps

  // TODO JCG: create separate component for footer buttons
  let footerButtons: JSX.Element;
  const footerClassName = `${namespace}-footer`;
  if (isDeleteConfirmation) {
    footerButtons = (
      <div className={footerClassName}>
        <Button
          text="Cancel Delete"
          type="secondary"
          onClick={() => {
            setIsDeleteConfirmation(false);
          }}
          disabled={disableButtons}
          danger={true}
        />
        <Button
          text="Confirm Delete"
          type="primary"
          onClick={() => {
            deleteCalendarRow(calendarRow.id);
          }}
          disabled={disableButtons}
          danger={true}
        />
      </div>
    );
  } else if (mode.isEdit) {
    footerButtons = (
      <div className={footerClassName}>
        <Button
          text="Delete Item"
          type="primary"
          onClick={() => {
            setIsDeleteConfirmation(true);
          }}
          icon={<TrashIcon />}
          disabled={disableButtons}
          danger={true}
        />
        <Popconfirm
          content="Are you sure you want to save these updates?"
          onCancel={() => {
            return;
          }}
          onConfirm={onSave}
        >
          <Button text="Save" type="primary" disabled={disableButtons} />
        </Popconfirm>
      </div>
    );
  } else {
    //mode.isAdd
    footerButtons = (
      <div className={footerClassName}>
        <Button
          text="Cancel"
          type="secondary"
          onClick={() => {
            navigate(pages.keyEvents.go());
          }}
          disabled={disableButtons}
        />
        <Popconfirm
          content="Are you sure you want to save these updates?"
          onCancel={() => {
            return;
          }}
          onConfirm={onSave}
        >
          <Button text="Save" type="primary" disabled={disableButtons} />
        </Popconfirm>
      </div>
    );
  }

  useEffect(() => {
    if (automatedMilestonesApi?.length === 0) {
      setIsAutomatedMilestonesEmpty(true);
    } else {
      setIsAutomatedMilestonesEmpty(false);
    }
  }, [automatedMilestonesApi]);

  return (
    <PaModal
      title={mode.isAdd ? "Add New Row" : `${mode.text} ${calendarRow.name}`}
      status={status}
      axiosErrors={error}
      isOpen={mhResult.enabled}
      onClose={onClose}
      className={namespace}
      footer={footerButtons}
    >
      <div className={`${namespace}-content`}>
        <Input
          label="Row Name"
          autoUpdater={{
            item: calendarRow,
            setItem: setCalendarRow,
            propExpression: ri => ri.name
          }}
          required
          validator={fieldUtils}
          placeholder="Input"
        />
        <Select
          label="Select an item"
          loading={productItemsStatus === "loading"}
          autoUpdater={{
            item: calendarRow,
            setItem: setCalendarRow,
            propExpression: ri => ri.calendarRowLinkId
          }}
          validator={fieldUtils}
          options={pipelineProducts}
          placeholder="Select"
          disabled={
            mode.isEdit ||
            (!!calendarRow.calendarRowLinkId &&
              automatedMilestonesStatus === "loading")
          }
        />
        {isAutomatedMilestonesEmpty && (
          <Alert content="No milestones to import." type="info" />
        )}
        <ToggleButton
          checked={calendarRow.isVisibleToOfficers}
          onClick={() =>
            setCalendarRow({
              ...calendarRow,
              isVisibleToOfficers: !calendarRow.isVisibleToOfficers
            })
          }
          secondLabel="Show for Limited Viewers"
        />
        <div>
          <Label>MILESTONES</Label>
          <Text>
            Create a milestone to track its progress. Milestones will be ordered
            by date.
          </Text>
        </div>
        {contextHolder}
        <Tabs
          items={[
            {
              key: "manual-row-milestone",
              label: "Manual",
              children: (
                <UpsertCalendarRowModalMilestoneGroup
                  addButton={true}
                  autoUpdater={{
                    item: calendarRow,
                    setItem: setCalendarRow,
                    propExpression: ri => ri.manualMilestones
                  }}
                />
              )
            },
            {
              key: "automated-row-milestone",
              label: "Automated",
              children: (
                <UpsertCalendarRowModalMilestoneGroup
                  addButton={false}
                  status={mode.isAdd ? automatedMilestonesStatus : "success"}
                  autoUpdater={{
                    item: calendarRow,
                    setItem: setCalendarRow,
                    propExpression: ri => ri.automatedMilestones
                  }}
                />
              ),
              disabled: !calendarRow?.calendarRowLinkId
            }
          ]}
        />
      </div>
    </PaModal>
  );
};

export default UpsertCalendarRowModal;
