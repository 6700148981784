//adapted from https://stackoverflow.com/questions/55531857/is-there-a-way-to-constraint-a-generic-type-to-plain-objects-only-in-typescript

export const nameOf = <T extends object>(expression: (instance: T) => void) => {
  const propNames: string[] = [];

  const proxy: T = new Proxy({} as T, {
    get: function (_, prop: string) {
      propNames.push(prop);

      return proxy;
    }
  });

  expression(proxy);

  return propNames;
};

export default nameOf;
