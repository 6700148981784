import { Dispatch, SetStateAction, useEffect, useState } from "react";

export type AccordionSyncKey =
  | "CommercialModal"
  | "TalentModal"
  | "AgendaItemModal";

type AccordionSyncReturn = {
  isOpen: boolean;
  onClick: () => void;
};

type AccordionSyncGroup = {
  openAccordionId: string;
  listeners: Map<string, Dispatch<SetStateAction<Record<string, unknown>>>>;
};

const accordionSyncGroups: Map<AccordionSyncKey, AccordionSyncGroup> =
  new Map();

type OnAccordionClick = (
  group: AccordionSyncGroup | undefined,
  accordionId: string
) => void;

const onAccordionClick: OnAccordionClick = (group, accordionId) => {
  if (!group) {
    return; //never undefined, but fixes TS complaints
  }

  if (accordionId === group.openAccordionId) {
    // eslint-disable-next-line no-param-reassign
    group.openAccordionId = "";
  } else {
    // eslint-disable-next-line no-param-reassign
    group.openAccordionId = accordionId;
  }

  group.listeners.forEach(refreshFunc => {
    refreshFunc({});
  });
};

export const useAccordionSync = (
  key: AccordionSyncKey | undefined,
  accordionId: string | undefined,
  startOpen: boolean | undefined //only first accordion set with this as true in a group starts open
): AccordionSyncReturn | undefined => {
  const [, forceRefresh] = useState({});

  //register forceRefresh for group
  useEffect(() => {
    if (key === undefined || accordionId === undefined) {
      return;
    }

    const group = accordionSyncGroups.get(key);
    if (!group) {
      return;
    }

    group.listeners.set(accordionId, forceRefresh);

    return () => {
      group.listeners.delete(accordionId);
    };
  }, [accordionId, key, forceRefresh]);

  //return undefined items if not using it (AccordionWithStatus always calls this hook, but not always with params)
  if (key === undefined || accordionId === undefined) {
    if (key !== undefined && accordionId === undefined) {
      // eslint-disable-next-line no-console
      console.error(
        "Attempting to use useAccordionSync without an accordionId. Validate you're passing in className with accordionSyncKey if using AccordionWithStatus"
      );
    }

    return;
  }

  //register group, if needed
  let accordionGroup = accordionSyncGroups.get(key);
  if (accordionGroup === undefined) {
    accordionGroup = {
      openAccordionId: "",
      listeners: new Map()
    };

    accordionSyncGroups.set(key, accordionGroup);
  }

  if (!accordionGroup.openAccordionId && startOpen === true) {
    accordionGroup.openAccordionId = accordionId;
  }

  return {
    isOpen: accordionId === accordionGroup.openAccordionId,
    onClick: () => {
      onAccordionClick(accordionGroup, accordionId);
    }
  };
};

export const resetAccordionsSync = (key: AccordionSyncKey) => {
  onAccordionClick(accordionSyncGroups.get(key), "");
};
