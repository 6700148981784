import React, { useContext, useEffect, useMemo, useState } from "react";
import "./UpsertCalendarModal.scss";
import {
  getCalendar,
  postCalendar,
  putCalendar,
  deleteCalendar as deleteCalendarApi,
  Calendar,
  AdEntity,
  getAuthorization,
  EntityListItem
} from "api";
import PaModal from "common-components/pa-modal/PaModal";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Button } from "@opsdti-global-component-library/amgen-design-system";
import { useValidator } from "global/use-validator";
import { useStatusManager } from "global/use-status-manager";
import TrashIcon from "icons/trash-icon";
import { useNavigate } from "react-router-dom";
import { useModalHelper } from "global/use-modal-helper";
import pages from "pages/pages";
import { PermissionsContext } from "global/permissions";
import { Input, DatePicker, Select, Checkbox } from "common-components";
import { useGetAllAdEntities } from "global/use-get-all-ad-entities";
import { getKeyEventsId } from "utils/multitenancy";
import dayjs from "dayjs";
import produce from "immer";
import { useOktaUser } from "global/use-okta-user";
import { tooltips } from "./upsertCalendarModalTooltips";

type CalendarModel = {
  id: number;
  title: string;
  publishedDate: string;
  owner: string;
  isHiddenFromSuperAdmin: boolean;
  admins: string[];
  fullViewers: string[];
  limitedViewers: string[];
};

const initialCalendar: CalendarModel = {
  id: 0,
  title: "",
  publishedDate: dayjs().toISOString(),
  owner: "",
  isHiddenFromSuperAdmin: false,
  admins: [],
  fullViewers: [],
  limitedViewers: []
};

type AddMode = {
  text: "Add New";
  isAdd: true;
  isEdit: false;
};

type EditMode = {
  text: "Edit";
  isAdd: false;
  isEdit: true;
};

type ModalMode = AddMode | EditMode;

const namespace = "rts-pa-upsert-calendar-modal";

const UpsertCalendarModal = () => {
  const perms = useContext(PermissionsContext);
  const navigate = useNavigate();
  const mhResult = useModalHelper(
    [
      pages.keyEvents.addCalendarModal.path,
      pages.keyEvents.editCalendarModal.path
    ],
    [perms.keyEvents.create, perms.keyEvents.edit]
  );
  const currentUser = useOktaUser();
  const [calendar, setCalendar] = useState<CalendarModel>(initialCalendar);

  const mode: ModalMode = useMemo(() => {
    if (mhResult.path === pages.keyEvents.addCalendarModal.path) {
      return {
        text: "Add New",
        isAdd: true,
        isEdit: false
      };
    } else {
      return {
        text: "Edit",
        isAdd: false,
        isEdit: true
      };
    }
  }, [mhResult]);

  const [isDeleteConfirmation, setIsDeleteConfirmation] = useState(false);
  const { containerUtils, fieldUtils } = useValidator();

  const { refetch: refreshPermsList } = useQuery(
    ["authorization"],
    getAuthorization,
    {
      enabled: false
    }
  );

  const adEntities = useGetAllAdEntities();

  const {
    data,
    status: apiCalendarStatus,
    refetch: refreshApiCalendar,
    error
  } = useQuery(["calendar", getKeyEventsId()], getCalendar, {
    enabled: mhResult.enabled && mode.isEdit
  });

  const { status: addStatus, mutate: addCalendar } = useMutation(postCalendar, {
    onMutate: () => {
      overrideStatus("addCalendar", "loading");
    },
    onSuccess: () => {
      refreshPermsList().then(query => {
        const newCalendar: EntityListItem | undefined =
          query.data?.ke.calendarList.find(a => a.title === calendar?.title);

        if (newCalendar) {
          navigate(pages.keyEvents.go(newCalendar.id.toString()));
        } else {
          navigate(pages.keyEvents.go());
        }

        setTimeout(() => {
          //keep spinner going when closing modal
          overrideStatus("addCalendar", undefined);
        }, 500);
      });
    },
    onError: () => {
      overrideStatus("addCalendar", undefined);
    }
  });
  const { status: updateStatus, mutate: updateCalendar } = useMutation(
    putCalendar,
    {
      onMutate: () => {
        overrideStatus("putCalendar", "loading");
      },
      onSuccess: () => {
        Promise.all([refreshPermsList(), refreshApiCalendar()]).then(() => {
          if (getKeyEventsId() !== "0") {
            // 0 when redirected by permissions to pa-grid or unauthorized on removing self from calendar
            navigate(pages.keyEvents.go());
          }

          setTimeout(() => {
            //keep spinner going when closing modal -- started on update
            overrideStatus("putCalendar", undefined);
          }, 500);
        });
      },
      onError: () => {
        overrideStatus("putCalendar", undefined);
      }
    }
  );
  const { status: deleteStatus, mutate: deleteCalendar } = useMutation(
    deleteCalendarApi,
    {
      onMutate: () => {
        overrideStatus("deleteCalendar", "loading");
      },
      onSuccess: () => {
        refreshPermsList().then(() => {
          if (getKeyEventsId() !== "0") {
            // 0 when deleting last calendar for self, and redirected by permissions to pa-grid or unauthorized page
            navigate(pages.keyEvents.go());
          }

          setTimeout(() => {
            //keep spinner going when closing modal
            overrideStatus("deleteCalendar", undefined);
            setIsDeleteConfirmation(false);
          }, 500);
        });
      },
      onError: () => {
        overrideStatus("deleteCalendar", undefined);
      }
    }
  );

  const { status, overrideStatus } = useStatusManager(
    mode.isEdit && mhResult.enabled ? apiCalendarStatus : "idle",
    addStatus,
    updateStatus,
    deleteStatus
  );

  //reset modal on close
  useEffect(() => {
    if (mhResult.enabled && mode.isEdit && data) {
      const mapToStrings = (adEntities: AdEntity[]): string[] =>
        adEntities.map(a => a.username);

      const owner =
        adEntities.users.find(u => u.username === data.owner.username)
          ?.displayName || "";

      const calendar: CalendarModel = {
        id: data.id,
        title: data.title,
        publishedDate: data.publishedDate,
        owner: owner,
        isHiddenFromSuperAdmin: data.isHiddenFromSuperAdmin,
        admins: mapToStrings(data.admins),
        fullViewers: mapToStrings(data.fullViewers),
        limitedViewers: mapToStrings(data.limitedViewers)
      };

      setCalendar(calendar);
    } else if (mhResult.enabled && mode.isAdd) {
      const newCalendar = produce(calendar, draftState => {
        const owner =
          adEntities.users.find(u => u.email === currentUser?.email)
            ?.displayName || "";

        draftState.owner = owner;
      });

      setCalendar(newCalendar);
    } else if (!mhResult.enabled) {
      setTimeout(() => {
        containerUtils.resetValidation();
        setIsDeleteConfirmation(false);
        setCalendar(initialCalendar);
      }, 500);
    }
  }, [mhResult.enabled, data, adEntities, currentUser]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSave = () => {
    containerUtils.triggerValidation().then(({ isValid }) => {
      if (!isValid) {
        return;
      }

      const mapToAdEntities = (opts: string[]): AdEntity[] => {
        let newAdEntities = opts.map(optVal => {
          let adEntity: AdEntity | undefined = adEntities.users.find(
            u => u.username === optVal
          );

          if (!adEntity) {
            adEntity = adEntities.groups.find(u => u.username === optVal);
          }

          // should never happen
          if (!adEntity) {
            return undefined as unknown as AdEntity;
          }

          return adEntity;
        });

        //remove undefined items
        newAdEntities = newAdEntities.filter(e => !!e);

        return newAdEntities;
      };

      const apiCalendar: Calendar = {
        id: calendar.id,
        title: calendar.title,
        publishedDate: dayjs
          .tz(dayjs(calendar.publishedDate).format("YYYY-MM-DD"))
          .toISOString(),
        owner: {} as AdEntity,
        isHiddenFromSuperAdmin: calendar.isHiddenFromSuperAdmin,
        admins: mapToAdEntities(calendar.admins),
        fullViewers: mapToAdEntities(calendar.fullViewers),
        limitedViewers: mapToAdEntities(calendar.limitedViewers)
      };

      if (mode.isAdd) {
        addCalendar(apiCalendar);
      } else if (mode.isEdit) {
        updateCalendar(apiCalendar);
      }
    });
  };

  const isSaving = status === "loading";

  const onClose = () => {
    if (isSaving) {
      return;
    }

    navigate(pages.keyEvents.go());
  };

  let footerButtons: JSX.Element;
  const footerClassName = `${namespace}-footer`;
  if (isDeleteConfirmation) {
    footerButtons = (
      <div className={footerClassName}>
        <Button
          text="Cancel Delete"
          type="secondary"
          onClick={() => {
            setIsDeleteConfirmation(false);
          }}
          className={`${namespace}-delete-button`}
          disabled={status === "loading"}
          danger={true}
        />
        <Button
          text="Confirm Delete"
          type="primary"
          onClick={() => {
            deleteCalendar();
          }}
          className={`${namespace}-delete-button`}
          disabled={status === "loading"}
          danger={true}
        />
      </div>
    );
  } else if (mode.isEdit) {
    footerButtons = (
      <div className={footerClassName}>
        <Button
          text="Delete Calendar"
          type="primary"
          onClick={() => {
            setIsDeleteConfirmation(true);
          }}
          icon={<TrashIcon />}
          className={`${namespace}-delete-button`}
          disabled={isSaving}
          danger={true}
        />
        <Button
          text="Publish Updates"
          type="primary"
          onClick={onSave}
          disabled={isSaving}
        />
      </div>
    );
  } else {
    //mode.isAdd
    footerButtons = (
      <div className={footerClassName}>
        <Button
          text="Cancel"
          type="secondary"
          onClick={() => {
            navigate(pages.keyEvents.go());
          }}
          disabled={isSaving}
        />
        <Button
          text="Save"
          type="primary"
          onClick={onSave}
          disabled={isSaving}
        />
      </div>
    );
  }

  return (
    <PaModal
      title={`${mode.text} ${calendar.title} Forward 12 Quarters`}
      status={status}
      axiosErrors={error}
      isOpen={mhResult.enabled}
      onClose={onClose}
      disableBackdropDismiss={true}
      className={namespace}
      footer={footerButtons}
    >
      <div className={`${namespace}-content`}>
        <Input
          label="Title"
          placeholder="Input"
          required
          autoUpdater={{
            item: calendar,
            setItem: setCalendar,
            propExpression: x => x.title
          }}
          validator={fieldUtils}
          tooltip={tooltips.title}
        />
        <DatePicker
          label='"As Of" Date'
          placeholder="Select date"
          required
          autoUpdater={{
            item: calendar,
            setItem: setCalendar,
            propExpression: x => x.publishedDate
          }}
          tooltip={tooltips.publishedDate}
        />
        <div className={`${namespace}-one-line-container`}>
          <Input
            label="Owner"
            disabled={true}
            autoUpdater={{
              item: calendar,
              setItem: setCalendar,
              propExpression: x => x.owner
            }}
            tooltip={tooltips.owner}
          />
          <Checkbox
            label="Hide from Super Admins?"
            autoUpdater={{
              item: calendar,
              setItem: setCalendar,
              propExpression: x => x.isHiddenFromSuperAdmin
            }}
          />
        </div>
        <Select
          label="Admin Users & Groups"
          options={adEntities.options}
          autoUpdater={{
            item: calendar,
            setItem: setCalendar,
            propExpression: x => x.admins
          }}
          mode="multiple"
          tooltip={tooltips.admins}
          placeholder="Select"
        />
        <Select
          label="Full View Users & Groups"
          options={adEntities.options}
          autoUpdater={{
            item: calendar,
            setItem: setCalendar,
            propExpression: x => x.fullViewers
          }}
          mode="multiple"
          tooltip={tooltips.fullViewers}
          placeholder="Select"
        />
        <Select
          label="Limited View Users & Groups"
          options={adEntities.options}
          autoUpdater={{
            item: calendar,
            setItem: setCalendar,
            propExpression: x => x.limitedViewers
          }}
          mode="multiple"
          tooltip={tooltips.limitedViewers}
          placeholder="Select"
        />
      </div>
    </PaModal>
  );
};

export default UpsertCalendarModal;
