import React from "react";
import "./TalentDinbModalTalentMetrics.scss";
import ModalSection from "common-components/modal-section/ModalSection";
import { TalentDinbTalentSection } from "api/talent-dinb/talent-dinb-talent";
import TalentDinbModalTalentAmgenOverallEngagement from "./amgen-overall-engagement/TalentDinbModalTalentAmgenOverallEngagement";
import TalentDinbModalTalentAmgenTotalTurnover from "./amgen-total-turnover/TalentDinbModalTalentAmgenTotalTurnover";

type Props = {
  data: TalentDinbTalentSection | undefined | null;
};

const TalentDinbModalTalentMetrics = (props: Props) => {
  const namespace = "rts-pa-talent-dinb-modal-talent-metrics";
  if (!props.data) {
    return <></>;
  }

  return (
    <ModalSection title="Talent Metrics" className={namespace}>
      <div className="rts-pa-talent-dinb-modal-talent-metrics-container">
        <TalentDinbModalTalentAmgenOverallEngagement
          data={props.data.amgenOverallEngagement}
        />
        <TalentDinbModalTalentAmgenTotalTurnover
          data={props.data.amgenTotalTurnover}
        />
      </div>
    </ModalSection>
  );
};

export default TalentDinbModalTalentMetrics;
