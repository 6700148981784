import React from "react";
import "./ToggleButton.scss";
import { Label } from "common-components";
import { Switch } from "@opsdti-global-component-library/amgen-design-system";
import classNames from "classnames";

type Props = {
  firstLabel?: string;
  secondLabel?: string;
  checked: boolean | undefined;
  onClick: React.Dispatch<React.SetStateAction<boolean>>;
  className?: string;
};

const namespace = "rts-pa-toggle-button";

export const ToggleButton = (props: Props) => {
  const className = classNames(namespace, props.className);

  return (
    <span className={className}>
      <Label colorVariant={!props.checked ? "selected" : "tertiary"}>
        {props.firstLabel}
      </Label>
      <Switch checked={props.checked} onChange={props.onClick} />
      <Label colorVariant={props.checked ? "selected" : "tertiary"}>
        {props.secondLabel}
      </Label>
    </span>
  );
};

export default ToggleButton;
