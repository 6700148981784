import { Record, Static } from "runtypes";
import { TotalCarbonWasteWaterReductionOffsetValidator } from "./total-carbon-waste-water-reduction-offset";
import { InactiveSectionValidator } from "api/common";

export const EnvironmentalSustainabilityValidator = Record({
  totalCarbonWasteWaterReductionOffset:
    TotalCarbonWasteWaterReductionOffsetValidator.Or(InactiveSectionValidator)
});

export type EnvironmentalSustainability = Static<
  typeof EnvironmentalSustainabilityValidator
>;
