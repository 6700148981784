import React from "react";
import "./EmptyContainer.scss";
import { Empty } from "@opsdti-global-component-library/amgen-design-system";
import { Loader } from "@gitlab-rtsensing/component-library";
import { QueryStatus } from "@tanstack/react-query";

export const namespace = "rts-pa-empty-container";

type Props = {
  status: QueryStatus;
  description?: string;
};

// TODO JCG: implement in KeyEventsCalendarEmptyContainer for consistency

export default function EmptyContainer(props: Props): JSX.Element {
  return (
    <div className={namespace}>
      {props.status === "loading" ? (
        <Loader />
      ) : (
        <Empty description={props.description || "No Data"} />
      )}
    </div>
  );
}
