import { Array, Record, Static } from "runtypes";
import { ActiveDirectoryGroupValidator } from "./active-directory-common";

export const ActiveDirectoryUserGroupsValidator = Record({
  groups: Array(ActiveDirectoryGroupValidator)
});

export type ActiveDirectoryUserGroups = Static<
  typeof ActiveDirectoryUserGroupsValidator
>;
