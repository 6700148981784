import React from "react";
import "./AgendaItemBuilderModalSectionBase.scss";
import { AgendaItemSectionType } from "api/agenda-item/agenda-item-common";
import { SelectSimple, Input, GenericOption } from "common-components";
import produce from "immer";
import { SectionBasicProps } from "./AgendaItemBuilderModalSection";
import { AgendaItemSectionPlaceholder } from "api/agenda-item/sections";

const namespace = "rts-pa-agenda-item-builder-modal-section-base";

type Props = SectionBasicProps;

const sectionTypes: GenericOption<AgendaItemSectionType>[] = [
  {
    label: "Engagement Score",
    value: "engagementScore"
  },
  {
    label: "Inventory Below Target",
    value: "inventoryBelowTarget"
  },
  {
    label: "Location",
    value: "location"
  },
  {
    label: "Pipeline Project",
    value: "pipelineProject"
  },
  {
    label: "Placeholder",
    value: "placeholder"
  },
  {
    label: "Sales / Demand",
    value: "salesDemand"
  },
  {
    label: "Single Metric",
    value: "singleMetric"
  },
  {
    label: "Talent Acquisition",
    value: "talentAcquisition"
  },
  {
    label: "Turnover",
    value: "turnover"
  }
];

export const AgendaItemBuilderModalSectionBase = (props: Props) => {
  const onTypeChange = (newType: string) => {
    //clear out residual values on type swap
    const newTemplate = produce(props.agendaItemTemplate, draftState => {
      const currentSection =
        draftState.groups[props.groupIndex].sections[props.index];

      draftState.groups[props.groupIndex].sections[props.index] = {
        id: currentSection.id,
        title: currentSection.title,
        type: newType as AgendaItemSectionType
      } as AgendaItemSectionPlaceholder;
    });

    props.setAgendaItemTemplate(newTemplate);
  };

  return (
    <div className={namespace}>
      <Input
        label="Display Name"
        autoUpdater={{
          item: props.agendaItemTemplate,
          setItem: props.setAgendaItemTemplate,
          propExpression: x =>
            x.groups[props.groupIndex].sections[props.index].title
        }}
        required
        validator={props.fieldUtils}
      />
      {/* TODO JCG: need to add validator to Dropdown */}
      <SelectSimple
        label="Type"
        options={sectionTypes}
        value={props.section.type}
        onChange={onTypeChange}
        placeholder="Select Type"
      />
      {/* <Select
        label="Type"
        autoUpdater={{
          item: props.agendaItemTemplate,
          setItem: props.setAgendaItemTemplate,
          propExpression: x =>
            x.groups[props.groupIndex].sections[props.index].type
        }}
        required
        validator={props.fieldUtils}
        data={sectionTypes}
        placeholder="Select Type"
      /> */}
    </div>
  );
};

export default AgendaItemBuilderModalSectionBase;
