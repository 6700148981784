import React from "react";
import "./TalentDinbModalTalentAmgenTotalTurnover.scss";
import { AdvancedTooltip } from "common-components/advanced-tooltip/AdvancedTooltip";
import { colors } from "utils";
import { formatPercentage } from "utils/format";
import getAdvancedTooltipContent from "common-components/advanced-tooltip/getAdvancedTooltipContent";
import { Label } from "common-components";
import { TalentDinbTalentAmgenTotalTurnover } from "api/talent-dinb/talent-dinb-talent";
import { AccordionWithStatus } from "common-components/accordion-with-status/AccordionWithStatus";
import { LineChart } from "@opsdti-global-component-library/amgen-design-system";
import { InactiveSection } from "api";

type Props = {
  data: TalentDinbTalentAmgenTotalTurnover | InactiveSection | undefined | null;
};

const TalentDinbModalTalentAmgenTotalTurnover = (props: Props) => {
  const namespace = "rts-pa-talent-dinb-modal-talent-amgen-total-turnover";
  const title = "Amgen Total Turnover";

  if (!props.data) {
    return <></>;
  } else if (props.data.status === "inactive") {
    return <AccordionWithStatus title={props.data.title} status="gray" />;
  }

  const chartData = props.data.chart.map(item => ({
    label: item.label || undefined,
    forecast: item.forecast !== null ? item.forecast : undefined,
    value: item.value !== null ? item.value : undefined
  }));

  const customTicks =
    chartData.length > 8
      ? chartData.map((value, index) =>
          index % 2 === 1 ? value.label || "" : ""
        )
      : chartData.map(value => value.label || "");

  return (
    <AccordionWithStatus
      status={props.data.status}
      title={title}
      tooltipContent={getAdvancedTooltipContent(props.data.tooltip)}
      className={namespace}
      accordionSyncKey="TalentModal"
      links={props.data.links}
    >
      {props.data.chartTooltip && (
        <div className={`${namespace}-header`}>
          <Label>12 Month Rolling Average</Label>
          <AdvancedTooltip tooltip={props.data.chartTooltip} />
        </div>
      )}
      <div className={`${namespace}-metrics`}>
        <LineChart
          data={chartData}
          xKey="label"
          dataKeys={[
            {
              lineKey: "value",
              dot: true,
              color: colors.primaryBlue,
              displayName: "AMGEN"
            },
            {
              lineKey: "forecast",
              dot: false,
              color: colors.pinkAccent,
              displayName: "LIFE SCIENCES BENCHMARK"
            }
          ]}
          customXTicks={customTicks}
          xAxisPadding={{ left: 0, right: 7 }}
          yAxisTickFormatter={(
            value: string | number | Array<string | number>
          ) => {
            if (Array.isArray(value)) {
              return "";
            }

            return formatPercentage(value);
          }}
          chartHeight={200}
          showPopover
          showLegend
          yAxisWidth={25}
        />
      </div>
    </AccordionWithStatus>
  );
};

export default TalentDinbModalTalentAmgenTotalTurnover;
