import classNames from "classnames";
import React from "react";
import "./TooltipContent.scss";
import { Label, Text } from "common-components";

type Props = {
  className?: string;
  children: JSX.Element | JSX.Element[] | string | string[];
};

const namespace = "rts-pa-tooltip-content";

export const TooltipContent = (props: Props) => {
  const className = classNames(namespace, props.className);

  return <div className={className}>{props.children}</div>;
};

export default TooltipContent;

const TooltipContentBold = (props: {
  children: JSX.Element | JSX.Element[] | string | number | null;
  className?: string;
}) => {
  return <Text strong>{props.children}</Text>;
};
TooltipContent.Bold = TooltipContentBold;

const TooltipContentGray = (props: {
  children: JSX.Element | JSX.Element[] | string | number | null;
  className?: string;
}) => {
  return <Label>{props.children}</Label>;
};
TooltipContent.Gray = TooltipContentGray;
