import React from "react";
import { KeyEventsIconProps } from "./common-props";

export const OctagonIcon = (props: KeyEventsIconProps) => {
  const { width = 14, height = 14, className, fill = "black" } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M7 0L11.9497 2.05025L14 7L11.9497 11.9497L7 14L2.05025 11.9497L0 7L2.05025 2.05025L7 0Z"
        fill={fill}
      />
    </svg>
  );
};

export default OctagonIcon;
