import { Literal, Static } from "runtypes";
import { AgendaItemTemplateSectionValidator } from "../agenda-item-template-common";

export const AgendaItemTemplateSectionPlaceholderValidator =
  AgendaItemTemplateSectionValidator.extend({
    type: Literal("placeholder")
  });

export type AgendaItemTemplateSectionPlaceholder = Static<
  typeof AgendaItemTemplateSectionPlaceholderValidator
>;
