import React, { useEffect, useState } from "react";
import "./ChangeLogTable.scss";
import ChangeLogTableFilters, {
  Filters
} from "./filters/ChangeLogTableFilters";
import Table, { TableColumnsType } from "common-components/table/Table";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import {
  ChangeLogItem,
  GetChangeLogResponse,
  getChangeLog
} from "api/change-log";
import { Text } from "common-components";

const namespace = "rts-pa-change-log-table";

const initialFilters: Filters = {
  dateRange: [],
  agendaItemIds: [],
  fieldNames: []
};

const columns: TableColumnsType<ChangeLogItem> = [
  {
    title: "Date and Time",
    dataIndex: "createdDate",
    className: `${namespace}-date-requested`,
    render: (value: string) => {
      const createdDate = dayjs.utc(value).tz();

      return createdDate.format("MMM D, YYYY h:mm A");
    },
    width: "175px"
  },
  {
    title: "Editor",
    dataIndex: ["createdBy", "fullName"],
    width: "125px"
  },
  {
    title: "Changes",
    dataIndex: "id",
    render: (_, item) => {
      return (
        <>
          <Text strong>{item.agendaItemName}: </Text>
          <Text>
            {item.fieldName} changed from [{item.oldValue}] to [{item.newValue}]
          </Text>
        </>
      );
    },
    sorter: false
  }
];

export const ChangeLogTable = () => {
  const [filters, setFilters] = useState<Filters>(initialFilters);
  // const [pageInfo, setPageInfo] = useState<PageInfo>(defaultPageInfo);
  // const [sortInfo, setSortInfo] =
  //   useState<SortInfo<ChangeLogItem>>(defaultSortInfo);

  const { data } = useQuery<GetChangeLogResponse>(
    ["change-log", filters], //, pageInfo, sortInfo],
    getChangeLog
    // () =>
    //   getChangeLog({
    //     filters: {
    //       startDate:
    //         (filters.dateRange[0] && filters.dateRange[0].toString()) || "",
    //       endDate:
    //         (filters.dateRange[1] && filters.dateRange[1].toString()) || "",
    //       agendaItemIds: filters.agendaItemIds,
    //       fieldNames: filters.fieldNames
    //     },
    //     pageInfo: pageInfo,
    //     sortInfo: {
    //       property: sortInfo.property || "",
    //       direction: sortInfo.direction
    //     }
    //   })
  );

  useEffect(() => {
    if (!data) {
      return;
    }

    setFilters({
      dateRange: [dayjs(data.filters.startDate), dayjs(data.filters.endDate)], //TODO JCG: figure out if dates need to be timezoned
      agendaItemIds: data.filters.agendaItemIds,
      fieldNames: data.filters.fieldNames
    });
    // setPageInfo(data.pageInfo);
    // setSortInfo({
    //   ...data.sortInfo,
    //   property: data.sortInfo.property as keyof ChangeLogItem
    // });
  }, [data]);

  // const serverSideControl: ServerSideControl<ChangeLogItem> = useMemo(() => {
  //   return {
  //     page: {
  //       pageInfo: pageInfo,
  //       onPageChange: setPageInfo
  //     },
  //     sort: {
  //       sortInfo: sortInfo,
  //       onSortChange: setSortInfo
  //     }
  //   };
  // }, [pageInfo, sortInfo]);

  return (
    <div className={namespace}>
      <ChangeLogTableFilters filters={filters} onChange={setFilters} />
      <Table columns={columns} dataSource={data?.data} />
    </div>
  );
};

export default ChangeLogTable;
