import React, { useEffect } from "react";
import "./AIBuilderModalSectionSalesDemand.scss";
import { Select, GenericOption } from "common-components";
import produce from "immer";
import {
  AgendaItemTemplateSectionSalesDemand,
  AgendaItemTemplateSectionSalesDemandScope,
  AgendaItemTemplateSectionSalesDemandSubtype
} from "api/agenda-item-template/sections";
import { SectionBasicProps } from "../../AgendaItemBuilderModalSection";

const namespace = "rts-pa-agenda-item-builder-modal-section-sales-demand";

type Props = SectionBasicProps;

const subtypes: GenericOption<AgendaItemTemplateSectionSalesDemandSubtype>[] = [
  {
    label: "Net Sales",
    value: "netSales"
  },
  {
    label: "NBRx Demand",
    value: "nbrxDemand"
  },
  {
    label: "TRx Demand",
    value: "trxDemand"
  }
];

const scopes: GenericOption<AgendaItemTemplateSectionSalesDemandScope>[] = [
  {
    label: "US",
    value: "us"
  }
];

export const AIBuilderModalSectionSalesDemand = (props: Props) => {
  //set defaults (for one-item dropdowns)
  useEffect(() => {
    let hasChanges = false;
    const newTemplate = produce(props.agendaItemTemplate, draftState => {
      const section = draftState.groups[props.groupIndex].sections[
        props.index
      ] as AgendaItemTemplateSectionSalesDemand;

      if (!section.scope) {
        section.scope = "us";
        hasChanges = true;
      }
    });

    if (hasChanges) {
      props.setAgendaItemTemplate(newTemplate);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const section = props.section as AgendaItemTemplateSectionSalesDemand;

  //update default name
  useEffect(() => {
    if (
      !section.title ||
      (scopes.find(item => section.title.startsWith(item.label)) &&
        subtypes.find(item => section.title.endsWith(item.label)))
    ) {
      const scope = scopes.find(item => item.value === section.scope);
      const subtype = subtypes.find(item => item.value === section.subtype);

      if (scope && subtype) {
        props.updateTitle(`${scope.label} ${subtype.label}`);
      }
    }
  }, [section.scope, section.subtype]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={namespace}>
      <Select
        label="Subtype"
        autoUpdater={{
          item: props.agendaItemTemplate,
          setItem: props.setAgendaItemTemplate,
          propExpression: x =>
            (
              x.groups[props.groupIndex].sections[
                props.index
              ] as AgendaItemTemplateSectionSalesDemand
            ).subtype
        }}
        required
        validator={props.fieldUtils}
        options={subtypes}
        placeholder="Select Subtype"
      />
      <Select
        label="Data Scope"
        autoUpdater={{
          item: props.agendaItemTemplate,
          setItem: props.setAgendaItemTemplate,
          propExpression: x =>
            (
              x.groups[props.groupIndex].sections[
                props.index
              ] as AgendaItemTemplateSectionSalesDemand
            ).scope
        }}
        required
        validator={props.fieldUtils}
        options={scopes}
        placeholder="Select Data Scope"
      />
    </div>
  );
};

export default AIBuilderModalSectionSalesDemand;
