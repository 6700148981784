import { Container } from "@gitlab-rtsensing/component-library";
import React from "react";

type ErrorProps = {
  error: Error;
};

export function ErrorFallback(props: ErrorProps) {
  const { error } = props;
  return (
    <Container>
      <div role="alert">
        <p>Error</p>
        <pre>{error.message}</pre>
        <pre>{error.stack}</pre>
      </div>
    </Container>
  );
}

export default ErrorFallback;
