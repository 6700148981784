import { Record, Static } from "runtypes";
import { BiosimilarsPipelinePlannedMarketReadinessSectionValidator } from "./biosimilars-planned-market-readiness-section";
import { InactiveSectionValidator } from "api/common";

export const BiosimilarsPipelineGroupValidator = Record({
  plannedMarketReadiness:
    BiosimilarsPipelinePlannedMarketReadinessSectionValidator.Or(InactiveSectionValidator)
});

export type BiosimilarsPipelineGroup = Static<
  typeof BiosimilarsPipelineGroupValidator
>;
