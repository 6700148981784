import React from "react";
import {
  AgendaItemTemplateSectionSingleMetric,
  AgendaItemTemplateSectionSalesDemand,
  AgendaItemTemplateSectionPipelineProject,
  AgendaItemTemplateSectionLocation,
  AgendaItemTemplateSectionInventoryBelowTarget,
  AgendaItemTemplateSectionEngagementScore,
  AgendaItemTemplateSectionTalentAcquisition,
  AgendaItemTemplateSectionTurnover,
  AgendaItemTemplateSectionPlaceholder
} from "api/agenda-item-template/sections";
import { SectionBasicProps } from "./AgendaItemBuilderModalSection";
import {
  AIBuilderModalSectionSingleMetric,
  AIBuilderModalSectionSalesDemand,
  AIBuilderModalSectionPipelineProject,
  AIBuilderModalSectionLocation,
  AIBuilderModalSectionInventoryBelowTarget,
  AIBuilderModalSectionEngagementScore,
  AIBuilderModalSectionTalentAcquisition,
  AIBuilderModalSectionTurnover,
  AIBuilderModalSectionPlaceholder
} from "./implementations";

type Props = SectionBasicProps;

export const AgendaItemBuilderModalSectionMapper = (props: Props) => {
  switch (props.section.type) {
    case "placeholder":
      return (
        <AIBuilderModalSectionPlaceholder
          {...props}
          section={props.section as AgendaItemTemplateSectionPlaceholder}
        />
      );
    case "singleMetric":
      return (
        <AIBuilderModalSectionSingleMetric
          {...props}
          section={props.section as AgendaItemTemplateSectionSingleMetric}
        />
      );
    case "salesDemand":
      return (
        <AIBuilderModalSectionSalesDemand
          {...props}
          section={props.section as AgendaItemTemplateSectionSalesDemand}
        />
      );
    case "pipelineProject":
      return (
        <AIBuilderModalSectionPipelineProject
          {...props}
          section={props.section as AgendaItemTemplateSectionPipelineProject}
        />
      );
    case "location":
      return (
        <AIBuilderModalSectionLocation
          {...props}
          section={props.section as AgendaItemTemplateSectionLocation}
        />
      );
    case "inventoryBelowTarget":
      return (
        <AIBuilderModalSectionInventoryBelowTarget
          {...props}
          section={
            props.section as AgendaItemTemplateSectionInventoryBelowTarget
          }
        />
      );
    case "engagementScore":
      return (
        <AIBuilderModalSectionEngagementScore
          {...props}
          section={props.section as AgendaItemTemplateSectionEngagementScore}
        />
      );
    case "talentAcquisition":
      return (
        <AIBuilderModalSectionTalentAcquisition
          {...props}
          section={props.section as AgendaItemTemplateSectionTalentAcquisition}
        />
      );
    case "turnover":
      return (
        <AIBuilderModalSectionTurnover
          {...props}
          section={props.section as AgendaItemTemplateSectionTurnover}
        />
      );
    default:
      return <></>;
  }
};

export default AgendaItemBuilderModalSectionMapper;
