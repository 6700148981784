import { StatusValidator, LinkValidator, InactiveSectionValidator } from "api/common";
import { Array, Boolean, Number, Record, Static, String } from "runtypes";

const TalentDinbLaborProductivityRevenuePerLnbChartValidator = Record({
  plan: Number.nullable(),
  label: String,
  actuals: Number.nullable(),
  forecast: Number.nullable()
});

export type TalentDinbLaborProductivityRevenuePerLnbChart = Static<
  typeof TalentDinbLaborProductivityRevenuePerLnbChartValidator
>;

const TalentDinbLaborProductivityRevenuePerLnbValidator = Record({
  chart: Array(TalentDinbLaborProductivityRevenuePerLnbChartValidator),
  planKey: String,
  actualsKey: String,
  forecastKey: String,
  forecastYear: String,
  forecastMonth: String, //e.g. "June"
  status: StatusValidator,
  title: String,
  links: Array(LinkValidator),
  statusDriver: Boolean
});

export type TalentDinbLaborProductivityRevenuePerLnb = Static<
  typeof TalentDinbLaborProductivityRevenuePerLnbValidator
>;

export const TalentDinbLaborProductivitySectionValidator = Record({
  revenuePerLnb: TalentDinbLaborProductivityRevenuePerLnbValidator.Or(InactiveSectionValidator)
});

export type TalentDinbLaborProductivitySection = Static<
  typeof TalentDinbLaborProductivitySectionValidator
>;
