import {
  AdvancedTooltipValidator,
  StatusValidator,
  StatusWithAcceleratedValidator
} from "api/common";
import { Array, Record, Static, String } from "runtypes";

const BiosimilarsPipelinePlannedMarketReadinessProject = Record({
  id: String,
  name: String,
  status: StatusWithAcceleratedValidator,
  statusTooltip: AdvancedTooltipValidator,
  readinessDate: String,
  region: String
});

export const BiosimilarsPipelinePlannedMarketReadinessSectionValidator = Record(
  {
    status: StatusValidator,
    tooltip: AdvancedTooltipValidator,
    projects: Array(BiosimilarsPipelinePlannedMarketReadinessProject)
  }
);

export type BiosimilarsPipelinePlannedMarketReadinessSection = Static<
  typeof BiosimilarsPipelinePlannedMarketReadinessSectionValidator
>;
