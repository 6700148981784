import { Number, Record, Static, Array, String } from "runtypes";
import { TalentDinbTalentSectionValidator } from "./talent-dinb/talent-dinb-talent";
import { TalentDinbDiversityInclusionBelongingSectionValidator } from "./talent-dinb/talent-dinb-diversity-inclusion-belonging";
import { TalentDinbLaborProductivitySectionValidator } from "./talent-dinb/talent-dinb-labor-productivity";
import {
  ArchetypeValidator,
  LinkValidator,
  OwnersValidator,
  SummaryInfoValidator
} from "./common";

export const TalentDinbValidator = Record({
  agendaItemId: Number,
  archetype: ArchetypeValidator,
  triggers: Array(String),
  statusDrivers: Array(String),
  summaryInfo: SummaryInfoValidator,
  owners: OwnersValidator,
  talent: TalentDinbTalentSectionValidator.nullable(),
  diversityInclusionBelonging:
    TalentDinbDiversityInclusionBelongingSectionValidator.nullable(),
  laborProductivity: TalentDinbLaborProductivitySectionValidator.nullable(),
  links: Array(LinkValidator)
});

export type TalentDinb = Static<typeof TalentDinbValidator>;
