import React, { Dispatch, SetStateAction } from "react";
import "./RadioOption.scss";
import classNames from "classnames";
import { Text } from "common-components";

const namespace = "rts-pa-radio-option";

export type RadioOptionValue = string | number | null;

export type RadioOptionType<T extends RadioOptionValue> = {
  label: string;
  value: T;
};

type Props<T extends RadioOptionValue> = RadioOptionType<T> & {
  isSelected: boolean;
  onClick: ((value: T) => void) | Dispatch<SetStateAction<T>>;
};

export const RadioOption = <T extends RadioOptionValue>(props: Props<T>) => {
  const circleClassName = classNames(`${namespace}-circle`, {
    selected: props.isSelected
  });

  return (
    <span className={namespace} onClick={() => props.onClick(props.value)}>
      <div className={circleClassName}>
        <div className={`${namespace}-circle-inner`} />
      </div>
      <Text strong>{props.label}</Text>
    </span>
  );
};

export default RadioOption;
