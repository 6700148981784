import React from "react";
import Logo from "images/amgen_logo_white.png";
import Web from "images/neural_web.png";
import "./PrintHeader.scss";

export const PrintHeader = () => {
  return (
    <div className="rts-pa-print-header">
      <img className="print-neural-web" src={Web} alt="amgen logo" />
      <div className="print-logo-block">
        <img src={Logo} alt="amgen logo" />
        <p>Sensing</p>
      </div>
    </div>
  );
};

export default PrintHeader;
