import React from "react";
import "./ComModalPipelineMetrics.scss";
import { CommercialPipelineSection } from "api";
import ModalSection from "common-components/modal-section/ModalSection";
import ComModalPipelineProject from "./project/ComModalPipelineProject";

type Props = {
  data: CommercialPipelineSection | undefined | null;
};

const ComModalPipelineMetrics = (props: Props) => {
  if (!props.data) {
    return <></>;
  }

  return (
    <ModalSection
      title="Pipeline Metrics"
      className="rts-pa-commercial-modal-pipeline-metrics"
    >
      <>
        {props.data.projects.map((p, i) => (
          <ComModalPipelineProject key={i} project={p} />
        ))}
      </>
    </ModalSection>
  );
};

export default ComModalPipelineMetrics;
