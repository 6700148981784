import { Array, Boolean, Number, Record, Static, String } from "runtypes";
import { CalendarMilestoneValidator } from "./calendar-common";

export const CalendarBoardRowItemValidator = Record({
  id: Number,
  calendarRowVersionId: Number,
  calendarRowLinkId: String.nullable(),
  order: Number,
  name: String,
  isVisibleToOfficers: Boolean,
  linkToSource: String.nullable(),
  milestones: Array(CalendarMilestoneValidator)
});

export type CalendarBoardRowItem = Static<typeof CalendarBoardRowItemValidator>;

export const CalendarBoardRangeValidator = Record({
  year: Number,
  quarters: Array(Number)
});

export type CalendarBoardRange = Static<typeof CalendarBoardRangeValidator>;

export const CalendarBoardValidator = Record({
  rows: Array(CalendarBoardRowItemValidator),
  range: Array(CalendarBoardRangeValidator)
});

export type CalendarBoard = Static<typeof CalendarBoardValidator>;
