import { Label, MilestoneTypeIcon, Text } from "common-components";
import React from "react";
import "./CalendarKeys.scss";
import { useQuery } from "@tanstack/react-query";
import { getMilestoneCalendarKeys } from "api";

const namespace = "rts-pa-key-events-page-calendar-keys";

export const CalendarKeys = () => {
  const { data } = useQuery(["calendar-keys"], getMilestoneCalendarKeys);

  return (
    <div className={namespace}>
      <Label>Calendar Keys</Label>
      {data ? (
        data.keys.map(mck => (
          <MilestoneTypeIcon key={mck.id} id={mck.id} showLabel size="small" />
        ))
      ) : (
        <Text>Loading...</Text>
      )}
    </div>
  );
};

export default CalendarKeys;
