import React from "react";
import "./AIBuilderModalSectionEngagementScore.scss";
//import { AgendaItemTemplateSectionPlaceholder } from "api/agenda-item-template/sections";
import { SectionBasicProps } from "../../AgendaItemBuilderModalSection";

//const namespace = "rts-pa-agenda-item-builder-modal-section-engagement-score";

type Props = SectionBasicProps;

export const AIBuilderModalSectionEngagementScore = (props: Props) => {
  return <></>;

  // return (
  //   <div className={namespace}>
  //   </div>
  // );
};

export default AIBuilderModalSectionEngagementScore;
