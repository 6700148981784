import React, { useCallback, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import "./ImageUploader.scss";
import { Button } from "@opsdti-global-component-library/amgen-design-system";
import colors from "utils/colors";

const namespace = "rts-pa-image-uploader";
const baseStyle = {
  flex: "0 0 132px",
  display: "flex",
  alignItems: "center",
  width: "100%",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: colors.gray300,
  borderStyle: "dashed",
  backgroundColor: colors.gray200,
  outline: "none",
  transition: "border .24s ease-in-out"
};

const acceptStyle = {
  color: colors.tertiaryBlue,
  borderColor: colors.tertiaryBlue
};

const rejectStyle = {
  borderColor: colors.gray300
};

type Props = {
  uploadImage: (image: File) => void;
};

export const ImageUploader = ({ uploadImage }: Props) => {
  const [image, setImage] = useState<string>("");
  const [acceptedFiles, setAcceptedFiles] = useState<File[]>([]);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setAcceptedFiles(acceptedFiles);
    acceptedFiles.forEach((file: File) => {
      const reader = new FileReader();
      reader.onload = () => setImage(reader.result as string);
      reader.readAsDataURL(file);
    });
  }, []);

  const accept = { "image/*": [".png", ".jpg", ".jpeg", ".gif", ".svg"] };

  const { getRootProps, getInputProps, isDragAccept, isDragReject, open } =
    useDropzone({
      accept,
      maxFiles: 1,
      onDrop
    });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragAccept, isDragReject]
  );

  return (
    <div className={namespace}>
      {!acceptedFiles.length ? (
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <div className={`${namespace}-label`}>
            <p>Drag and drop image here</p>
            <p>or click to select file</p>
          </div>
        </div>
      ) : (
        <div className={`${namespace}-accepted`}>
          <img width={240} height={150} alt="Tile preview" src={image} />
          <div className={`${namespace}-detail`}>
            <span>{acceptedFiles[0].name}</span>
            <div className={`${namespace}-controls`}>
              <button onClick={() => setAcceptedFiles([])}>Remove</button>
              <button onClick={open}>Change photo</button>
            </div>
          </div>
        </div>
      )}
      <div className={`${namespace}-footer`}>
        <Button
          text="Save changes"
          type="primary"
          disabled={acceptedFiles.length <= 0}
          className={`${namespace}-export-button`}
          onClick={() => uploadImage(acceptedFiles[0])}
        />
      </div>
    </div>
  );
};
