import React, { useContext, useEffect, useState } from "react";
import "./AgendaItemBuilderModal.scss";
import { useQuery } from "@tanstack/react-query";
import PaModal from "common-components/pa-modal/PaModal";
import { useNavigate } from "react-router-dom";
import { useModalHelper } from "global/use-modal-helper";
import pages from "pages/pages";
import { PermissionsContext } from "global/permissions";
import { getAgendaItemTemplate, AgendaItemTemplate } from "api";
import { AgendaItem } from "api/common";
import AgendaItemBuilderModalBuilderContainer from "./AgendaItemBuilderModalBuilderContainer";
import AgendaItemBuilderModalPreviewContainer from "./AgendaItemBuilderModalPreviewContainer";
import { getAgendaItemPreview } from "api/agenda-item-new";

const namespace = "rts-pa-agenda-item-builder-modal";

const initialTemplate: AgendaItemTemplate = {
  id: 0,
  name: "",
  archetype: "standalone",
  statusType: "manual",
  status: "gray",
  linkId: null,
  groups: []
};

//TODO JCG: update to generic AI call + model + permissions logic
export const AgendaItemBuilderModal = () => {
  const perms = useContext(PermissionsContext);
  const hasPermissions = perms.grid.edit;
  const navigate = useNavigate();
  const mhResult = useModalHelper(
    [pages.grid.edit.agendaItemBuilderModal.path], //TODO JCG: add add/edit paths
    hasPermissions
  );

  const [agendaItemSimple, setAgendaItemSimple] = useState<AgendaItem>();
  const [agendaItemTemplate, setAgendaItemTemplate] =
    useState<AgendaItemTemplate>(initialTemplate);
  const { status, error } = useQuery<AgendaItemTemplate>(
    ["agenda-item-template", agendaItemSimple?.id],
    () => getAgendaItemTemplate(mhResult.itemId),
    {
      enabled: mhResult.enabled,
      onSuccess: setAgendaItemTemplate
    }
  );
  const previewData = getAgendaItemPreview(agendaItemTemplate);

  useEffect(() => {
    if (mhResult.enabled) {
      setAgendaItemSimple(mhResult.agendaItem);
    } else {
      setTimeout(() => {
        setAgendaItemSimple(undefined);
      }, 500);
    }
  }, [mhResult.enabled]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <PaModal
      title="Agenda Item Builder"
      status={status}
      axiosErrors={error}
      isOpen={mhResult.enabled}
      onClose={() => {
        navigate(pages.grid.go());
      }}
      className={namespace}
      removeScroll
      width={934}
    >
      <AgendaItemBuilderModalBuilderContainer
        agendaItemTemplate={agendaItemTemplate}
        setAgendaItemTemplate={setAgendaItemTemplate}
      />
      <AgendaItemBuilderModalPreviewContainer agendaItem={previewData} />
    </PaModal>
  );
};

export default AgendaItemBuilderModal;
