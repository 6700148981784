import React from "react";
import "./LinksInputItem.scss";
import { Input, Label, Checkbox } from "common-components";
import {
  AutoUpdaterPropExpression,
  AutoUpdaterSetItem,
  AutoUpdaterType
} from "global/use-auto-updater";
import { FieldValUtils } from "global/use-validator";
import TrashIcon from "icons/trash-icon";
import DragIcon from "icons/drag-icon";
import { Draggable } from "react-beautiful-dnd";
import classnames from "classnames";

const namespace = "rts-pa-links-input-item";

export type Props<T extends AutoUpdaterType> = {
  title: string;
  id: string;
  className?: string;
  autoUpdater: {
    item: T;
    propExpressionLabel: AutoUpdaterPropExpression<T>;
    propExpressionHref: AutoUpdaterPropExpression<T>;
    propExpressionIsExternal: AutoUpdaterPropExpression<T>;
    setItem: AutoUpdaterSetItem<T>;
  };
  autoUpdaterIndex: number;
  validator: FieldValUtils;
  onDeleteClick: (i: number) => void;
};

export const LinksInputItem = <T extends AutoUpdaterType>(
  props: Props<T>
): JSX.Element => {
  const title = props.title;

  return (
    // JCG: .toString() since technically IDs are numbers, but FE-created temp IDs are UUID strings
    <Draggable draggableId={props.id.toString()} index={props.autoUpdaterIndex}>
      {(provided, snapshot) => {
        const rootClassName = classnames(namespace, {
          isDragging: snapshot.isDragging
        });

        return (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            style={provided.draggableProps.style}
            className={rootClassName}
          >
            <Label>{title}</Label>
            <div className={`${namespace}-container`}>
              <div className={`${namespace}-container-inputs`}>
                <Input
                  id={`${props.id}-text`}
                  placeholder="Text"
                  errorLabel="Text"
                  autoUpdater={{
                    ...props.autoUpdater,
                    propExpression: props.autoUpdater.propExpressionLabel
                  }}
                  validator={props.validator}
                  required={true}
                />
                <Input
                  id={`${props.id}-URL`}
                  placeholder="URL (should include https://)"
                  errorLabel="URL"
                  autoUpdater={{
                    ...props.autoUpdater,
                    propExpression: props.autoUpdater.propExpressionHref
                  }}
                  validator={props.validator}
                  required={true}
                />
                <Checkbox
                  label="Select if the link leads to a website outside of Sensing"
                  autoUpdater={{
                    ...props.autoUpdater,
                    propExpression: props.autoUpdater.propExpressionIsExternal
                  }}
                />
              </div>
              <div className={`${namespace}-container-controls`}>
                <TrashIcon
                  className="trash-icon"
                  height={15}
                  width={16}
                  onClick={() => props.onDeleteClick(props.autoUpdaterIndex)}
                />
                <div
                  className={`${namespace}-drag`}
                  {...provided.dragHandleProps}
                >
                  <DragIcon className="drag-icon" height={12} width={16} />
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </Draggable>
  );
};

export default LinksInputItem;
