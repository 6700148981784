import { Record, Static, String, Array } from "runtypes";

const AdGroupUserValidator = Record({
  email: String,
  fullName: String
});

export type AdGroupUser = Static<typeof AdGroupUserValidator>;

export const ActiveDirectoryAdGroupUserListValidator = Record({
  users: Array(AdGroupUserValidator)
});

export type ActiveDirectoryAdGroupUserList = Static<
  typeof ActiveDirectoryAdGroupUserListValidator
>;
