import React from "react";
import "./KeyEventsCalendarRowDateline.scss";
import dayjs from "dayjs";

export const namespace = "rts-pa-key-events-calendar-row-dateline";

const quarterProgress = (() => {
  const today = dayjs();

  const qStart = today.subtract(today.month() % 3, "month").date(1);
  const qEnd = qStart.add(3, "month");
  const totalDays = qEnd.diff(qStart, "day");
  const progressDays = today.diff(qStart, "day");
  const progressPct = (progressDays / totalDays) * 100;

  return `${progressPct}%`;
})();

export default function KeyEventsCalendarRowDateline(): JSX.Element {
  return (
    <div
      className={namespace}
      style={{
        gridColumn: `1 / span 3`
      }}
    >
      <div
        className={`${namespace}-container`}
        style={{ paddingLeft: quarterProgress }}
      />
    </div>
  );
}
