import React, { useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import "./BiosimilarsModal.scss";
import PaModal from "common-components/pa-modal/PaModal";
import { BiosimilarsItem } from "api/biosimilars";
import { getAgendaItemCached } from "api/cache";
import { PermissionsContext } from "global/permissions";
import { useNavigate } from "react-router-dom";
import { useModalHelper } from "global/use-modal-helper";
import pages from "pages/pages";
import PermissionsWrapper from "common-components/permissions-wrapper/permissions-wrapper";
import SubmissionRequest from "common-components/submission-request/SubmissionRequest";
import SummaryInfo from "common-components/summary-info/SummaryInfo";
import OwnersContainer from "common-components/owners-container/OwnersContainer";
import BiosimilarsPipelineMetricsGroup from "./pipeline-metrics-group/BiosimilarsPipelineMetricsGroup";
import ModalExternalLinks from "common-components/modal-external-links/ModalExternalLinks";

const namespace = "rts-pa-biosimilars-modal";

const BiosimilarsModal = () => {
  const perms = useContext(PermissionsContext);
  const hasPermissions = perms.grid.biosimilarsModal;
  const navigate = useNavigate();

  const mhResult = useModalHelper(
    pages.grid.biosimilarsModal.path,
    hasPermissions,
    "biosimilars"
  );

  const { status, data, error } = useQuery<BiosimilarsItem>(
    ["agenda-item-cached", mhResult.itemId],
    () => getAgendaItemCached("biosimilars", mhResult.itemId),
    {
      enabled: mhResult.enabled
    }
  );

  return (
    <PaModal
      title={mhResult.title}
      status={status}
      axiosErrors={error}
      isOpen={mhResult.enabled}
      onClose={() => {
        navigate(pages.grid.go());
      }}
      className={namespace}
    >
      <PermissionsWrapper permissions={perms.submissions}>
        <SubmissionRequest agendaItem={mhResult.agendaItem} />
      </PermissionsWrapper>
      <SummaryInfo data={data?.summaryInfo} />
      <OwnersContainer owners={data?.owners} className="biosimilars-modal" />
      <BiosimilarsPipelineMetricsGroup data={data?.pipeline} />
      <ModalExternalLinks
        links={data?.links}
        className="biosimilars-modal"
        buttonType="primary"
      />
    </PaModal>
  );
};

export default BiosimilarsModal;
