import { Literal, Static, Union } from "runtypes";

export const SubmissionStatusValidator = Union(
  Literal("created"),
  Literal("approved"),
  Literal("rejected"),
  Literal("submitted")
);

export type SubmissionStatus = Static<typeof SubmissionStatusValidator>;
