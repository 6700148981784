import React from "react";
import "./BiosimilarsPipelineMetricsGroup.scss";
import { BiosimilarsPipelineGroup } from "api/biosimilars-item/biosimilars-pipeline-group";
import ModalSection from "common-components/modal-section/ModalSection";
import BiosimilarsPipelineMetricsPlannedMarketReadinessSection from "./planned-market-readiness-section/BiosimilarsPlannedMarketReadinessSection";

type Props = {
  data?: BiosimilarsPipelineGroup | undefined | null;
};

const namespace = "rts-pa-biosimilars-modal-pipeline-metrics";

const BiosimilarsPipelineMetricsGroup = (props: Props) => {
  if (!props.data) {
    return <></>;
  }

  return (
    <ModalSection title="Pipeline Metrics" className={namespace}>
      <div className={`${namespace}-container`}>
        <BiosimilarsPipelineMetricsPlannedMarketReadinessSection
          data={props.data.plannedMarketReadiness}
        />
      </div>
    </ModalSection>
  );
};

export default BiosimilarsPipelineMetricsGroup;
