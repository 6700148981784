import React, { useMemo } from "react";
import "./TabNavigation.scss";
import {
  Tabs,
  TabsProps
} from "@opsdti-global-component-library/amgen-design-system";
import { Tab } from "rc-tabs/lib/interface";
import classNames from "classnames";

export type TabItem = Tab & {
  badge?: number;
  onClick: () => void;
};

type Props = Omit<TabsProps, "items"> & {
  items: TabItem[];
};
const namespace = "rts-pa-tab-navigation";

const TabNavigation = (props: Props) => {
  const items = useMemo(() => {
    const newItems: TabItem[] = [];

    for (const item of props.items) {
      if (item.badge !== undefined) {
        item.label = (
          <>
            {item.label} <Tabs.Badge count={item.badge} />
          </>
        );
      }

      newItems.push(item);
    }

    return newItems;
  }, [props.items]);

  const onTabClick = useMemo(() => {
    const onClickMap = new Map<string, () => void>();

    for (const item of items) {
      onClickMap.set(item.key as string, item.onClick);
    }

    return (
      activeKey: string,
      e: React.KeyboardEvent<Element> | React.MouseEvent<Element, MouseEvent>
    ) => {
      onClickMap.get(activeKey)?.();
      props.onTabClick?.(activeKey, e);
    };
  }, [items, props.onTabClick]); // eslint-disable-line react-hooks/exhaustive-deps
  const className = classNames(namespace, props.className);
  return (
    <div className={className}>
      <Tabs
        {...props}
        className={undefined}
        items={items as TabsProps["items"]}
        onTabClick={onTabClick as unknown as TabsProps["onTabClick"]}
      />
    </div>
  );
};

export default TabNavigation;
