import React, { useEffect, useState } from "react";
import "./SubmissionsTable.scss";
import SubmissionsTableFilters, {
  Filters
} from "./Filters/SubmissionsTableFilters";
import Table, { TableColumnsType } from "common-components/table/Table";
import { useQuery } from "@tanstack/react-query";
import {
  SubmissionsTable as SubmissionsTableType,
  SubmissionsTableItem,
  User,
  SubmissionStatus
} from "api";
import { Button } from "@opsdti-global-component-library/amgen-design-system";
import dayjs from "dayjs";
import { CheckoutButtonWithTooltip, Text } from "common-components";
import { useConflictManagement } from "global/use-conflict-management";
import { getSubmissionsTable } from "api/submission/submissions-table";

type PaSubmissionStatus = {
  statusLabel: string;
  buttonLabel: string;
  sortOrder: number;
};

const namespace = "rts-pa-submissions-table";

const initialFilters: Filters = {
  status: "",
  owner: "",
  dateRange: [],
  name: ""
};

const paStatusMap = new Map<SubmissionStatus, PaSubmissionStatus>([
  [
    "created",
    { statusLabel: "Requested", buttonLabel: "review", sortOrder: 2 }
  ],
  [
    "submitted",
    { statusLabel: "Ready for Review", buttonLabel: "review", sortOrder: 1 }
  ],
  ["approved", { statusLabel: "Reviewed", buttonLabel: "view", sortOrder: 3 }],
  ["rejected", { statusLabel: "Reviewed", buttonLabel: "view", sortOrder: 4 }]
]);

// TODO AG: Read curation url from property in submissions.
export const curationUrlsMap = new Map<string, string>([
  ["development", "https://sensing-dev.nimbus.amgen.com/curation"],
  ["test", "https://sensing-test.nimbus.amgen.com/curation"],
  ["stage", "https://sensing-stg.nimbus.amgen.com/curation"],
  ["production", "https://sensing.nimbus.amgen.com/curation"]
]);

const openSubmission = (path: string) => {
  const { NODE_ENV } = window.__RUNTIME_CONFIG__;
  const curationUrl = curationUrlsMap.get(NODE_ENV) || "";

  window.open(`${curationUrl}${path}`, "_blank");
};

export const SubmissionsTable = () => {
  const { data, status } = useQuery<SubmissionsTableType>(
    ["submission-table"],
    getSubmissionsTable
  );
  const [tableData, setTableData] = useState(data);
  const [filters, setFilters] = useState<Filters>(initialFilters);

  const {
    isCheckOutInLoading,
    setIsCheckOutInLoading,
    refreshCheckoutStatus,
    checkout
  } = useConflictManagement();

  const renderCheckoutButton = (label: string, submissionId: string) => {
    const handleButtonClick = async () => {
      if (!isCheckOutInLoading) {
        setIsCheckOutInLoading(true);

        const result = await checkout();
        setIsCheckOutInLoading(false);

        if (result.isSuccess) {
          openSubmission(`/submission/${submissionId}/review`);
        } else {
          refreshCheckoutStatus();
        }
      }
    };

    return (
      <CheckoutButtonWithTooltip
        text={label}
        type="secondary"
        onClick={handleButtonClick}
      />
    );
  };
  useEffect(() => {
    if (!data) {
      return;
    }

    let filteredData = data;

    if (filters.status !== "") {
      filteredData = filteredData.filter(data => {
        // Since both approved and rejected are displayed as Reviewed in the table.
        if (filters.status === "approved") {
          return data.status === filters.status || data.status === "rejected";
        }
        return data.status === filters.status;
      });
    }

    if (filters.owner !== "") {
      filteredData = filteredData.filter(
        data => data.assignee.email === filters.owner
      );
    }

    if (filters.name !== "") {
      filteredData = filteredData.filter(data =>
        data.title.toLowerCase().includes(filters.name.toLowerCase())
      );
    }

    if (filters.dateRange.length === 2) {
      const startDate = filters.dateRange[0];
      const endDate = filters.dateRange[1];

      filteredData = filteredData.filter(data => {
        if (!data.submittedDate) {
          return false;
        }

        const checkDate = dayjs.utc(data.submittedDate).tz();

        return (
          checkDate.isSameOrAfter(startDate, "day") &&
          checkDate.isSameOrBefore(endDate, "day")
        );
      });
    }

    setTableData(filteredData);
  }, [data, filters]);

  const columns: TableColumnsType<SubmissionsTableItem> = [
    {
      title: "Date Requested",
      dataIndex: "createdDate",
      className: `${namespace}-date-requested`,
      width: "135px",
      render: (value: string) => {
        const createdDate = dayjs.utc(value).tz();

        return createdDate.format("MMM D, YYYY");
      },
      sorter: (a, b) => {
        const dayA = dayjs.utc(a.createdDate);
        const dayB = dayjs.utc(b.createdDate);

        return dayA.diff(dayB);
      }
    },
    {
      title: "Item Name",
      dataIndex: "title",
      sorter: (a, b) => {
        return a.title.localeCompare(b.title);
      }
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "120px",
      render: (value: SubmissionStatus) => {
        return paStatusMap.get(value)?.statusLabel || "";
      },
      sorter: (a, b) => {
        return (
          (paStatusMap.get(a.status)?.sortOrder || 0) -
          (paStatusMap.get(b.status)?.sortOrder || 0)
        );
      }
    },
    {
      title: "Owner",
      dataIndex: "assignee",
      width: "125px",
      render: (value: User) => {
        return value.fullName;
      },
      sorter: (a, b) => {
        return (a.assignee.fullName + "").localeCompare(
          b.assignee.fullName + ""
        );
      }
    },
    {
      title: "Date Received",
      dataIndex: "submittedDate",
      width: "110px",
      render: (value: string) => {
        if (value) {
          const submittedDateObject = dayjs.utc(value).tz();
          return submittedDateObject.format("MMM D, YYYY");
        }
        return <Text type="notAvailable">Not available</Text>;
      },
      sorter: (a, b) => {
        if (!a.submittedDate && b.submittedDate) {
          return 1;
        } else if (a.submittedDate && !b.submittedDate) {
          return -1;
        } else if (!a.submittedDate && !b.submittedDate) {
          return 0;
        }

        const dayA = dayjs.utc(a.submittedDate);
        const dayB = dayjs.utc(b.submittedDate);

        return dayA.diff(dayB);
      }
    },
    {
      title: "Actions",
      dataIndex: "status",
      width: "100px",
      render: (value: SubmissionStatus, item) => {
        const submissionId = item.id;
        const label = (paStatusMap.get(value) as PaSubmissionStatus)
          ?.buttonLabel;

        switch (value) {
          case "created":
            return <Button text={label} disabled type="primary" />;
          case "submitted":
            return renderCheckoutButton(label, submissionId);
          case "rejected":
          case "approved":
            return (
              <Button
                text={label}
                type="secondary"
                onClick={() => {
                  openSubmission(`/submission/${submissionId}/view`);
                }}
              />
            );
        }
      },
      sorter: (a, b) => {
        return (
          (paStatusMap.get(a.status)?.sortOrder || 0) -
          (paStatusMap.get(b.status)?.sortOrder || 0)
        );
      }
    }
  ];

  return (
    <div className={namespace}>
      <SubmissionsTableFilters
        submissionsData={data}
        filters={filters}
        onChange={setFilters}
      />
      <Table columns={columns} dataSource={tableData} status={status} />
    </div>
  );
};

export default SubmissionsTable;
