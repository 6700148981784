import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { OnDragUpdateResponder } from "react-beautiful-dnd";
import { AgendaGridLineItemPlaceholderStyleProps } from "./AgendaGridLineItemPlaceholder";
import "./AgendaGridLineItemPlaceholder.scss";

/* based on https://medium.com/@larryliu_50901/how-to-finally-add-a-custom-placeholder-react-beautiful-dnd-1b1359a94e30 */

const placeholderInstances: Map<
  string,
  Dispatch<SetStateAction<AgendaGridLineItemPlaceholderStyleProps | undefined>>
> = new Map();

let oldDroppableId: string | undefined = undefined;

export const onDragEnd = () => {
  placeholderInstances.forEach(setStyle => {
    setStyle(undefined);
  });

  oldDroppableId = undefined;
};

//update size/location of placeholder
export const onDragUpdate: OnDragUpdateResponder = update => {
  if (!update.destination) {
    //remove placeholder when not hovering over anything
    if (oldDroppableId !== undefined) {
      const oldSetStyle = placeholderInstances.get(oldDroppableId);
      if (oldSetStyle) {
        oldSetStyle(undefined);
      }
    }

    oldDroppableId = undefined;

    return;
  }

  const droppableId = update.destination.droppableId;
  const draggableId = update.draggableId;
  const destinationIndex = update.destination.index;

  const draggedDOM = document.querySelector<HTMLElement>(
    `[data-rbd-drag-handle-draggable-id='${draggableId}']`
  )?.parentElement;

  const droppableDOM = document.querySelector<HTMLElement>(
    `[data-rbd-droppable-id='${droppableId}']`
  );

  if (!draggedDOM || !droppableDOM) {
    return;
  }

  const { offsetHeight, offsetWidth } = draggedDOM;

  const clientY = Array.from(
    droppableDOM.children as HTMLCollectionOf<HTMLElement>
  )
    .slice(0, destinationIndex - 1)
    .reduce((total, curr) => {
      const style = window.getComputedStyle(curr);
      const marginBottom = parseFloat(style.marginBottom);
      return total + curr.offsetHeight + marginBottom;
    }, 0);

  //update new location
  const setStyle = placeholderInstances.get(droppableId);
  if (setStyle) {
    setStyle({
      height: offsetHeight,
      width: offsetWidth,
      y: clientY,
      x: 0
    });
  }

  //update old location
  if (oldDroppableId !== undefined && oldDroppableId !== droppableId) {
    const oldSetStyle = placeholderInstances.get(oldDroppableId);
    if (oldSetStyle) {
      oldSetStyle(undefined);
    }
  }

  oldDroppableId = droppableId;
};

const useAgendaGridLineItemPlaceholder = (droppableId: string) => {
  const [styleProps, setStyleProps] =
    useState<AgendaGridLineItemPlaceholderStyleProps>();

  useEffect(() => {
    placeholderInstances.set(droppableId, setStyleProps);

    return () => {
      placeholderInstances.delete(droppableId);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return styleProps;
};

export default useAgendaGridLineItemPlaceholder;
