//import axios from "axios";
import { Array, Number, Record, Static, String } from "runtypes";
import {
  PageInfo,
  PageInfoValidator,
  SortInfo,
  SortInfoValidator,
  UserValidator
} from "./common";
import * as TestData from "../__fixtures__/change-log/list.json";

const ChangeLogItemValidator = Record({
  id: Number,
  agendaItemId: Number,
  agendaItemName: String,
  createdBy: UserValidator,
  createdDate: String,
  fieldName: String,
  oldValue: String.nullable(), //null on "added" or "removed" fieldName
  newValue: String.nullable() //null on "added" or "removed" fieldName
});

export type ChangeLogItem = Static<typeof ChangeLogItemValidator>;

const ChangeLogFiltersValidator = Record({
  startDate: String,
  endDate: String,
  agendaItemIds: Array(String),
  fieldNames: Array(String)
});

export type ChangeLogFilters = Static<typeof ChangeLogFiltersValidator>;

export type GetChangeLogRequest = {
  filters: ChangeLogFilters;
  sortInfo: SortInfo;
  pageInfo: PageInfo;
};

const GetChangeLogResponseValidator = Record({
  filters: ChangeLogFiltersValidator,
  sortInfo: SortInfoValidator,
  pageInfo: PageInfoValidator,
  data: Array(ChangeLogItemValidator)
});

export type GetChangeLogResponse = Static<typeof GetChangeLogResponseValidator>;

export const getChangeLog = async (): //request: GetChangeLogRequest
Promise<GetChangeLogResponse> => {
  // const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  // const result = await axios.get<GetChangeLogResponse>(
  //   `${REACT_APP_DOMAIN}/api/v1/change_log`,
  //   {
  //     params: {
  //       ...request.filters,
  //       sortProperty: request.sortInfo.property,
  //       sortDirection: request.sortInfo.direction,
  //       pageSize: request.pageInfo.pageSize,
  //       pageNumber: request.pageInfo.pageNumber
  //     }
  //   }
  // );

  const result = {
    data: TestData as unknown as GetChangeLogResponse
  };

  GetChangeLogResponseValidator.check(result.data);

  return result.data;
};
