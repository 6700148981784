import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { getAllAdEntities, AdUser, AdGroup } from "api/active-directory";
import { GenericOption } from "common-components";

type Response = {
  users: AdUser[];
  groups: AdGroup[];
  options: GenericOption<string>[];
};

export const useGetAllAdEntities = (): Response => {
  const [response, setResponse] = useState<Response>({
    users: [],
    groups: [],
    options: []
  });

  useQuery(["all-ad-entities"], getAllAdEntities, {
    onSuccess: data => {
      let options: GenericOption<string>[] = [];

      options = options.concat(
        data.users.map(u => ({
          label: u.displayName,
          value: u.username
        }))
      );

      options = options.concat(
        data.groups.map(u => ({
          label: u.displayName,
          value: u.username
        }))
      );

      setResponse({
        users: data.users,
        groups: data.groups,
        options: options
      });
    }
  });

  return response;
};
