import { Literal, Record, Static, String, Union } from "runtypes";

const ActiveDirectoryEntityTypeValidator = Union(
  Literal("user"),
  Literal("group")
);

export type ActiveDirectoryEntityType = Static<
  typeof ActiveDirectoryEntityTypeValidator
>;

export const AdEntityBaseValidator = Record({
  displayName: String,
  username: String,
  email: String,
  type: ActiveDirectoryEntityTypeValidator
});

export type ActiveDirectoryEntity = Static<typeof AdEntityBaseValidator>;

export const ActiveDirectoryGroupValidator = AdEntityBaseValidator.extend({
  type: Literal("group")
});

export type ActiveDirectoryGroup = Static<typeof ActiveDirectoryGroupValidator>;

export const ActiveDirectoryUserValidator = AdEntityBaseValidator.extend({
  type: Literal("user")
});

export type ActiveDirectoryUser = Static<typeof ActiveDirectoryUserValidator>;
