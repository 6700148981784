import React from "react";
import "./Dropdown.scss";
import {
  Dropdown as DsDropdown,
  DropdownProps
} from "@opsdti-global-component-library/amgen-design-system";
export type {
  MenuItemType,
  ItemType,
  MenuDividerType
} from "antd/es/menu/hooks/useItems";

type Props = DropdownProps;

const namespace = "rts-pa-dropdown";

const Dropdown = ({ ...rest }: Props) => {
  return (
    <div className={namespace}>
      <DsDropdown {...rest} />
    </div>
  );
};

export default Dropdown;
