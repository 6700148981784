/* eslint-disable no-case-declarations */
//import axios from "axios";
import {
  AgendaItemTemplate,
  AgendaItemTemplateValidator
} from "./agenda-item-template/agenda-item-template";
import * as TestData from "../__fixtures__/agenda-item-template/template-repatha.json";

export const getAgendaItemTemplate = async (
  id: number
): Promise<AgendaItemTemplate> => {
  // const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  // const result = await axios.get<AgendaItemTemplate>(
  //   `${REACT_APP_DOMAIN}/api/v2/agenda_item/${id}`
  // );

  const result = {
    data: TestData as unknown as AgendaItemTemplate
  };

  AgendaItemTemplateValidator.check(result.data);

  return result.data;
};
