import React from "react";
import { KeyEventsIconProps } from "./common-props";

export const TriangleIcon = (props: KeyEventsIconProps) => {
  const { width = 15, height = 14, className, fill = "#5610EB" } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M7.83301 0L14.833 14H0.833008L7.83301 0Z" fill={fill} />
    </svg>
  );
};

export default TriangleIcon;
