import React from "react";
import "./PipelineProjectNextPriorityMilestone.scss";
import {
  AdvancedTooltip as AdvancedTooltipType,
  NextPriorityMilestone
} from "api";
import {
  CalendarIcon,
  LocationIcon
} from "@opsdti-global-component-library/amgen-design-system";
import dayjs from "dayjs";
import PipelineStatusWithTooltip from "common-components/pipeline-status-with-tooltip/PipelineStatusWithTooltip";
import { AdvancedTooltip } from "common-components/advanced-tooltip/AdvancedTooltip";
import { Label, Text } from "common-components";

const namespace = "rts-pa-pipeline-project-next-priority-milestone";

const infoTooltip: AdvancedTooltipType = {
  sections: [
    {
      rows: [
        {
          content: [
            "Soonest major milestone from the list of prioritized indications for this program."
          ]
        }
      ]
    },
    {
      rows: [
        {
          label: "Data Source:",
          content: ["Mercury"]
        }
      ]
    }
  ]
};

type Props = {
  data: NextPriorityMilestone | undefined;
};

const PipelineProjectNextPriorityMilestone = (props: Props) => {
  if (!props.data) {
    return <></>;
  }

  //calculate # of empty rows needed in right column for alignment to work as expected
  let colRightEmptyRows = 0;
  if (props.data.milestoneShortName) colRightEmptyRows++;
  if (props.data.date) colRightEmptyRows++;
  if (props.data.geographicArea) colRightEmptyRows--;
  if (props.data.status && props.data.statusTooltip) colRightEmptyRows--;
  if (colRightEmptyRows < 0) {
    colRightEmptyRows = 0;
  }

  return (
    <div className={namespace}>
      <div className={`${namespace}-title`}>
        <Label className={`${namespace}-title-text`}>
          Next Priority Milestone
        </Label>
        <AdvancedTooltip tooltip={infoTooltip} />
      </div>

      <div className={`${namespace}-content`}>
        <Text strong>{props.data.description}</Text>

        <Text>{props.data.studyShortDescription}</Text>

        <div className={`${namespace}-columns`}>
          <div className={`${namespace}-columns-left`}>
            <Text>{props.data.milestoneShortName}</Text>

            {props.data.date && (
              <div className={`${namespace}-date-container`}>
                <CalendarIcon width={16} height={16} />
                <Text>{dayjs(props.data.date).format("MM/DD/YYYY")}</Text>
              </div>
            )}
          </div>

          <div className={`${namespace}-columns-right`}>
            {[...Array(colRightEmptyRows)].map((_, i) => (
              <Text key={i}>&nbsp;</Text>
            ))}

            {props.data.geographicArea && (
              <div className={`${namespace}-geo-container`}>
                <LocationIcon width={16} height={16} />
                <Text>{props.data.geographicArea}</Text>
              </div>
            )}

            {props.data.status && props.data.statusTooltip && (
              <div className={`${namespace}-status-container`}>
                <PipelineStatusWithTooltip
                  status={props.data.status}
                  tooltip={props.data.statusTooltip}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PipelineProjectNextPriorityMilestone;
