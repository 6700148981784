import axios from "axios";
import { getKeyEventsId } from "utils/multitenancy";
import { MilestoneCalendarKeysResponse } from "./index";

export const getMilestoneCalendarKeys =
  async (): Promise<MilestoneCalendarKeysResponse> => {
    const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

    const result = await axios.get<MilestoneCalendarKeysResponse>(
      `${REACT_APP_DOMAIN}/api/v1/${getKeyEventsId()}/milestone_calendar_key/all`
    );

    return result.data;
  };
