import React, { useContext } from "react";
import "./KeyEventsCalendarRowMenu.scss";
import { Popover } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import {
  ArrowRightIcon,
  EditIcon
} from "@opsdti-global-component-library/amgen-design-system";
import KeyEventsCalendarRowMenuItem from "./item/KeyEventsCalendarRowMenuItem";
import { useNavigate } from "react-router-dom";
import pages from "pages/pages";
import PermissionsWrapper from "common-components/permissions-wrapper/permissions-wrapper";
import { PermissionsContext } from "global/permissions";

export const namespace = "rts-pa-key-events-calendar-row-menu";

type Props = {
  rowId: number;
  linkToSource: string | null;
};

export default function KeyEventsCalendarRowMenu(props: Props): JSX.Element {
  const perms = useContext(PermissionsContext).keyEvents;
  const navigate = useNavigate();

  if (!props.linkToSource && !perms.edit) {
    return <></>;
  }

  const content = (
    <div className={`${namespace}-content`}>
      {props.linkToSource && (
        <KeyEventsCalendarRowMenuItem
          icon={<ArrowRightIcon />}
          label="Go to Pipeline Page"
          onClick={() => {
            if (props.linkToSource) {
              window.open(props.linkToSource, "_blank");
            }
          }}
        />
      )}
      <PermissionsWrapper permissions={perms.edit}>
        <KeyEventsCalendarRowMenuItem
          icon={<EditIcon height={14} />}
          label="Edit Row"
          onClick={() => {
            navigate(pages.keyEvents.editItemModal.go(props.rowId));
          }}
        />
      </PermissionsWrapper>
    </div>
  );

  return (
    <Popover className={namespace} content={content} trigger="click">
      <MoreOutlined />
    </Popover>
  );
}
