import { CalendarRowItems } from "api/calendar/calendar-row";
import dayjs, { Dayjs } from "dayjs";
import produce from "immer";

export const getQuarterStart = (date: Dayjs | undefined): Dayjs | undefined => {
  if (!date) {
    return undefined;
  }

  const quarterMonthStart = Math.floor(date.month() / 3) * 3;
  return dayjs(new Date(date.year(), quarterMonthStart, 1));
};

export const getQuarterEnd = (date: Dayjs | undefined): Dayjs | undefined => {
  const startDate = getQuarterStart(date);

  if (!startDate) {
    return undefined;
  }

  return startDate.add(3, "months").subtract(1, "day");
};

export const updateRowOrder = (calendarRows: CalendarRowItems) => {
  const newRows = produce(calendarRows, draftState => {
    for (let i = 0; i < draftState.length; i++) {
      draftState[i].order = i + 1;
    }
  });

  return newRows;
};
