import React, { useContext, useMemo } from "react";
import PaModal from "common-components/pa-modal/PaModal";
import { useQuery } from "@tanstack/react-query";
import "./PipelineModal.scss";
import SummaryInfo from "common-components/summary-info/SummaryInfo";
import { PermissionsContext } from "global/permissions";
import { useNavigate } from "react-router-dom";
import pages from "pages/pages";
import PermissionsWrapper from "common-components/permissions-wrapper/permissions-wrapper";
import { PipelineItem } from "api/getPipelineItem";
import { getAgendaItemCached } from "api/cache";
import { Link } from "api/common";
import { useModalHelper } from "global/use-modal-helper";
import SubmissionRequest from "common-components/submission-request/SubmissionRequest";
import ModalExternalLinks from "common-components/modal-external-links/ModalExternalLinks";
import PipelineProject from "components/pipeline-project/PipelineProject";

const namespace = "rts-pa-pipeline-modal";

const PipelineModal = () => {
  const perms = useContext(PermissionsContext);
  const hasPermissions = perms.grid.pipelineModal;
  const navigate = useNavigate();
  const mhResult = useModalHelper(
    pages.grid.pipelineModal.path,
    hasPermissions,
    "pipeline"
  );

  const { status, data, error } = useQuery<PipelineItem>(
    ["agenda-item-cached", mhResult.itemId],
    () => getAgendaItemCached("pipeline", mhResult.itemId),
    {
      enabled: mhResult.enabled
    }
  );

  const links = useMemo(() => {
    const links: Link[] = [];

    if (data) {
      for (const link of data.links) {
        links.push(link);
      }
    }

    return links;
  }, [data]);

  return (
    <PaModal
      title={mhResult.title}
      status={status}
      axiosErrors={error}
      isOpen={mhResult.enabled}
      onClose={() => {
        navigate(pages.grid.go());
      }}
      className={namespace}
    >
      <PermissionsWrapper permissions={perms.submissions}>
        <SubmissionRequest agendaItem={mhResult.agendaItem} />
      </PermissionsWrapper>
      <SummaryInfo data={data?.summaryInfo} />
      <PipelineProject
        owners={data?.owners}
        nextApprovalLaunchE2l={data?.nextApprovalLaunchE2L}
        nextPriorityMilestone={data?.nextPriorityMilestone}
        studyEnrollments={data?.studyEnrollments}
      />
      <ModalExternalLinks
        links={links}
        className="pipeline-modal"
        buttonType="primary"
      />
    </PaModal>
  );
};

export default PipelineModal;
