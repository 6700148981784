import React from "react";
import "./AgendaItemBuilderModalBuilderContainer.scss";
import { AgendaItemTemplate } from "api";
import { AgendaItemBuilderModalBasicInfo } from "./basic-info/AgendaItemBuilderModalBasicInfo";
import { useValidator } from "global/use-validator";
import AgendaItemBuilderModalGroupsContainer from "./groups-container/AgendaItemBuilderModalGroupsContainer";
import { AutoUpdaterSetItem } from "global/use-auto-updater";

const namespace = "rts-pa-agenda-item-builder-modal-builder-container";

type Props = {
  agendaItemTemplate: AgendaItemTemplate;
  setAgendaItemTemplate: AutoUpdaterSetItem<AgendaItemTemplate>;
};

//TODO JCG: update to generic AI call + model + permissions logic
export const AgendaItemBuilderModalBuilderContainer = (props: Props) => {
  const { fieldUtils } = useValidator();

  return (
    <div className={namespace}>
      <div className={`${namespace}-content`}>
        <AgendaItemBuilderModalBasicInfo
          agendaItemTemplate={props.agendaItemTemplate}
          setAgendaItemTemplate={props.setAgendaItemTemplate}
          fieldUtils={fieldUtils}
        />
        <div className={`${namespace}-horizontal-rule`} />
        <AgendaItemBuilderModalGroupsContainer
          agendaItemTemplate={props.agendaItemTemplate}
          setAgendaItemTemplate={props.setAgendaItemTemplate}
          fieldUtils={fieldUtils}
        />
      </div>
    </div>
  );
};

export default AgendaItemBuilderModalBuilderContainer;
