import React from "react";
import "./AgendaItemBuilderModalPreviewContainer.scss";
import { InfoIcon } from "@opsdti-global-component-library/amgen-design-system";
import { AgendaItemModalHeaderContainer } from "components/agenda-item-modal/header-container/AgendaItemModalHeaderContainer";
import { AgendaItemModalGroupsContainer } from "components/agenda-item-modal/groups-container/AgendaItemModalGroupsContainer";
import { AgendaItem } from "api/agenda-item/agenda-item";

const namespace = "rts-pa-agenda-item-builder-modal-preview-container";

type Props = {
  agendaItem: AgendaItem | undefined;
};

export const AgendaItemBuilderModalPreviewContainer = (props: Props) => {
  return (
    <div className={namespace}>
      <div className={`${namespace}-preview-warning`}>
        <InfoIcon height={18} width={18} color="primary" />
        <span>Preview with test data included.</span>
      </div>
      <div className={`${namespace}-header`}>{props.agendaItem?.name}</div>
      <AgendaItemModalHeaderContainer agendaItem={props.agendaItem} />
      <AgendaItemModalGroupsContainer agendaItem={props.agendaItem} />
    </div>
  );
};

export default AgendaItemBuilderModalPreviewContainer;
