import React from "react";
import { BiosimilarsPipelinePlannedMarketReadinessSection } from "api/biosimilars-item/biosimilars-planned-market-readiness-section";
import { AccordionWithStatus } from "common-components/accordion-with-status/AccordionWithStatus";
import getAdvancedTooltipContent from "common-components/advanced-tooltip/getAdvancedTooltipContent";
import BiosimilarsProjectMetric from "./BiosimilarsProjectMetric";
import "./BiosimilarsPlannedMarketReadinessSection.scss";
import { Label } from "common-components";
import { InactiveSection } from "api";

type Props = {
  data: BiosimilarsPipelinePlannedMarketReadinessSection | InactiveSection;
};

const namespace = "rts-pa-biosimilars-modal-planned-market-readiness";

const BiosimilarsPipelineMetricsPlannedMarketReadinessSection = (
  props: Props
) => {
  if (!props.data) {
    return <></>;
  } else if (props.data.status === "inactive") {
    return <AccordionWithStatus title={props.data.title} status="gray" />;
  }
  return (
    <AccordionWithStatus
      title="Planned Market Readiness"
      status={props.data.status}
      className={namespace}
      tooltipContent={getAdvancedTooltipContent(props.data.tooltip)}
      startOpen={true}
    >
      <div className={`${namespace}-project-metrics`}>
        <div className={`${namespace}-header-row`}>
          <Label>BIOSIMILARS PROJECTS</Label>
          <Label>NEXT PLANNED MARKET READINESS</Label>
        </div>
        <div className={`${namespace}-container`}>
          {props.data.projects.map(project => (
            <BiosimilarsProjectMetric
              id={project.id}
              name={project.name}
              readinessDate={project.readinessDate}
              status={project.status}
              statusTooltip={project.statusTooltip}
              region={project.region}
              key={project.id}
            />
          ))}
        </div>
      </div>
    </AccordionWithStatus>
  );
};

export default BiosimilarsPipelineMetricsPlannedMarketReadinessSection;
