import axios from "axios";
import { EsgItem, EsgItemValidator } from "./esg";
import { getAgendaId } from "utils/multitenancy";
import { PipelineItem, PipelineItemValidator } from "./getPipelineItem";
import { CommercialItem, CommercialItemValidator } from "./getCommercialItem";
import { BiosimilarsItem, BiosimilarsItemValidator } from "./biosimilars";
import { TalentDinb, TalentDinbValidator } from "./getTalentDinb";
import { Archetype } from "./common";

type GetAgendaItemCachedResult<T extends Archetype> = T extends "commercial"
  ? CommercialItem
  : T extends "pipeline"
  ? PipelineItem
  : T extends "talent_dinb"
  ? TalentDinb
  : T extends "esg"
  ? EsgItem
  : T extends "biosimilars"
  ? BiosimilarsItem
  : never;

export const getAgendaItemCached = async <T extends Archetype>(
  archetypeV2: T,
  id: number
): Promise<GetAgendaItemCachedResult<T>> => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  const result = await axios.get<GetAgendaItemCachedResult<T>>(
    `${REACT_APP_DOMAIN}/api/v2/${getAgendaId()}/cache/agenda_item?agenda_item_id=${id}`
  );

  // const result = {
  //   data: TestData as unknown as CommercialItem
  // };

  switch (archetypeV2) {
    case "commercial":
      CommercialItemValidator.check(result.data);
      break;
    case "pipeline":
      PipelineItemValidator.check(result.data);
      break;
    case "talent_dinb":
      TalentDinbValidator.check(result.data);
      break;
    case "esg":
      EsgItemValidator.check(result.data); //EsgItem2Validator to come
      break;
    case "biosimilars":
      BiosimilarsItemValidator.check(result.data);
      break;
  }

  return result.data;
};

export const putOfficersGridCache = async (): Promise<void> => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  await axios.put(
    `${REACT_APP_DOMAIN}/api/v2/${getAgendaId()}/cache/update_officers_grid`
  );
};
