import React, { useMemo } from "react";
import "./AIModalSectionTurnover.scss";
import {
  AccordionWithStatus,
  getAdvancedTooltipContent
} from "common-components";
import { AgendaItemSectionTurnover } from "api/agenda-item/sections";
import { colors, format } from "utils";
import dayjs from "dayjs";
import { LineChart } from "@opsdti-global-component-library/amgen-design-system";

const namespace = "rts-pa-agenda-item-modal-section-turnover";

type Props = {
  agendaItemName: string;
  section: AgendaItemSectionTurnover;
};

export const AIModalSectionTurnover = (props: Props) => {
  const chartData = useMemo(() => {
    return props.section.chart.map(i => {
      return {
        label: dayjs(i.label).format("MMM 'YY").toUpperCase(),
        Amgen: i.forecast || undefined,
        [props.agendaItemName]: i.value || undefined
      };
    });
  }, [props.section, props.agendaItemName]);

  const customTicks = [];

  for (let i = 0; i < chartData.length; i += 2) {
    customTicks.push(chartData[i].label || "");
  }

  return (
    <AccordionWithStatus
      title={props.section.title}
      status={props.section.status}
      tooltipContent={getAdvancedTooltipContent(props.section.tooltip)}
      className={namespace}
      links={props.section.links}
      accordionSyncKey="AgendaItemModal"
      accordionSyncId={props.section.id}
    >
      <div className={`${namespace}-label`}>12 Month Rolling Average</div>
      <div>
        <LineChart
          data={chartData}
          xKey="label"
          dataKeys={[
            { lineKey: "Amgen", dot: true, color: colors.primaryBlue },
            {
              lineKey: props.agendaItemName,
              dot: true,
              color: colors.teal
            }
          ]}
          chartHeight={175}
          yAxisWidth={39}
          customXTicks={customTicks}
          showPopover
          showLegend
          xAxisTickFormatter={(value: string | number) => {
            return value.toString().toUpperCase();
          }}
          yAxisTickFormatter={(
            value: string | number | Array<string | number>
          ) => {
            if (typeof value === "number") {
              return format.percentage(value, { decimals: 1 });
            }
            return value.toString();
          }}
        />
      </div>
    </AccordionWithStatus>
  );
};

export default AIModalSectionTurnover;
