import { Literal, Static } from "runtypes";
import { AgendaItemTemplateSectionValidator } from "../agenda-item-template-common";

export const AgendaItemTemplateSectionInventoryBelowTargetValidator =
  AgendaItemTemplateSectionValidator.extend({
    type: Literal("inventoryBelowTarget")
  });

export type AgendaItemTemplateSectionInventoryBelowTarget = Static<
  typeof AgendaItemTemplateSectionInventoryBelowTargetValidator
>;
