import React, { useContext } from "react";
import PermissionsWrapper from "common-components/permissions-wrapper/permissions-wrapper";
import { PermissionsContext } from "global/permissions";
import "./KeyEventsPageHeader.scss";
import pages from "pages/pages";
import ToggleButton from "common-components/toggle-button/ToggleButton";
import useLimitedView from "global/use-limited-view";
import Button from "common-components/button/Button";
import { PlusIcon } from "@opsdti-global-component-library/amgen-design-system";
import CalendarKeys from "components/calendar-keys/CalendarKeys";
import { useNavigate } from "react-router-dom";
import TenantSelector from "components/tenant-selector/TenantSelector";
import { DateRangePickerSimple } from "common-components";
import { Dayjs } from "dayjs";
import { useCalendarModeHelper } from "global/use-key-events-calendar-mode-helper";
import { exportMilestonesToCSV } from "./exportMilestonesToCSV";
import { useCalendarContext } from "../CalendarContext";
import { getMilestoneCalendarKeys } from "api";
import { useQuery } from "@tanstack/react-query";

const namespace = "rts-pa-key-events-page-header";

export type Props = {
  dateRange: [Dayjs, Dayjs] | [];
  onDateRangeChange: (dateRange: [Dayjs, Dayjs] | []) => void;
  onReorderSave: (() => void) | undefined;
};

export default function KeyEventsPageHeader(props: Props): JSX.Element {
  const { calendarRows: calendarRowsData } = useCalendarContext();
  const calendarMode = useCalendarModeHelper();
  const perms = useContext(PermissionsContext).keyEvents;

  const { isLimitedView, toggleLimitedView } = useLimitedView(pages.keyEvents);
  const navigate = useNavigate();

  const { data: apiCalendarKeys } = useQuery(
    ["calendar-keys"],
    getMilestoneCalendarKeys
  );

  return (
    <div className={namespace}>
      <div className={`${namespace}-row`}>
        <div className={`${namespace}-left-container`}>
          <TenantSelector />
        </div>
        <div className={`${namespace}-right-container`}>
          <PermissionsWrapper permissions={perms.edit}>
            <ToggleButton
              firstLabel="FULL VIEW"
              secondLabel="LIMITED VIEW"
              checked={isLimitedView}
              onClick={toggleLimitedView}
            />
          </PermissionsWrapper>
        </div>
      </div>
      <div className={`${namespace}-row`}>
        <div className={`${namespace}-left-container`}>
          <PermissionsWrapper permissions={perms.edit}>
            <Button
              disabled={calendarMode.isRearrangeMode}
              text="Add Row"
              icon={<PlusIcon height={14} />}
              onClick={() => navigate(pages.keyEvents.addItemModal.go())}
            />
            {calendarMode.isRearrangeMode ? (
              <Button text="Save" onClick={props.onReorderSave} />
            ) : (
              <Button
                text="Rearrange"
                type="secondary"
                onClick={() => {
                  navigate(pages.keyEvents.rearrange.go());
                }}
              />
            )}
          </PermissionsWrapper>
          <DateRangePickerSimple
            onChange={props.onDateRangeChange}
            value={props.dateRange}
            disabled={calendarMode.isRearrangeMode}
          />
          {calendarMode.isRearrangeMode && (
            <PermissionsWrapper permissions={perms.edit}>
              <Button
                text="cancel rearrange"
                type="link"
                onClick={() => {
                  navigate(pages.keyEvents.go());
                }}
              />
            </PermissionsWrapper>
          )}
        </div>
        <div>
          <PermissionsWrapper permissions={perms.edit}>
            <Button
              disabled={calendarMode.isRearrangeMode}
              text="Export to CSV"
              type="secondary"
              onClick={() =>
                exportMilestonesToCSV(calendarRowsData, apiCalendarKeys)
              }
            />
          </PermissionsWrapper>
        </div>
      </div>
      <div className={`${namespace}-row`}>
        <div className={`${namespace}-left-container`}>
          <CalendarKeys />
        </div>
      </div>
    </div>
  );
}
