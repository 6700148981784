import React from "react";
import { KeyEventsIconProps } from "./common-props";

export const ThreeQuarterCircleIcon = (props: KeyEventsIconProps) => {
  const { width = 15, height = 14, className, fill = "#FDB81E" } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 14C11.366 14 14.5 10.866 14.5 7L7.5 7L7.5 0C3.63401 0 0.5 3.13401 0.5 7C0.5 10.866 3.63401 14 7.5 14Z"
        fill={fill}
      />
    </svg>
  );
};

export default ThreeQuarterCircleIcon;
