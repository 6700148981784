import { Literal, Static, String } from "runtypes";
import { AgendaItemTemplateSectionValidator } from "../agenda-item-template-common";

export const AgendaItemTemplateSectionLocationValidator =
  AgendaItemTemplateSectionValidator.extend({
    type: Literal("location"),
    locationId: String
  });

export type AgendaItemTemplateSectionLocation = Static<
  typeof AgendaItemTemplateSectionLocationValidator
>;
