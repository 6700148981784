import { Owners } from "api/common";
import classNames from "classnames";
import React from "react";
import "./OwnersContainer.scss";
import { Label, Text } from "common-components";
import { getAgendaId } from "utils/multitenancy";

const namespace = "rts-pa-owners-container";

export type Props = {
  owners: Owners | undefined | null;
  className?: string;
  hideOnEmpty?: boolean;
};

export const OwnersContainer = (props: Props) => {
  if (
    props.hideOnEmpty &&
    !props.owners?.ceoStaffSponsors.length &&
    !props.owners?.operatingTeamOwners.length
  ) {
    return <></>;
  }

  const agendaId = getAgendaId();
  const className = classNames(namespace, props.className);

  return (
    <div className={className}>
      <Label className="rts-pa-owners-container-label">
        {agendaId === "1" ? "CEO Staff Sponsor" : "Sponsor"}
      </Label>
      <Label className="rts-pa-owners-container-label">
        {agendaId === "1" ? "Operating Team Owner" : "Owner"}
      </Label>
      {props.owners?.ceoStaffSponsors?.length ? (
        <div className="rts-pa-owners-container-column">
          {props.owners.ceoStaffSponsors.map((owner, i) => (
            <div key={owner.id}>
              <Text>{owner.text}</Text>
            </div>
          ))}
        </div>
      ) : (
        <div>
          <Text>N/A</Text>
        </div>
      )}

      {props.owners?.operatingTeamOwners?.length ? (
        <div className="rts-pa-owners-container-column">
          {props.owners.operatingTeamOwners.map((owner, i) => (
            <div key={owner.id}>
              <Text>{owner.text}</Text>
            </div>
          ))}
        </div>
      ) : (
        <div>
          <Text>N/A</Text>
        </div>
      )}
    </div>
  );
};

export default OwnersContainer;
