import { Array, Literal, Number, Record, Static, String } from "runtypes";
import { AgendaItemSectionValidator } from "../agenda-item-common";
import { StatusValidator, LinkValidator } from "api/common";

export const AgendaItemSectionInventoryBelowTargetFdpValidator = Record({
  value: Number,
  status: StatusValidator,
  statusText: String,
  reasonCodes: Array(String),
  countries: Array(String)
});

export type AgendaItemSectionInventoryBelowTargetFdp = Static<
  typeof AgendaItemSectionInventoryBelowTargetFdpValidator
>;

export const AgendaItemSectionInventoryBelowTargetValidator =
  AgendaItemSectionValidator.extend({
    type: Literal("inventoryBelowTarget"),
    fdp: AgendaItemSectionInventoryBelowTargetFdpValidator,
    links: Array(LinkValidator)
  });

export type AgendaItemSectionInventoryBelowTarget = Static<
  typeof AgendaItemSectionInventoryBelowTargetValidator
>;
