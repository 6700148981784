import { AgendaItemSectionTypeValidator } from "api/agenda-item/agenda-item-common";
import { Record, Static, String } from "runtypes";

export const AgendaItemTemplateSectionValidator = Record({
  id: String, //uuid
  title: String,
  type: AgendaItemSectionTypeValidator
});

export type AgendaItemTemplateSection = Static<
  typeof AgendaItemTemplateSectionValidator
>;
