import React from "react";
import { QueryStatus } from "@tanstack/react-query";
import "./PaModal.scss";
import classNames from "classnames";
import SimpleBar from "simplebar-react";
import PaLoader from "components/pa-loader/PaLoader";
import {
  LinkExternalIcon,
  Button,
  Modal
} from "@opsdti-global-component-library/amgen-design-system";

type Props = {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  disableBackdropDismiss?: boolean;
  status: QueryStatus;
  axiosErrors?: unknown | unknown[];
  children: JSX.Element | JSX.Element[];
  button?: {
    label: string;
    href: string;
    isExternal?: boolean;
  };
  className?: string;
  removeScroll?: boolean;
  footer?: JSX.Element; //TODO JCG: merge with new "button" property
  width?: number;
};

export const PaModal = (props: Props) => {
  const className = classNames("rts-pa-modal", props.className);

  let children: JSX.Element | JSX.Element[];

  if (props.removeScroll) {
    children = (
      <div className="rts-pa-modal-noscroll-container">{props.children}</div>
    );
  } else {
    children = (
      <SimpleBar className="rts-pa-fix-vertical-scrollbar-overlap">
        {props.children}
      </SimpleBar>
    );
  }

  const onButtonClick = () => {
    window.open(props.button?.href, "_blank");
  };

  return (
    <Modal
      open={props.isOpen}
      onCancel={props.onClose}
      className={className}
      title={props.title}
      width={props.width || 570}
      maskCloseable={props.disableBackdropDismiss}
      footer={props.footer}
    >
      <div className="rts-pa-modal-container">
        <PaLoader
          status={props.status}
          axiosErrors={props.axiosErrors}
          componentName="modal"
        >
          {children}
        </PaLoader>

        {props.button && (
          <Button
            text={props.button.label}
            onClick={onButtonClick}
            type="secondary"
            className="footer"
            icon={
              props.button.isExternal ? (
                <LinkExternalIcon width={12} height={12} />
              ) : undefined
            }
          />
        )}
      </div>
    </Modal>
  );
};

export default PaModal;