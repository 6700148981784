import React from "react";
import "./InputListItem.scss";
import { Input } from "common-components";
import { AutoUpdater, AutoUpdaterType } from "global/use-auto-updater";
import { FieldValUtils } from "global/use-validator";
import TrashIcon from "icons/trash-icon";
import DragIcon from "icons/drag-icon";
import { Draggable } from "react-beautiful-dnd";
import classnames from "classnames";

const namespace = "rts-pa-input-list-item";

export type Props<T extends AutoUpdaterType> = {
  id: string;
  placeholder?: string;
  className?: string;
  autoUpdater: AutoUpdater<T>;
  autoUpdaterIndex: number;
  validator: FieldValUtils;
  onDeleteClick: (i: number) => void;
};

export const InputListItem = <T extends AutoUpdaterType>(
  props: Props<T>
): JSX.Element => {
  return (
    // JCG: .toString() since technically IDs are numbers, but FE-created temp IDs are UUID strings
    <Draggable draggableId={props.id.toString()} index={props.autoUpdaterIndex}>
      {(provided, snapshot) => {
        const rootClassName = classnames(namespace, {
          isDragging: snapshot.isDragging
        });

        return (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            style={provided.draggableProps.style}
            className={rootClassName}
          >
            <div className={`${namespace}-inputs`}>
              <Input
                id={`${props.id}-text`}
                placeholder={props.placeholder}
                autoUpdater={props.autoUpdater}
                validator={props.validator}
              />
            </div>
            <div className={`${namespace}-controls`}>
              <TrashIcon
                className="trash-icon"
                height={15}
                width={16}
                onClick={() => props.onDeleteClick(props.autoUpdaterIndex)}
              />
              <div
                className={`${namespace}-drag`}
                {...provided.dragHandleProps}
              >
                <DragIcon className="drag-icon" height={12} width={16} />
              </div>
            </div>
          </div>
        );
      }}
    </Draggable>
  );
};

export default InputListItem;
