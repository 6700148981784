import React from "react";
import "./PipelineProjectStudyEnrollments.scss";
import {
  AdvancedTooltip as AdvancedTooltipType,
  PipelineStudyEnrollment
} from "api";
import dayjs from "dayjs";
import PipelineEnrollmentStatusWithTooltip from "common-components/pipeline-enrollment-status-with-tooltip/PipelineEnrollmentStatusWithTooltip";
import { AdvancedTooltip } from "common-components/advanced-tooltip/AdvancedTooltip";
import { Label, Text } from "common-components";

const namespace = "rts-pa-pipeline-project-study-enrollments";

const infoTooltip: AdvancedTooltipType = {
  sections: [
    {
      rowSpacing: "medium",
      rows: [
        { label: "Enrollment Status Indicator Definitions", content: [] },
        {
          content: [
            {
              type: "icon",
              icon: "green"
            },
            {
              type: "space",
              spaces: 2
            },
            "Greater than or equal to 90%"
          ]
        },
        {
          content: [
            {
              type: "icon",
              icon: "yellow"
            },
            {
              type: "space",
              spaces: 2
            },
            "Between 75%-89%"
          ]
        },
        {
          content: [
            {
              type: "icon",
              icon: "red"
            },
            {
              type: "space",
              spaces: 2
            },
            "Below 75%"
          ]
        },
        {
          content: [
            {
              type: "icon",
              icon: "gray"
            },
            {
              type: "space",
              spaces: 2
            },
            "Missing Data"
          ]
        },
        { content: ["Enrollment Complete"] },
        { content: ["Enrollment Not Started"] },
        { label: "Data Source:", content: ["Enrollment Indicators Dashboard"] }
      ]
    }
  ]
};

type Props = {
  data: PipelineStudyEnrollment[] | undefined;
};

const PipelineProjectStudyEnrollments = (props: Props) => {
  if (!props.data) {
    return <></>;
  }

  return (
    <div className={namespace}>
      <div className={`${namespace}-title`}>
        <Label>Priority Study Critical Path Enrollment</Label>
        <AdvancedTooltip tooltip={infoTooltip} />
      </div>

      <div className={`${namespace}-content`}>
        {props.data.length ? (
          props.data.map((enr, i) => {
            const enrNamespace = `${namespace}-enrollment`;

            return (
              <div key={i} className={enrNamespace}>
                <Text strong>{enr.studyShortDescription}</Text>
                <div className={`${enrNamespace}-columns`}>
                  <div className={`${enrNamespace}-columns-left`}>
                    <Label>{enr.dateType}</Label>
                    <Text className={`${enrNamespace}-columns-left-date-value`}>
                      {dayjs(enr.date).format("MM/DD/YYYY")}
                    </Text>
                  </div>
                  <div className={`${enrNamespace}-columns-right`}>
                    <PipelineEnrollmentStatusWithTooltip
                      status={enr.enrStatus}
                      tooltip={enr.enrStatusTooltip}
                    />
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <Text>No priority studies currently available.</Text>
        )}
      </div>
    </div>
  );
};

export default PipelineProjectStudyEnrollments;
