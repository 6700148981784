import React from "react";
import { KeyEventsIconProps } from "./common-props";

export const PentagonIcon = (props: KeyEventsIconProps) => {
  const { width = 15, height = 14, className, fill = "#00BCE4" } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M7.33301 0L14.333 5.34752L11.6592 14H3.00677L0.333008 5.34752L7.33301 0Z"
        fill={fill}
      />
    </svg>
  );
};

export default PentagonIcon;
