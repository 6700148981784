type FormatPercentageOptions = {
  decimals?: number;
  showPlusSign?: boolean;
};

export const formatPercentage = (
  num: number | string,
  options?: FormatPercentageOptions
): string => {
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: options?.decimals,
    maximumFractionDigits: options?.decimals
  });

  const value = typeof num === "string" ? parseFloat(num) : num;

  let result = `${formatter.format(value)}%`;

  if (options?.showPlusSign && value > 0) {
    result = `+${result}`;
  }

  return result;
};

export const formatPoints = (num: number): string => {
  return num.toFixed(1) + " pts";
};

type FormatMoneyOptions = Omit<
  FormatSimpleOptions,
  "stripTrailingZeroDecimals"
>;

export const formatMoney = (
  num: number,
  options?: FormatMoneyOptions
): string => {
  // default to true for money
  if (!options) {
    // eslint-disable-next-line no-param-reassign
    options = { padZeroIfDecimal: true };
  } else if (options.padZeroIfDecimal === undefined) {
    // eslint-disable-next-line no-param-reassign
    options.padZeroIfDecimal = true;
  }

  // Should have two decimals by default when number below 1000
  if (num === 0) {
    // eslint-disable-next-line no-param-reassign
    options.decimals = 0;
  } else if (
    options?.decimals === undefined &&
    options?.precision === undefined &&
    parseFloat(Math.abs(num).toFixed(2)) < 1000 &&
    num !== 0
  ) {
    // eslint-disable-next-line no-param-reassign
    options.decimals = 2;
  }

  return `$${formatSimple(num, options)}`.replace(
    /\$-([0-9,]+\.?\d*\w?)/,
    "-$$$1"
  );
};

type FormatSimpleOptions = {
  decimals?: number;
  precision?: "base" | "K" | "M" | "B" | "T" | "P" | "E";
  stripTrailingZeroDecimals?: boolean;
  showPlusSign?: boolean;
  padZeroIfDecimal?: boolean;
};

export function formatSimple(
  num: number,
  options?: FormatSimpleOptions
): string {
  let precisionDetails = findPrecisionDetails(num, options?.precision);

  const originalOrderOfMagnitude = Math.floor(Math.log10(Math.abs(num || 1)));
  let reducedNum = num / precisionDetails.threshold;

  // if order of magnitude increases due to rounding, recheck the precision
  const roundedReducedNum = parseFloat(
    reducedNum.toFixed(
      options?.decimals
        ? options?.decimals
        : precisionDetails.threshold === 1
        ? 0
        : 2
    )
  );
  const reducedOrderOfMagnitude = Math.floor(
    Math.abs(Math.log10(Math.abs(reducedNum || 1)))
  );
  const roundedOrderOfMagnitude = Math.floor(
    Math.log10(Math.abs(roundedReducedNum || 1))
  );
  // If a precision IS NOT specified and the rounded number has gone up an order of magnitude,
  // we may need to simplify/reduce the number further and determine a new suffix. Ex: 999.99 -> 1K
  // If a precision IS specified, there is no need to simplify/reduce further as we have already
  // reduced relative to the specified precision
  if (
    !options?.precision &&
    roundedOrderOfMagnitude > reducedOrderOfMagnitude
  ) {
    precisionDetails = findPrecisionDetails(roundedReducedNum, undefined);
    reducedNum = roundedReducedNum / precisionDetails.threshold;

    // we've increased order of magnitude, determine if a new postfix is needed
    const temp = 10 ** (roundedOrderOfMagnitude + originalOrderOfMagnitude);
    precisionDetails = findPrecisionDetails(temp, options?.precision);
  }

  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits:
      options?.decimals === undefined
        ? options?.padZeroIfDecimal && !Number.isInteger(reducedNum)
          ? 2
          : 0
        : options?.decimals,
    maximumFractionDigits:
      options?.decimals === undefined ? 2 : options?.decimals,
    signDisplay: options?.showPlusSign ? "exceptZero" : undefined
  });

  let formattedNum = formatter.format(reducedNum);
  if (options?.stripTrailingZeroDecimals) {
    formattedNum = formattedNum.replace(/\.0*$|(\.\d+?)0*$/, "$1");
  }
  return `${formattedNum}${precisionDetails.postfix}`;
}

function findPrecisionDetails(num: number, precision: string | undefined) {
  const numberEndings = [
    { threshold: 1, postfix: "" },
    { threshold: 1e3, postfix: "K" },
    { threshold: 1e6, postfix: "M" },
    { threshold: 1e9, postfix: "B" },
    { threshold: 1e12, postfix: "T" },
    { threshold: 1e15, postfix: "P" },
    { threshold: 1e18, postfix: "E" }
  ];

  let index;
  if (precision === "base") {
    index = 0;
  } else {
    for (index = numberEndings.length - 1; index > 0; index--) {
      if (precision === numberEndings[index].postfix) {
        break;
      } else if (!precision) {
        if (Math.abs(num) >= numberEndings[index].threshold) {
          break;
        }
      }
    }
  }
  return numberEndings[index];
}

export const format = {
  percentage: formatPercentage,
  points: formatPoints,
  money: formatMoney,
  simple: formatSimple
};

export default format;
