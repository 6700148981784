import React from "react";
import "./ExternalLinkInput.scss";
import classNames from "classnames";
import {
  AutoUpdaterPropExpression,
  AutoUpdaterSetItem,
  AutoUpdaterType
} from "global/use-auto-updater";
import { FieldValUtils } from "global/use-validator";
import { Input, Label } from "common-components";

type Props<T extends AutoUpdaterType> = {
  className?: string;
  autoUpdater: {
    item: T;
    propExpressionLabel: AutoUpdaterPropExpression<T>;
    propExpressionHref: AutoUpdaterPropExpression<T>;
    setItem: AutoUpdaterSetItem<T>;
  };
  autoUpdaterIndex: number;
  validator?: FieldValUtils;
  required?: boolean;
};

export const ExternalLinkInput = <T extends AutoUpdaterType>(
  props: Props<T>
) => {
  const label = `External Link: ${props.autoUpdaterIndex + 1}`;
  const textLabel = `${label} - Text`;
  const urlLabel = `${label} - URL`;

  const classname = classNames("rts-pa-external-link-input", props.className);

  return (
    <div className={classname}>
      <Label>{label}</Label>
      <Input
        placeholder="Text"
        errorLabel={textLabel}
        autoUpdater={{
          ...props.autoUpdater,
          propExpression: props.autoUpdater.propExpressionLabel
        }}
        validator={props.validator}
        required={props.required}
      />
      <Input
        placeholder="URL (should include https://)"
        errorLabel={urlLabel}
        autoUpdater={{
          ...props.autoUpdater,
          propExpression: props.autoUpdater.propExpressionHref
        }}
        validator={props.validator}
        required={props.required}
      />
    </div>
  );
};

export default ExternalLinkInput;
