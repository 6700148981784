import React from "react";
import {
  Text as TextCompLib,
  TextProps,
  theme
} from "@opsdti-global-component-library/amgen-design-system";

const { token } = theme;

type LibTextType = Exclude<TextProps["type"], undefined>;
type TextType =
  | LibTextType
  | "blue"
  | "notAvailable"
  | "red"
  | "yellow"
  | "green"
  | "gray"
  | null;

type Props = Omit<TextProps, "type"> & {
  children?: React.ReactNode;
  keepSpaceWhenEmpty?: boolean;
  type?: TextType;
};

export const Text = ({
  children,
  type,
  keepSpaceWhenEmpty,
  style,
  ...rest
}: Props) => {
  if (!children) {
    if (keepSpaceWhenEmpty) {
      return <TextCompLib {...rest}>&nbsp;</TextCompLib>;
    }
    return null;
  }

  const textTypeMap = {
    default: undefined,
    secondary: undefined,
    success: undefined,
    warning: undefined,
    danger: undefined,
    yellow: "warning",
    red: "danger",
    green: "success",
    gray: "secondary",
    blue: token?.colorPrimary,
    notAvailable: token?.colorTextDisabled
  };

  let color, textType;
  if (!type || !textTypeMap[type]) {
    textType = type;
    color = undefined;
  } else if (["yellow", "red", "green", "gray"].includes(type)) {
    textType = textTypeMap[type];
    color = undefined;
  } else if (["blue", "notAvailable"].includes(type)) {
    textType = undefined;
    color = textTypeMap[type];
  }

  return (
    <TextCompLib
      {...rest}
      style={style ? style : { color: color }}
      type={textType as TextProps["type"]}
    >
      {children}
    </TextCompLib>
  );
};

export default Text;
