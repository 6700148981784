import {
  AdvancedTooltipValidator,
  ChartItemValidator,
  InactiveSectionValidator,
  LinkValidator,
  StatusValidator
} from "api/common";
import { Array, Boolean, Record, Static, String } from "runtypes";

const TalentDinbTalentAmgenOverallEngagementValidator = Record({
  status: StatusValidator,
  title: String,
  tooltip: AdvancedTooltipValidator,
  chart: Array(ChartItemValidator),
  links: Array(LinkValidator),
  statusDriver: Boolean
});

export type TalentDinbTalentAmgenOverallEngagement = Static<
  typeof TalentDinbTalentAmgenOverallEngagementValidator
>;

const TalentDinbTalentAmgenTotalTurnoverValidator = Record({
  status: StatusValidator,
  title: String,
  tooltip: AdvancedTooltipValidator,
  chartTooltip: AdvancedTooltipValidator,
  chart: Array(ChartItemValidator),
  links: Array(LinkValidator),
  statusDriver: Boolean
});

export type TalentDinbTalentAmgenTotalTurnover = Static<
  typeof TalentDinbTalentAmgenTotalTurnoverValidator
>;

export const TalentDinbTalentSectionValidator = Record({
  amgenOverallEngagement: TalentDinbTalentAmgenOverallEngagementValidator.Or(InactiveSectionValidator).nullable(),
  amgenTotalTurnover: TalentDinbTalentAmgenTotalTurnoverValidator.Or(InactiveSectionValidator).nullable()
});

export type TalentDinbTalentSection = Static<
  typeof TalentDinbTalentSectionValidator
>;
