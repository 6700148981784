import React, { useCallback, useEffect } from "react";
import "./Checkbox.scss";
import classNames from "classnames";
import {
  AutoUpdater,
  AutoUpdaterId,
  AutoUpdaterType,
  useAutoUpdater
} from "global/use-auto-updater";
import { FieldValUtils, ValidationError } from "global/use-validator";
import {
  Checkbox as DSCheckbox,
  CheckboxChangeEvent
} from "@opsdti-global-component-library/amgen-design-system";

const namespace = "rts-pa-checkbox";

type Props<T extends AutoUpdaterType> = {
  id?: AutoUpdaterId;
  label?: string;
  errorLabel?: string;
  className?: string;
  required?: boolean;
  placeholder?: string;
  autoUpdater: AutoUpdater<T>;
  validator?: FieldValUtils;
};

export const Checkbox = <T extends AutoUpdaterType>(props: Props<T>) => {
  const { id, value, onChange } = useAutoUpdater<boolean, T>(
    props.autoUpdater,
    props.id
  );

  const validate = useCallback(
    (val: boolean) => {
      if (!props.validator) {
        return;
      }

      const errors: ValidationError[] = [];

      if (props.required && !val) {
        const fieldName = props.errorLabel || props.label || props.placeholder;

        errors.push({
          fieldError: `${fieldName || "This field"} is required.`,
          fullError: `${fieldName || id} is required.`
        });
      }

      props.validator.setErrors(id, errors);
    },
    [props.required, props.validator] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    if (!props.validator) {
      return;
    }

    const unregister = props.validator.registerValidation(id, () =>
      validate(value)
    );

    return () => {
      unregister();
    };
  }, [value, validate]); // eslint-disable-line react-hooks/exhaustive-deps

  const onChangeWrapper = (e: CheckboxChangeEvent) => {
    const val = e.target.checked;
    onChange(val);
    validate(val);
  };

  const errors = props.validator?.errors.get(id) || [];
  const className = classNames(namespace, props.className, {
    error: !!errors.length
  });

  return (
    <div className={className}>
      <div className={`${namespace}-main-container`}>
        <DSCheckbox checked={value} onChange={onChangeWrapper}>
          {props.label}
          {props.required && <span className="required-asterisk"> *</span>}
        </DSCheckbox>
      </div>
      {!!errors.length && (
        <div className={`${namespace}-errors-container`}>
          {errors.map((e, i) => (
            <div key={i} className="error-item">
              {e.fieldError}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Checkbox;
