import React, { useMemo } from "react";
import "./ComModalPeopleTurnover.scss";
import {
  CommercialPeopleInactiveSection,
  CommercialPeopleTurnoverSection
} from "api";
import { AccordionWithStatus } from "common-components/accordion-with-status/AccordionWithStatus";
import { colors, format } from "utils";
import dayjs from "dayjs";
import getAdvancedTooltipContent from "common-components/advanced-tooltip/getAdvancedTooltipContent";
import { Label } from "common-components";
import { LineChart } from "@opsdti-global-component-library/amgen-design-system";

const namespace = "rts-pa-commercial-modal-people-turnover";

type Props = {
  agendaItemTitle: string;
  data:
    | CommercialPeopleTurnoverSection
    | CommercialPeopleInactiveSection
    | undefined
    | null;
};

const ComModalPeopleTurnover = (props: Props) => {
  const chartData = useMemo(() => {
    if (props.data?.status === "inactive" || !props.data?.chart) {
      return [];
    }

    return props.data?.chart.map(i => {
      return {
        label: dayjs(i.label).format("MMM 'YY").toUpperCase(),
        Amgen: i.forecast || undefined,
        [props.agendaItemTitle]: i.value
      };
    });
  }, [props.data, props.agendaItemTitle]);

  const title = "Turnover";

  if (!props.data) {
    return <></>;
  } else if (props.data.status === "inactive") {
    return (
      <AccordionWithStatus
        title={title}
        status="gray"
        tooltipContent={getAdvancedTooltipContent(props.data.tooltip)}
      />
    );
  }

  const customTicks = [];

  for (let i = 0; i < chartData.length; i += 2) {
    customTicks.push(chartData[i].label || "");
  }

  return (
    <AccordionWithStatus
      status={props.data.status}
      tooltipContent={getAdvancedTooltipContent(props.data.tooltip)}
      title={title}
      className={namespace}
      links={props.data.externalLinks}
      accordionSyncKey="CommercialModal"
    >
      <Label className={`${namespace}-label`}>12 Month Rolling Average</Label>
      <div className={`${namespace}-chart-container`}>
        <LineChart
          data={chartData}
          xKey="label"
          dataKeys={[
            { lineKey: "Amgen", dot: true, color: colors.primaryBlue },
            {
              lineKey: props.agendaItemTitle,
              dot: true,
              color: colors.teal
            }
          ]}
          chartHeight={175}
          yAxisWidth={39}
          customXTicks={customTicks}
          showPopover
          showLegend
          xAxisTickFormatter={(value: string | number) => {
            return value.toString().toUpperCase();
          }}
          yAxisTickFormatter={(
            value: string | number | Array<string | number>
          ) => {
            if (typeof value === "number") {
              return format.percentage(value, { decimals: 1 });
            }
            return value.toString();
          }}
        />
      </div>
    </AccordionWithStatus>
  );
};

export default ComModalPeopleTurnover;
