import React from "react";
import "./AIModalSectionTalentAcquisition.scss";
import {
  AccordionWithStatus,
  getAdvancedTooltipContent
} from "common-components";
import { AgendaItemSectionTalentAcquisition } from "api/agenda-item/sections";

const namespace = "rts-pa-agenda-item-modal-section-talent-acquisition";

type Props = {
  agendaItemName: string;
  section: AgendaItemSectionTalentAcquisition;
};

export const AIModalSectionTalentAcquisition = (props: Props) => {
  return (
    <AccordionWithStatus
      title={props.section.title}
      status={props.section.status}
      tooltipContent={getAdvancedTooltipContent(props.section.tooltip)}
      className={namespace}
      links={props.section.links}
      accordionSyncKey="AgendaItemModal"
      accordionSyncId={props.section.id}
    >
      <div className={`${namespace}-metrics`}>
        <div />
        <div className={`${namespace}-metrics-label-col`}>
          {props.agendaItemName}
        </div>
        <div className={`${namespace}-metrics-label-col`}>amgen</div>
        <div className={`${namespace}-metrics-label-row`}>
          Open Requisitions
        </div>
        <div className={`${namespace}-metrics-data`}>
          {props.section.openRequisitions}
        </div>
        <div className={`${namespace}-metrics-data`}>
          {props.section.amgenOpenRequisitions}
        </div>
        <div className={`${namespace}-metrics-label-row`}>
          Average Open Days
        </div>
        <div className={`${namespace}-metrics-data-bold`}>
          {props.section.avgOpenDays}
        </div>
        <div className={`${namespace}-metrics-data`}>
          {props.section.amgenAvgOpenDays}
        </div>
      </div>
    </AccordionWithStatus>
  );
};

export default AIModalSectionTalentAcquisition;
