import { useCallback, useMemo, useRef, useState } from "react";

export type ValidationError = {
  fieldError?: string; //to be displayed under field
  fullError?: string; //to be displayed in collection of errors box
};

type Errors = Map<string, ValidationError[]>;

type ValidationResult = {
  isValid: boolean;
  errors: Errors;
};

type ContainerResult = {
  isValid: boolean;
  errors: Errors;
  triggerValidation: () => Promise<ValidationResult>;
  resetValidation: () => void;
};

export type FieldValUtils = {
  setErrors: (field: string, errors: ValidationError[]) => void;
  errors: Errors;
  registerValidation: (field: string, valFunc: () => void) => () => void;
};

type ValidatorResult = {
  containerUtils: ContainerResult;
  fieldUtils: FieldValUtils;
};

export function useValidator(): ValidatorResult {
  const [isValid, setIsValid] = useState(true);
  const [needUpdate, triggerUpdate] = useState({});
  const _errors = useRef<Errors>(new Map<string, ValidationError[]>());
  const valFuncs = useRef<Map<string, () => void>>(
    new Map<string, () => void>()
  );

  const resetValidation = useCallback(() => {
    _errors.current.forEach((_, field) => {
      _errors.current.set(field, []);
    });

    triggerUpdate({});
  }, []);

  const triggerValidation = useCallback(() => {
    return new Promise<ValidationResult>(resolve => {
      //clear existing errors (might include fields no longer existing)
      _errors.current.forEach((_, field) => {
        _errors.current.set(field, []);
      });

      //run validation logic
      valFuncs.current.forEach(vf => vf());

      //check for errors; remove errors for fields no longer being validated
      let isValidInternal = true;
      _errors.current.forEach((errorArr, field) => {
        if (!valFuncs.current.has(field)) {
          //remove errors from list, and queue up update
          _errors.current.delete(field);
          triggerUpdate({});
        } else if (isValidInternal) {
          //check if any errors
          isValidInternal = errorArr.length === 0;
        }
      });

      setIsValid(isValidInternal);

      resolve({ isValid: isValidInternal, errors: _errors.current });
    });
  }, []);

  const registerValidation = useCallback(
    (field: string, valFunc: () => void) => {
      valFuncs.current.set(field, valFunc);
      return () => valFuncs.current.delete(field);
    },
    []
  );

  return useMemo(() => {
    const setErrors = (field: string, errors: ValidationError[]) => {
      const currentErrors = _errors.current.get(field);
      _errors.current.set(field, errors);

      if (currentErrors && !currentErrors.length && !errors.length) {
        return; //if no errors previously, and no errors passed in, don't trigger refresh
      } else {
        triggerUpdate({});
      }
    };

    return {
      containerUtils: {
        isValid: isValid,
        errors: _errors.current,
        triggerValidation,
        resetValidation
      },
      fieldUtils: {
        setErrors,
        errors: _errors.current,
        registerValidation
      }
    };
  }, [needUpdate]); // eslint-disable-line react-hooks/exhaustive-deps
}
