import classNames from "classnames";
import React from "react";
import "./ModalSection.scss";
import { Label } from "common-components";

export type Props = {
  title: string;
  children: JSX.Element | JSX.Element[];
  className?: string;
};

export const ModalSection = (props: Props) => {
  const className = classNames("rts-pa-modal-section", props.className);

  return (
    <div className={className}>
      <Label>{props.title}</Label>
      <div className="rts-pa-modal-section-content">{props.children}</div>
    </div>
  );
};

export default ModalSection;
