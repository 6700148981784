import React from "react";
import {
  Title as TitleCompLib,
  TitleProps,
  theme
} from "@opsdti-global-component-library/amgen-design-system";

const { token } = theme;

type ColorVariant = "green" | "yellow" | "red" | "gray" | null;

type Props = Omit<TitleProps, "children"> & {
  children?: React.ReactNode;
  keepSpaceWhenEmpty?: boolean;
  color?: ColorVariant;
};

export const Title = ({
  children,
  color,
  style,
  keepSpaceWhenEmpty,
  ...rest
}: Props) => {
  if (!children) {
    if (keepSpaceWhenEmpty) {
      return <TitleCompLib {...rest}>&nbsp;</TitleCompLib>;
    }
    return null;
  }

  const colorMap = {
    default: undefined,
    green: token?.colorSuccessText,
    yellow: token?.colorWarningText,
    red: token?.colorErrorText,
    gray: token?.colorTextDescription
  };

  const titleColor = colorMap[color || "default"];

  return (
    <TitleCompLib {...rest} style={style ? style : { color: titleColor }}>
      {children}
    </TitleCompLib>
  );
};

export default Title;
