import React from "react";
import "./AgendaItemModalHeaderContainer.scss";
import OwnersContainer from "common-components/owners-container/OwnersContainer";
import SummaryInfo from "common-components/summary-info/SummaryInfo";
import { AgendaItem } from "api/agenda-item/agenda-item";

const namespace = "rts-pa-agenda-item-modal-header-container";

type Props = {
  agendaItem: AgendaItem | undefined;
};

export const AgendaItemModalHeaderContainer = (props: Props) => {
  if (!props.agendaItem) {
    return <></>;
  }

  return (
    <div className={namespace}>
      <SummaryInfo data={props.agendaItem.summaryInfo} />
      <OwnersContainer
        owners={props.agendaItem.owners}
        className="agenda-item-modal"
      />
    </div>
  );
};

export default AgendaItemModalHeaderContainer;
