import { Literal, Static, String } from "runtypes";
import { AgendaItemTemplateSectionValidator } from "../agenda-item-template-common";

export const AgendaItemTemplateSectionPipelineProjectValidator =
  AgendaItemTemplateSectionValidator.extend({
    type: Literal("pipelineProject"),
    projectId: String
  });

export type AgendaItemTemplateSectionPipelineProject = Static<
  typeof AgendaItemTemplateSectionPipelineProjectValidator
>;
