import React from "react";
import "./ComModalCommonSalesDemandLite.scss";
import { InactiveSection } from "api";
import { AccordionWithStatus } from "common-components/accordion-with-status/AccordionWithStatus";
import { AdvancedTooltip } from "common-components/advanced-tooltip/AdvancedTooltip";
import getAdvancedTooltipContent from "common-components/advanced-tooltip/getAdvancedTooltipContent";
import StatusIcon from "common-components/status-icon/StatusIcon";
import { CommercialCommonSalesDemandLiteSection } from "api/commercial-item/commercial-common";
import { Title } from "common-components";

const namespace = "rts-pa-commercial-modal-common-sales-demand-lite";

type Props = {
  data:
    | CommercialCommonSalesDemandLiteSection
    | InactiveSection
    | undefined
    | null;
};

const ComModalCommonNetSalesDemandLite = (props: Props) => {
  if (!props.data) {
    return <></>;
  } else if (props.data.status === "inactive") {
    return (
      <AccordionWithStatus
        title={props.data.title}
        status="gray"
        tooltipContent={getAdvancedTooltipContent(props.data.tooltip)}
      />
    );
  }

  return (
    <div className={namespace}>
      <div className={`${namespace}-left-container`}>
        <StatusIcon status={props.data.status} />
        <Title level={3}>{props.data.title}</Title>
        <AdvancedTooltip tooltip={props.data.tooltip} />
      </div>
      <div className={`${namespace}-right-container`}>
        <Title level={3} color={props?.data.color}>
          {props.data.value}
        </Title>
      </div>
    </div>
  );
};

export default ComModalCommonNetSalesDemandLite;
