import React from "react";
import "./AgendaItemModalSection.scss";
import { AgendaItemSection } from "api/agenda-item/agenda-item-common";
import AgendaItemModalSectionMapper from "./AgendaItemModalSectionMapper";
import { AgendaItem } from "api/agenda-item/agenda-item";

const namespace = "rts-pa-agenda-item-modal-section";

type Props = {
  agendaItem: AgendaItem;
  section: AgendaItemSection;
};

export const AgendaItemModalSection = (props: Props) => {
  return (
    <div className={namespace}>
      <AgendaItemModalSectionMapper
        agendaItem={props.agendaItem}
        section={props.section}
      />
    </div>
  );
};

export default AgendaItemModalSection;
