import React from "react";
import "./AgendaItemModalGroupsContainer.scss";
import { AgendaItem } from "api/agenda-item/agenda-item";
import AgendaItemModalGroup from "../group/AgendaItemModalGroup";

const namespace = "rts-pa-agenda-item-modal-groups-container";

type Props = {
  agendaItem: AgendaItem | undefined;
};

export const AgendaItemModalGroupsContainer = (props: Props) => {
  if (!props.agendaItem?.groups.length) {
    return <></>;
  }

  return (
    <div className={namespace}>
      {props.agendaItem.groups.map(g => (
        <AgendaItemModalGroup
          key={g.id}
          agendaItem={props.agendaItem as AgendaItem}
          group={g}
        />
      ))}
    </div>
  );
};

export default AgendaItemModalGroupsContainer;
