import React, { ComponentProps } from "react";
import "./StatusIcon.scss";
import { StatusWithAccelerated, PipelineActivityStatus } from "api/common";
import {
  StatusIcon as StatusIconCompLib,
  SvgProps
} from "@opsdti-global-component-library/amgen-design-system";
import classNames from "classnames";

export type Props = {
  status: StatusWithAccelerated | PipelineActivityStatus | "inactive";
} & SvgProps;

export const StatusIcon = (props: Props) => {
  const className = classNames("rts-pa-status-icon", props.className);
  let status: ComponentProps<typeof StatusIconCompLib>["status"];

  switch (props.status.toLowerCase()) {
    case "accelerated":
      status = "star";
      break;
    case "green":
    case "on track":
      status = "success";
      break;

    case "yellow":
    case "subject to change":
      status = "warning";
      break;

    case "red":
    case "delayed":
      status = "error";
      break;

    case "gray":
    case "inactive":
    default:
      status = "missing";
      break;
  }

  return (
    <StatusIconCompLib
      {...props}
      status={status}
      className={className}
      width={18}
      height={18}
    />
  );
};

export default StatusIcon;
