import { ActivityLog as NotificationsActivityLog } from "@gitlab-rtsensing-activity-log/activity-logs-feature";
import { ActivityLogFilterProps } from "@gitlab-rtsensing-activity-log/activity-logs-feature/lib/components/activity-log";
import {
  ActivityLogDataPayload,
  getActivityLogData,
  getActivityLogDropdownValuesData
} from "api/activity-log";
import "./ActivityLog.scss";
import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import PermissionsWrapper from "common-components/permissions-wrapper/permissions-wrapper";
import { PermissionsContext } from "global/permissions";
import { getAgendaId } from "utils/multitenancy";
import useLimitedView from "global/use-limited-view";
import pages from "pages/pages";

const namespace = "rts-pa-activity-log";

const workstream = "Prioritized Agenda";

const defaultActivityLogFilters: ActivityLogDataPayload = {
  workstream: workstream,
  filterList: [
    {
      filterKey: "item_id",
      filterValue: "All"
    },
    {
      filterKey: "status",
      filterValue: "All"
    },
    {
      filterKey: "timeline",
      filterValue: "Last 60 Days"
    }
  ]
};

export const ActivityLog = () => {
  const perms = useContext(PermissionsContext);
  const agendaId = getAgendaId();
  const hasPerms =
    agendaId === "1" && perms.grid.activityLog && perms.grid.status;

  const [filtersSelection, setFiltersSelection] =
    useState<ActivityLogDataPayload>(defaultActivityLogFilters);

  const [isFirstDataLoad, setIsFirstDataLoad] = useState(true);

  const onFilterValueChange = (
    activityLogFilters: ActivityLogFilterProps[]
  ) => {
    setFiltersSelection(prevPayload => ({
      ...prevPayload,
      filterList: activityLogFilters.map(item => ({
        filterKey: item.filterKey,
        filterValue: item.filterValue
      }))
    }));
  };

  const { data: dropdownOptions, isLoading: isDropdownLoading } = useQuery(
    ["activityLogDropdownOptions"],
    () => getActivityLogDropdownValuesData({ workstream: workstream }),
    {
      enabled: hasPerms
    }
  );

  const {
    data: activityLogData,
    refetch,
    isLoading: isActivityLogDataLoading
  } = useQuery(
    ["activityLogData", filtersSelection],
    () => getActivityLogData(filtersSelection),
    {
      enabled: hasPerms
    }
  );

  const { isLimitedView } = useLimitedView(pages.grid);

  useEffect(() => {
    if (hasPerms) {
      refetch();
    }
  }, [filtersSelection, refetch, hasPerms]);

  useEffect(() => {
    if (isFirstDataLoad && !isActivityLogDataLoading) {
      setIsFirstDataLoad(false);
    }
  }, [isActivityLogDataLoading, isFirstDataLoad]);

  // TODO: ASD - Cleanup once API removes duplicates
  useEffect(() => {
    if (!dropdownOptions) {
      return;
    }

    dropdownOptions.dropdownValues.item = Array.from(
      new Set(dropdownOptions.dropdownValues.item)
    );
  }, [dropdownOptions]);

  return (
    <PermissionsWrapper permissions={hasPerms && !isLimitedView}>
      <div className={`${namespace}-container`}>
        <NotificationsActivityLog
          title="Recent Updates"
          workstream={workstream}
          timelineSize={3}
          loading={isFirstDataLoad}
          drawerLoading={isActivityLogDataLoading || isDropdownLoading}
          aiSummary={activityLogData?.aiSummary}
          timelineData={activityLogData?.timelineData || []}
          dropdownMenuOptions={dropdownOptions}
          onFilterChange={onFilterValueChange}
        />
      </div>
    </PermissionsWrapper>
  );
};
