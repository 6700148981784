import { Array, Number, Record, Static, String } from "runtypes";
import {
  ArchetypeValidator,
  StatusTypeValidator,
  StatusValidator
} from "../common";
import {
  AgendaItemTemplateSectionEngagementScoreValidator,
  AgendaItemTemplateSectionInventoryBelowTargetValidator,
  AgendaItemTemplateSectionLocationValidator,
  AgendaItemTemplateSectionPipelineProjectValidator,
  AgendaItemTemplateSectionPlaceholderValidator,
  AgendaItemTemplateSectionSalesDemandValidator,
  AgendaItemTemplateSectionSingleMetricValidator,
  AgendaItemTemplateSectionTalentAcquisitionValidator,
  AgendaItemTemplateSectionTurnoverValidator
} from "./sections";

export const AgendaItemTemplateGroupValidator = Record({
  id: String, //uuid
  label: String,
  sections: Array(
    AgendaItemTemplateSectionPlaceholderValidator.Or(
      AgendaItemTemplateSectionSingleMetricValidator
    )
      .Or(AgendaItemTemplateSectionSalesDemandValidator)
      .Or(AgendaItemTemplateSectionPipelineProjectValidator)
      .Or(AgendaItemTemplateSectionLocationValidator)
      .Or(AgendaItemTemplateSectionInventoryBelowTargetValidator)
      .Or(AgendaItemTemplateSectionEngagementScoreValidator)
      .Or(AgendaItemTemplateSectionTalentAcquisitionValidator)
      .Or(AgendaItemTemplateSectionTurnoverValidator)
  )
});

export type AgendaItemTemplateGroup = Static<
  typeof AgendaItemTemplateGroupValidator
>;

export const AgendaItemTemplateValidator = Record({
  id: Number,
  name: String,
  archetype: ArchetypeValidator,
  linkId: String.nullable(),
  status: StatusValidator.nullable(),
  statusType: StatusTypeValidator,
  groups: Array(AgendaItemTemplateGroupValidator)
});

export type AgendaItemTemplate = Static<typeof AgendaItemTemplateValidator>;
