import React from "react";
import "./TalentDinbModalTalentAmgenOverallEngagement.scss";
import { colors } from "utils";
import getAdvancedTooltipContent from "common-components/advanced-tooltip/getAdvancedTooltipContent";
import { TalentDinbTalentAmgenOverallEngagement } from "api/talent-dinb/talent-dinb-talent";
import { AccordionWithStatus } from "common-components/accordion-with-status/AccordionWithStatus";
import { LineChart } from "@opsdti-global-component-library/amgen-design-system";
import { InactiveSection } from "api/common";

type Props = {
  data: TalentDinbTalentAmgenOverallEngagement | InactiveSection | undefined | null;
};

const TalentDinbModalTalentAmgenOverallEngagement = (props: Props) => {
  const namespace = "rts-pa-talent-dinb-modal-talent-amgen-overall-engagement";
  const title = "Amgen Overall Engagement";

  if (!props.data) {
    return <></>;
  } else if (props.data.status === "inactive") {
    return <AccordionWithStatus title={props.data.title} status="gray" />;
  }

  const chartData = props.data.chart.map(item => ({
    label: item.label || undefined,
    forecast: item.forecast !== null ? item.forecast : undefined,
    value: item.value !== null ? item.value : undefined
  }));

  return (
    <AccordionWithStatus
      status={props.data.status}
      title={title}
      tooltipContent={getAdvancedTooltipContent(props.data.tooltip)}
      className={namespace}
      accordionSyncKey="TalentModal"
      links={props.data.links}
    >
      <div className={`${namespace}-metrics`}>
        <LineChart
          data={chartData}
          xKey="label"
          dataKeys={[
            {
              lineKey: "value",
              dot: true,
              color: colors.primaryBlue,
              displayName: "AMGEN"
            },
            {
              lineKey: "forecast",
              dot: true,
              color: colors.yellowAccent,
              displayName: "GENERAL INDUSTRIES BENCHMARK"
            }
          ]}
          chartHeight={200}
          showPopover
          showLegend
          yAxisWidth={22}
          yAxisDomain={["dataMin - 2", "dataMax + 2"]}
        />
      </div>
    </AccordionWithStatus>
  );
};

export default TalentDinbModalTalentAmgenOverallEngagement;
