import StatusIcon from "common-components/status-icon/StatusIcon";
import React from "react";
import "./SupplyLocationMetrics.scss";
import { Status } from "api/common";
import { Label, Text } from "common-components";

type Props = {
  title: string;
  data: {
    status: Status;
    text: string | null;
  };
};

const SupplyLocationMetrics = (props: Props) => {
  if (!props.data) {
    return <></>;
  }

  return (
    <div className="rts-pa-commercial-modal-supply-location-metrics">
      <Label>{props.title}</Label>
      <div className="rts-pa-commercial-modal-supply-metrics-value">
        <div>
          <StatusIcon status={props.data.status} />
        </div>
        <Text>{props.data.text ?? "N/A"}</Text>
      </div>
    </div>
  );
};

export default SupplyLocationMetrics;
