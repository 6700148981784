import React from "react";
import { KeyEventsIconProps } from "./common-props";

export const DiamondIcon = (props: KeyEventsIconProps) => {
  const { width = 15, height = 14, className, fill = "#0063C3" } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M7.16699 0L14.167 7L7.16699 14L0.166992 7L7.16699 0Z"
        fill={fill}
      />
    </svg>
  );
};

export default DiamondIcon;
