import { Literal, Static } from "runtypes";
import { AgendaItemTemplateSectionValidator } from "../agenda-item-template-common";

export const AgendaItemTemplateSectionTalentAcquisitionValidator =
  AgendaItemTemplateSectionValidator.extend({
    type: Literal("talentAcquisition")
  });

export type AgendaItemTemplateSectionTalentAcquisition = Static<
  typeof AgendaItemTemplateSectionTalentAcquisitionValidator
>;
