import React from "react";

//TODO JCG: change icon from an X to actual trash can once it becomes available

type Props = {
  height?: number;
  width?: number;
  onClick?: () => void;
  className?: string;
  color?: string;
};

export const LockIcon = (props: Props) => {
  const {
    width = 86,
    height = 105,
    onClick,
    className,
    color = "currentColor"
  } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 86 105"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        d="M43 57.75C41.3826 57.7313 39.7982 58.2027 38.4602 59.1008C37.1221 59.9989 36.0945 61.2806 35.5158 62.7732C34.9371 64.2659 34.835 65.8979 35.2233 67.4497C35.6116 69.0014 36.4715 70.3984 37.6875 71.4525V78.75C37.6875 80.1424 38.2472 81.4777 39.2435 82.4623C40.2398 83.4469 41.591 84 43 84C44.409 84 45.7602 83.4469 46.7565 82.4623C47.7528 81.4777 48.3125 80.1424 48.3125 78.75V71.4525C49.5285 70.3984 50.3884 69.0014 50.7767 67.4497C51.165 65.8979 51.0629 64.2659 50.4842 62.7732C49.9055 61.2806 48.8779 59.9989 47.5398 59.1008C46.2018 58.2027 44.6174 57.7313 43 57.75ZM69.5625 36.75V26.25C69.5625 19.2881 66.764 12.6113 61.7825 7.68845C56.8011 2.76562 50.0448 0 43 0C35.9552 0 29.1989 2.76562 24.2175 7.68845C19.236 12.6113 16.4375 19.2881 16.4375 26.25V36.75C12.2106 36.75 8.15685 38.4094 5.16799 41.3631C2.17913 44.3168 0.5 48.3228 0.5 52.5V89.25C0.5 93.4272 2.17913 97.4332 5.16799 100.387C8.15685 103.341 12.2106 105 16.4375 105H69.5625C73.7894 105 77.8432 103.341 80.832 100.387C83.8209 97.4332 85.5 93.4272 85.5 89.25V52.5C85.5 48.3228 83.8209 44.3168 80.832 41.3631C77.8432 38.4094 73.7894 36.75 69.5625 36.75ZM27.0625 26.25C27.0625 22.0728 28.7416 18.0668 31.7305 15.1131C34.7193 12.1594 38.7731 10.5 43 10.5C47.2269 10.5 51.2807 12.1594 54.2695 15.1131C57.2584 18.0668 58.9375 22.0728 58.9375 26.25V36.75H27.0625V26.25ZM74.875 89.25C74.875 90.6424 74.3153 91.9777 73.319 92.9623C72.3227 93.9469 70.9715 94.5 69.5625 94.5H16.4375C15.0285 94.5 13.6773 93.9469 12.681 92.9623C11.6847 91.9777 11.125 90.6424 11.125 89.25V52.5C11.125 51.1076 11.6847 49.7723 12.681 48.7877C13.6773 47.8031 15.0285 47.25 16.4375 47.25H69.5625C70.9715 47.25 72.3227 47.8031 73.319 48.7877C74.3153 49.7723 74.875 51.1076 74.875 52.5V89.25Z"
        fill={color}
      />
    </svg>
  );
};

export default LockIcon;
