import React from "react";
import { KeyEventsIconProps } from "./common-props";

export const SquareIcon = (props: KeyEventsIconProps) => {
  const { width = 15, height = 14, className, fill = "#BCBD22" } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect x="0.666992" width="14" height="14" fill={fill} />
    </svg>
  );
};

export default SquareIcon;
