import React, { useMemo } from "react";
import "./AgendaGridPublish.scss";
import { useQuery } from "@tanstack/react-query";
import { getAgenda } from "api/agenda";
import dayjs from "dayjs";
import { Title } from "common-components";
import { getAgendaId } from "utils/multitenancy";

const namespace = "rts-pa-agenda-grid-publish";

export const AgendaGridPublish = () => {
  const { data } = useQuery(["agenda", getAgendaId()], getAgenda);

  const publishedDate = useMemo(() => {
    if (!data) {
      return "";
    }

    return dayjs(data.publishedDate).format("MM/DD/YYYY");
  }, [data]);

  return (
    <Title className={namespace} level={3} color="gray">
      {`As of ${publishedDate}`}
      {!publishedDate && <div className={`${namespace}-shimmer`} />}
    </Title>
  );
};

export default AgendaGridPublish;
