import axios from "axios";

import {
  ActiveDirectoryUser,
  ActiveDirectoryGroup
} from "./active-directory/active-directory-common";
import {
  ActiveDirectoryGroupUsers,
  ActiveDirectoryGroupUsersValidator
} from "./active-directory/active-directory-group-users";
import {
  ActiveDirectoryUserGroups,
  ActiveDirectoryUserGroupsValidator
} from "./active-directory/active-directory-user-groups";
import {
  ActiveDirectoryAllEntities,
  ActiveDirectoryAllEntitiesValidator
} from "./active-directory/active-directory-all";
import {
  ActiveDirectoryAdGroupUserList,
  ActiveDirectoryAdGroupUserListValidator
} from "./active-directory/active-directory-ad-group-users";

export type {
  ActiveDirectoryUser as AdUser,
  ActiveDirectoryGroup as AdGroup,
  ActiveDirectoryGroupUsers as AdGroupUsers,
  ActiveDirectoryUserGroups as AdUserGroups,
  ActiveDirectoryAllEntities as AdAllEntities,
  ActiveDirectoryAdGroupUserList as AdGroupUserList
};

export const getGroupUsers = async (
  group: string
): Promise<ActiveDirectoryGroupUsers> => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  const result = await axios.get<ActiveDirectoryGroupUsers>(
    `${REACT_APP_DOMAIN}/api/v1/active-directory/group-users`,
    {
      params: {
        group: group
      }
    }
  );

  ActiveDirectoryGroupUsersValidator.check(result.data);

  return result.data;
};

export const getUserGroups = async (
  username: string
): Promise<ActiveDirectoryUserGroups> => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  const result = await axios.get<ActiveDirectoryUserGroups>(
    `${REACT_APP_DOMAIN}/api/v1/active-directory/user-groups`,
    {
      params: {
        username: username
      }
    }
  );

  ActiveDirectoryUserGroupsValidator.check(result.data);

  return result.data;
};

export const getAllAdEntities =
  async (): Promise<ActiveDirectoryAllEntities> => {
    const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

    const result = await axios.get<ActiveDirectoryAllEntities>(
      `${REACT_APP_DOMAIN}/api/v1/active-directory/all`
    );

    ActiveDirectoryAllEntitiesValidator.check(result.data);

    return result.data;
  };

export const getAdGroupUserList = async (
  adGroup: string
): Promise<ActiveDirectoryAdGroupUserList> => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  const result = await axios.get<ActiveDirectoryAdGroupUserList>(
    `${REACT_APP_DOMAIN}/api/v1/active-directory/${adGroup}`
  );

  ActiveDirectoryAdGroupUserListValidator.check(result.data);

  return result.data;
};
