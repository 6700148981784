import React from "react";
import "./ComModalBrandMetrics.scss";
import { CommercialBrandSection } from "api";
import ModalSection from "common-components/modal-section/ModalSection";
import ComModalBrandSalesDemand from "./sales-demand/ComModalBrandSalesDemand";
import ComModalCommonSalesDemandLite from "../common/ComModalCommonSalesDemandLite";

type Props = {
  data: CommercialBrandSection | undefined | null;
};

const ComModalBrandMetrics = (props: Props) => {
  if (!props.data) {
    return <></>;
  }

  return (
    <ModalSection
      title="Brand Metrics"
      className="rts-pa-commercial-modal-brand-metrics"
    >
      <>
        {props.data.salesDemandLiteSections.map((sdls, i) => (
          <ComModalCommonSalesDemandLite key={i} data={sdls} />
        ))}
      </>
      <>
        {props.data.salesDemandSections.map((sds, i) => (
          <ComModalBrandSalesDemand key={i} data={sds} />
        ))}
      </>
    </ModalSection>
  );
};

export default ComModalBrandMetrics;
