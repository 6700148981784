import React, { useMemo } from "react";
import "./KeyEventsCalendarPublishedDate.scss";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { Text } from "common-components";
import { getKeyEventsId } from "utils/multitenancy";
import { getCalendar } from "api";

const namespace = "rts-pa-key-events-calendar-published-date";

export const KeyEventsCalendarPublishedDate = () => {
  const { data } = useQuery(["calendar", getKeyEventsId()], getCalendar);

  const publishedDate = useMemo(() => {
    if (!data) {
      return "";
    }

    return dayjs(data.publishedDate).format("MMM DD, YYYY");
  }, [data]);

  return (
    <div className={namespace}>
      <Text>{"As of"}</Text>
      {publishedDate ? (
        <Text>{publishedDate}</Text>
      ) : (
        <div className={`${namespace}-shimmer`} />
      )}
    </div>
  );
};

export default KeyEventsCalendarPublishedDate;
