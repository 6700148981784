import { Record, Static, String } from "runtypes";
import { StatusValidator, AdvancedTooltipValidator } from "../common";

export const CommercialCommonSalesDemandLiteSectionValidator = Record({
  title: String.nullable().optional(),
  color: StatusValidator.nullable().optional(),
  status: StatusValidator,
  value: String,
  tooltip: AdvancedTooltipValidator.nullable()
});

export type CommercialCommonSalesDemandLiteSection = Static<
  typeof CommercialCommonSalesDemandLiteSectionValidator
>;
