import React from "react";
import "./AgendaGridAxis.scss";
import { Label } from "common-components";

export type Props = {
  label: string;
  low: string;
  high: string;
};

const AgendaGridAxis = (props: Props) => {
  return (
    <div className="rts-pa-agenda-grid-axis">
      <div className="pill">
        <Label colorVariant="white">{props.low}</Label>
      </div>
      <div className="line" />
      <Label colorVariant="tertiary">{props.label}</Label>
      <div className="line" />
      <div className="pill">
        <Label colorVariant="white">{props.high}</Label>
      </div>
    </div>
  );
};

export default AgendaGridAxis;
