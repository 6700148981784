import { Array, Number, Record, Static, String } from "runtypes";
import {
  AdvancedTooltipValidator,
  InactiveSectionValidator,
  LinkValidator,
  StatusValidator
} from "../common";

const CommercialPeopleInactiveSectionValidator = InactiveSectionValidator.And(
  Record({
    tooltip: AdvancedTooltipValidator.nullable().optional()
  })
);

export type CommercialPeopleInactiveSection = Static<
  typeof CommercialPeopleInactiveSectionValidator
>;

const CommercialPeopleEngagementScoreValidator = Record({
  status: StatusValidator,
  tooltip: AdvancedTooltipValidator,
  date: String,
  productEngagementScore: Number,
  previousDate: String,
  productChange: Number,
  amgenAverage: Number,
  benchmark: Number,
  externalLinks: Array(LinkValidator)
});

const CommercialPeopleTalentAcquisitionValidator = Record({
  status: StatusValidator,
  openRequisitions: Number.nullable(),
  avgOpenDays: Number.nullable(),
  amgenOpenRequisitions: Number.nullable(),
  amgenAvgOpenDays: Number.nullable(),
  externalLinks: Array(LinkValidator)
});

const CommercialPeopleTurnoverValidator = Record({
  status: StatusValidator,
  tooltip: AdvancedTooltipValidator.nullable().optional(),
  chart: Array(
    Record({
      label: String,
      value: Number,
      forecast: Number.nullable()
    })
  ),
  externalLinks: Array(LinkValidator)
});

export const CommercialPeopleSectionValidator = Record({
  engagementScore: CommercialPeopleEngagementScoreValidator.Or(
    CommercialPeopleInactiveSectionValidator
  ).nullable(),
  talentAcquisition: CommercialPeopleTalentAcquisitionValidator.Or(
    CommercialPeopleInactiveSectionValidator
  ).nullable(),
  turnover: CommercialPeopleTurnoverValidator.Or(
    CommercialPeopleInactiveSectionValidator
  ).nullable()
});

export type CommercialPeopleEngagementScoreSection = Static<
  typeof CommercialPeopleEngagementScoreValidator
>;
export type CommercialPeopleTalentAcquisitionSection = Static<
  typeof CommercialPeopleTalentAcquisitionValidator
>;
export type CommercialPeopleTurnoverSection = Static<
  typeof CommercialPeopleTurnoverValidator
>;

export type CommercialPeopleSection = Static<
  typeof CommercialPeopleSectionValidator
>;
