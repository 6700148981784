import React from "react";
import "./StatusSelectorButton.scss";
import classNames from "classnames";
import { Status } from "api/common";
import StatusIcon from "common-components/status-icon/StatusIcon";

type Props = {
  status: Status;
  currentVal: Status | undefined;
  onChangeAutoUpdater: ((val: Status) => void) | undefined;
  onChangeProp: ((val: Status) => void) | undefined;
};

const namespace = "rts-pa-status-selector-button";

export const StatusButton = (props: Props) => {
  const { status, currentVal, onChangeAutoUpdater, onChangeProp } = props;

  const onClick = () => {
    if (onChangeAutoUpdater) {
      onChangeAutoUpdater(status);
    } else if (onChangeProp) {
      onChangeProp(status);
    }
  };

  const className = classNames(namespace, { selected: status === currentVal });

  return (
    <div onClick={onClick} className={className}>
      <StatusIcon status={status} />
    </div>
  );
};

export default StatusButton;
