import React, { useEffect } from "react";
import "./AIBuilderModalSectionSingleMetric.scss";
import { Select, GenericOption } from "common-components";
import {
  AgendaItemTemplateSectionSingleMetric,
  AgendaItemTemplateSectionSingleMetricScope,
  AgendaItemTemplateSectionSingleMetricSubtype
} from "api/agenda-item-template/sections";
import produce from "immer";
import { SectionBasicProps } from "../../AgendaItemBuilderModalSection";

const namespace = "rts-pa-agenda-item-builder-modal-section-single-metric";

type Props = SectionBasicProps;

const subtypes: GenericOption<AgendaItemTemplateSectionSingleMetricSubtype>[] =
  [
    {
      label: "Year to Date (YTD) Variance vs Forecast",
      value: "ytdVarVsFcst"
    }
  ];

const scopes: GenericOption<AgendaItemTemplateSectionSingleMetricScope>[] = [
  {
    label: "Global",
    value: "global"
  }
];

export const AIBuilderModalSectionSingleMetric = (props: Props) => {
  //set defaults
  useEffect(() => {
    let hasChanges = false;
    const newTemplate = produce(props.agendaItemTemplate, draftState => {
      const section = draftState.groups[props.groupIndex].sections[
        props.index
      ] as AgendaItemTemplateSectionSingleMetric;

      if (!section.subtype) {
        section.subtype = "ytdVarVsFcst";
        hasChanges = true;
      }

      if (!section.scope) {
        section.scope = "global";
        hasChanges = true;
      }
    });

    if (hasChanges) {
      props.setAgendaItemTemplate(newTemplate);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const section = props.section as AgendaItemTemplateSectionSingleMetric;

  //update default name
  useEffect(() => {
    if (!section.title || subtypes.find(item => item.label === section.title)) {
      const subtype = subtypes.find(item => item.value === section.subtype);
      if (subtype) {
        props.updateTitle(subtype.label);
      }
    }
  }, [section.subtype]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={namespace}>
      <Select
        label="Subtype"
        autoUpdater={{
          item: props.agendaItemTemplate,
          setItem: props.setAgendaItemTemplate,
          propExpression: x =>
            (
              x.groups[props.groupIndex].sections[
                props.index
              ] as AgendaItemTemplateSectionSingleMetric
            ).subtype
        }}
        required
        validator={props.fieldUtils}
        options={subtypes}
        placeholder="Select Subtype"
      />
      <Select
        label="Data Scope"
        autoUpdater={{
          item: props.agendaItemTemplate,
          setItem: props.setAgendaItemTemplate,
          propExpression: x =>
            (
              x.groups[props.groupIndex].sections[
                props.index
              ] as AgendaItemTemplateSectionSingleMetric
            ).scope
        }}
        required
        validator={props.fieldUtils}
        options={scopes}
        placeholder="Select Data Scope"
      />
    </div>
  );
};

export default AIBuilderModalSectionSingleMetric;
