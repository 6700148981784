const REDIRECT_URI = `${window.location.origin}/implicit/callback`;

export const getOktaAuthConfig = () => {
  return {
    issuer: window.__RUNTIME_CONFIG__.REACT_APP_ISSUER,
    clientId: window.__RUNTIME_CONFIG__.REACT_APP_CLIENT_ID,
    redirectUri: REDIRECT_URI,
    scopes: ["openid", "profile", "email", "offline_access"],
    pkce: true,
    tokenManager: {
      expireEarlySeconds: 120,
      autoRenew: true,
      storageKey: "sensing-okta-token"
    }
  };
};
