import { Array, Literal, Number, Static } from "runtypes";
import { AgendaItemSectionValidator } from "../agenda-item-common";
import { LinkValidator } from "api/common";

export const AgendaItemSectionTalentAcquisitionValidator =
  AgendaItemSectionValidator.extend({
    type: Literal("talentAcquisition"),
    openRequisitions: Number.nullable(),
    avgOpenDays: Number.nullable(),
    amgenOpenRequisitions: Number.nullable(),
    amgenAvgOpenDays: Number.nullable(),
    links: Array(LinkValidator)
  });

export type AgendaItemSectionTalentAcquisition = Static<
  typeof AgendaItemSectionTalentAcquisitionValidator
>;
