import React, { useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router";
import {
  Button,
  LinkExternalIcon
} from "@opsdti-global-component-library/amgen-design-system";
import { PermissionsContext } from "global/permissions";
import PermissionsWrapper from "common-components/permissions-wrapper/permissions-wrapper";
import { useNavLinks } from "global/use-nav-links";
import { TileConfig, getTileImage } from "api/updateHomepageImage";
import { HomeTile } from "./home-tile/HomeTile";

export const PrioritizedAgendaHomeTile = () => {
  const navigate = useNavigate();
  const permissions = useContext(PermissionsContext);
  const tilePermissions = permissions.homeTile;
  const { links } = useNavLinks(permissions);
  const { data, isLoading } = useQuery<TileConfig>(
    ["tile-image-url"],
    getTileImage
  );

  return (
    <HomeTile
      title="Prioritized Agenda"
      imageUrl={data?.imageUrl}
      isLoading={isLoading}
      footer={
        <>
          <PermissionsWrapper permissions={tilePermissions.pageLink}>
            <Button
              onClick={() => {
                navigate("/prioritized-agenda");
              }}
              type="secondary"
              text={`Go To Prioritized Agenda Page`}
            />
          </PermissionsWrapper>

          <PermissionsWrapper permissions={tilePermissions.pdfs}>
            <Button
              onClick={() => {
                if (!links.external.pa.ceoStaff.url) {
                  return;
                }
                window.open(
                  links.external.pa.ceoStaff.url,
                  "_blank",
                  "noreferrer"
                );
              }}
              icon={<LinkExternalIcon height={12} width={12} />}
              type="secondary"
              text={`Go To Ceo Staff Prioritized Agenda`}
            />
            <Button
              onClick={() => {
                if (!links.external.pa.functionalPa.url) {
                  return;
                }
                window.open(
                  links.external.pa.functionalPa.url,
                  "_blank",
                  "noreferrer"
                );
              }}
              icon={<LinkExternalIcon height={12} width={12} />}
              type="secondary"
              text={`Go To Functional Prioritized Agendas`}
            />
          </PermissionsWrapper>
        </>
      }
    />
  );
};
