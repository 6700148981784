import axios from "axios";
import { Array, Number, Record, Static } from "runtypes";
import { AgendaItemValidator } from "./common";
import { getAgendaId } from "utils/multitenancy";

const AgendaItemsGroupValidator = Record({
  value: Number,
  focus: Number,
  items: Array(AgendaItemValidator)
});

export type AgendaItemsGroup = Static<typeof AgendaItemsGroupValidator>;

const AgendaGridGroupsValidator = Record({
  groups: Array(AgendaItemsGroupValidator)
});

export type AgendaGridGroups = Static<typeof AgendaGridGroupsValidator>;

export const getAgendaItems = async (
  limitedView: boolean
): Promise<AgendaGridGroups> => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  const result = await axios.get<AgendaGridGroups>(
    `${REACT_APP_DOMAIN}/api/v4/${getAgendaId()}/agenda_item/board`,
    {
      params: {
        limited_view: limitedView
      }
    }
  );

  AgendaGridGroupsValidator.check(result.data);

  return result.data;
};

export const putAgendaItemsV2 = async (
  groups: AgendaGridGroups
): Promise<AgendaGridGroups> => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  const result = await axios.put<AgendaGridGroups>(
    `${REACT_APP_DOMAIN}/api/v4/${getAgendaId()}/agenda_item/board`,
    groups
  );

  AgendaGridGroupsValidator.check(result.data);

  return result.data;
};
