import React from "react";
import PaModal from "common-components/pa-modal/PaModal";
import {
  ExportModalOptions,
  ExportMode
} from "./export-modal-options/ExportModalOptions";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onExport: (option: ExportMode) => void;
};

const namespace = "rts-pa-export-modal";

const ExportModal = (props: Props) => {
  return (
    <PaModal
      onClose={props.onClose}
      isOpen={props.isOpen}
      title="Export Options"
      status="success"
      className={namespace}
    >
      <ExportModalOptions onExportClick={props.onExport} />
    </PaModal>
  );
};

export default ExportModal;
